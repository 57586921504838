import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// State

// MUI Components
import {
  Box,
  useTheme,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";

// MUI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WarningIcon from "@mui/icons-material/Warning";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InsightsIcon from "@mui/icons-material/Insights";
import Skipped from "assets/icons/Skipped";

// Custom components
import WARProgressChart from "../reports/charts/WARProgressChart";

// Custom hooks

// APIs
import { useGetStudentProfileQuery } from "../../app/api/studentsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import FlexBetween from "components/utilities/FlexBetween";

const StudentEnrolledProgram = ({ programEnrolmentId, student }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [view, setView] = useState("table");
  let chartData = [];

  const studentId = student.student_id;

  // Pull student profile
  const {
    data: studentProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentProfileQuery(studentId);

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    return <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const student = studentProfile[0];

    const handleView = (event, newView) => {
      if (newView) {
        setView(newView);
      }
    };

    let resIcn;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      cursor: "pointer",
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.primary[100],
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const asmts = student.enrol_prog
      .filter((prog) => prog.prog_enrol_id === parseInt(programEnrolmentId))
      .map((prog) => prog);

    const asmts2 = asmts[0].asmt_groups
      .slice()
      .sort((a, b) => parseInt(a.group_seq_no) - parseInt(b.group_seq_no));

    if (view === "chart") {
      const b25No = asmts2[0].b25th;
      const b40No = asmts2[0].b40th;

      asmts2.map((o, i) =>
        chartData.push({
          index: i,
          name: o.group_short_name,
          Pct25: b25No,
          Pct40: b40No,
          Result: o.avg_score ? Math.round(o.avg_score) : null,
        }),
      );
    }

    content = (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="0.5rem"
      >
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="select view"
        >
          <ToggleButton value="table" aria-label="view table">
            <ListAltIcon />
          </ToggleButton>
          <ToggleButton value="chart" aria-label="view chart">
            <InsightsIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        {view === "table" && (
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Assessment</StyledTableCell>
                    {asmts[0].enrol_term !== 0 && (
                      <StyledTableCell key="Term" align="center">
                        Term
                      </StyledTableCell>
                    )}
                    <StyledTableCell key="Asmt" align="center">
                      Parts in Asmt
                    </StyledTableCell>
                    <StyledTableCell key="completed" align="center">
                      Parts Completed
                    </StyledTableCell>
                    <StyledTableCell key="Status" align="center">
                      Status
                    </StyledTableCell>
                    <StyledTableCell key="Score" align="center">
                      Score
                    </StyledTableCell>
                    {asmts[0].show_pct && (
                      <StyledTableCell key="pct" align="center">
                        Pct
                      </StyledTableCell>
                    )}
                    <StyledTableCell key="benchmark" align="center">
                      Benchmark
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflow: "auto" }}>
                  {asmts2.map((row, index) => {
                    if (row.benchmark === "high") {
                      resIcn = (
                        <CheckCircleIcon
                          key={index}
                          sx={{
                            color: theme.palette.benchmark.high,
                          }}
                        />
                      );
                    } else if (row.benchmark === "mid") {
                      resIcn = (
                        <WarningAmberIcon
                          key={index}
                          sx={{
                            color: theme.palette.benchmark.mid,
                          }}
                        />
                      );
                    } else if (row.benchmark === "low") {
                      resIcn = (
                        <WarningIcon
                          key={index}
                          sx={{
                            color: theme.palette.benchmark.low,
                          }}
                        />
                      );
                    } else {
                      resIcn = "";
                    }
                    return (
                      <StyledTableRow
                        hover
                        key={row.enrol_group_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() =>
                          navigate(
                            `../assessment-group-summary/${programEnrolmentId}/${row.asmt_group_id}`,
                            {
                              relative: "path",
                            },
                          )
                        }
                      >
                        <StyledTableCell component="th" scope="row">
                          <FlexBetween>
                            <div>{row.group_name}</div>
                            <div>
                              {row.skipped_group_id && (
                                <Tooltip
                                  title={
                                    <Typography variant="h5">
                                      {`Skipped By: ${row.skipped_by} with reason: ${row.skipped_reason}`}
                                    </Typography>
                                  }
                                >
                                  <IconButton>
                                    <Skipped />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </FlexBetween>
                        </StyledTableCell>
                        {asmts[0].enrol_term !== 0 && (
                          <StyledTableCell align="center">
                            {asmts[0].enrol_term}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="center">
                          {row.asmt_in_group}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.asmt_comp}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.group_status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.group_total_score_inc &&
                            row.group_total_score_inc +
                              " / " +
                              row.group_max_score_to_date_inc}
                        </StyledTableCell>
                        {asmts[0].show_pct && (
                          <StyledTableCell align="center">
                            {row.group_total_score &&
                              Math.round(
                                (row.group_total_score_inc /
                                  row.group_max_score_to_date_inc) *
                                  100,
                              ) + "%"}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="center">
                          {resIcn}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {view === "chart" && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="0.5rem"
            width="100%"
            height="100%"
          >
            <WARProgressChart data={chartData} />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box m="20px" shadow="8">
      {content}
    </Box>
  );
};

export default StudentEnrolledProgram;
