import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// State
import {
  setCaNextAsmt,
  setCaGroupInstComp,
  setCaAsmtInstComp,
  setCaAsmtResult,
  setCaAsmtInfo,
  setCaGroupComp,
  setCaManage,
} from "../../app/state";

// MUI Components
import {
  IconButton,
  AppBar,
  Toolbar,
  Tooltip,
  Slide,
  Typography,
  Stack,
  Divider,
  Switch,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// MUI Icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

// Custom components
import CompleteAsmt from "./CompleteAsmt";
import GroupSummary from "./GroupSummary";
import DisplayGroupInstruction from "./DisplayGroupInstruction";
import FlexBetween from "components/utilities/FlexBetween";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";

// Custom hooks
import useAuth from "../../hooks/useAuth";

// APIs
import api from "../../app/axAPI/getData";
import { selectCurrentToken } from "../../app/api/authSlice";
import { useGetStudentsSummaryQuery } from "../../app/api/studentsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConductAsmtGroup = ({
  grpId,
  groupName,
  progEnrolId,
  asmts,
  review,
  showGroupAsmt,
  setShowGroupAsmt,
  showSummary = false,
  setShowSummary,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const studentId = useSelector((state) => state.global.studentId);

  const caManage = useSelector((state) => state.global.caManage);
  const edit = caManage.asmtEdit;
  const resultChange = caManage.resultChange;
  const asmt = caManage.currAsmt;

  const nextAsmt = useSelector((state) => state.global.caNextAsmt);

  const existingResult = useSelector((state) => state.global.caExistingResult);
  const instComp = useSelector((state) => state.global.caGroupInstComp);
  let groupComp = useSelector((state) => state.global.caGroupComp);
  groupComp = showSummary ? true : groupComp;

  const hiddenInst = useSelector((state) => state.global.caHiddenGroupInst);

  const { isAdmin, isManager } = useAuth();

  const { student, isFetching, isSuccess } = useGetStudentsSummaryQuery(
    "studentsList",
    {
      selectFromResult: ({ data, isFetching, isSuccess }) => ({
        student: data?.entities[studentId],
        isFetching,
        isSuccess,
      }),
    },
  );

  const [groupInst, setGroupInst] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [skipDialogOpen, setSkipDialogOpen] = useState(false);

  const asmtTotal = asmts.length;

  let asmtSeq = [...asmts];
  asmtSeq.sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no));

  // Pull group instructions and cycle through. When complete then set instuctionsCompleted = true
  // const getGroupInst = async (groupId) => {};

  // handle the changing of the Edit toggle control
  const handleEditChange = (event) => {
    let tempManage = structuredClone(caManage);
    tempManage.asmtEdit = event.target.checked;
    dispatch(setCaManage(tempManage));
  };

  // find the first incomplete assessment, skipped classed as complete. 0 means all asmts complete so go to first asmt
  const findFirstIncompleteAsmt = () => {
    let i;
    for (i = 0; i < asmtSeq.length; i++) {
      if (!asmtSeq[i].result_date && !asmtSeq[i].skipped) {
        return i;
      }
    }
    return 0;
  };

  const setNextAsmt = () => {
    let na = {};

    if (student.user_access_level === "read") {
      na.name = "Assessment Home";
      na.asmt = -2;
    } else if (asmtSeq[asmt].post_asmt_step === "next") {
      if (asmt + 1 >= asmtTotal) {
        na.name = "Assessment Summary";
        na.asmt = -1;
      } else {
        na.name = asmtSeq[asmt + 1].asmt_name;
        na.asmt = asmt + 1;
      }
    } else if (asmtSeq[asmt].post_asmt_step === "summary") {
      na.name = "Assessment Summary";
      na.asmt = -1;
    } else {
      // send to home page for group
      na.name = "Assessment Home";
      na.asmt = -2;
    }
    dispatch(setCaNextAsmt(na));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(
          `/assessments/group-instruction?id=${grpId}`,
          {
            headers: { Authorization: "Bearer " + token },
          },
        );

        const filteredInst = response.data.filter(
          (e) => !hiddenInst.includes(e.asmt_group_instruction_id),
        );
        setGroupInst(filteredInst);
        // Set the grp instructions complete if there are no instructions
        dispatch(setCaGroupInstComp(filteredInst.length === 0));
      } catch (err) {
        if (err.response) {
          setErrMsg("Err Msg: " + err.response.message);
          console.log(err.response.message);
        } else {
          setErrMsg(`Error: ${err.message}`);
          console.log(`Error: ${err.message}`);
        }
      }
    })();
    // getGroupInst();

    // if it is not a review and the asmt = 0 then find the first incomplete asmt
    if (!review && asmt === 0) {
      let i = findFirstIncompleteAsmt();
      let tempManage = structuredClone(caManage);
      tempManage.currAsmt = i;
      dispatch(setCaManage(tempManage));
      return;
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (asmt >= asmtTotal) {
        dispatch(setCaGroupComp(true));
        return;
      }
      setNextAsmt();
    }
  }, [asmt, isSuccess]);

  useEffect(() => {
    if (asmtSeq[asmt]?.skipped && asmt <= asmtTotal) {
      setSkipDialogOpen(true);
      setNextAsmt();
    }
  }, [asmtSeq[asmt]?.skipped, asmt]);

  const handleCloseExitDialog = () => {
    setExitDialogOpen(false);
  };

  const handleExitClick = () => {
    setShowSummary(false);
    if (instComp && !groupComp && !(existingResult && !resultChange)) {
      setExitDialogOpen(true);
    } else {
      handleExitGroup();
    }
  };

  const handleExitGroup = () => {
    let tempManage = structuredClone(caManage);
    tempManage.asmtEdit = false;
    tempManage.resultChange = false;
    dispatch(setCaManage(tempManage));
    dispatch(setCaAsmtInstComp(false));
    dispatch(setCaGroupComp(false));
    dispatch(setCaAsmtResult([]));
    dispatch(setCaAsmtInfo({}));
    setShowGroupAsmt(false);
  };

  const handleCloseSkipDialog = () => {
    setSkipDialogOpen(false);
  };

  let content;

  if (errMsg) {
    return <p className="errmsg">{errMsg}</p>;
  }
  if (!groupInst || isFetching) {
    return (
      <Dialog
        fullScreen
        open={showGroupAsmt}
        // onClose={setShowGroupAsmt(false)}
        TransitionComponent={Transition}
      >
        <PulseLoader color={"#000"} />
      </Dialog>
    );
  } else if (asmts.length === 0) {
    return (
      <p className="errmsg">
        No word list was found for this assessment. Please contact an
        administrator.
      </p>
    );
  } else {
    content = (
      <Dialog
        fullScreen
        open={showGroupAsmt}
        // onClose={setShowGroupAsmt(false)}
        TransitionComponent={Transition}
      >
        <>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <FlexBetween width="100%" padding="1rem">
                <VertCenter sx={{ width: "25%" }}>
                  <Tooltip title="Close Test">
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => handleExitClick()}
                      aria-label="close"
                    >
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </VertCenter>
                <HorizontalCenter width="60%">
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h2"
                    component="div"
                  >
                    {groupName}
                  </Typography>
                  <Stack
                    direction="row"
                    width="60%"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h3"
                      component="div"
                      textAlign="right"
                    >
                      {student.first_name + " " + student.last_name}
                    </Typography>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h3"
                      component="div"
                    >
                      Class: {student.class_name}
                    </Typography>
                  </Stack>
                </HorizontalCenter>
                <VertCenter sx={{ width: "25%" }}>
                  {(isAdmin || isManager) && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={edit}
                            disabled={!existingResult}
                            onChange={handleEditChange}
                            color="secondary"
                            inputProps={{
                              "aria-label": "edit results",
                            }}
                          />
                        }
                        label="Enable Edit Mode"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  )}
                  <Tooltip title="Instructions">
                    <IconButton
                      edge="start"
                      color="inherit"
                      // onClick={handleGetInfo}
                      aria-label="info"
                    >
                      <InfoOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </VertCenter>
              </FlexBetween>
            </Toolbar>
          </AppBar>
          {!instComp && !groupComp && (
            <DisplayGroupInstruction
              headerName={groupName}
              instType="group"
              inst={groupInst}
            />
          )}
          {instComp && !groupComp && (
            <CompleteAsmt
              asmtId={asmtSeq[asmt].asmt_id}
              enAsmtId={asmtSeq[asmt].enrolled_asmt_id}
              asmt={asmt}
              handleExitGroup={handleExitGroup}
            />
          )}
          {groupComp && (
            <GroupSummary asmtGroupId={grpId} progEnrolId={progEnrolId} />
          )}
        </>
        <Dialog open={exitDialogOpen} onClose={handleCloseExitDialog}>
          <DialogTitle>Exit Without Save</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you wish to exit? Any unsaved data will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseExitDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleExitGroup();
              }}
            >
              Exit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={skipDialogOpen && asmtSeq[asmt].skip_reason}
          onClose={handleCloseSkipDialog}
        >
          <DialogContent>
            <DialogContentText>
              <Typography variant="h5">{asmtSeq[asmt]?.skip_reason}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setSkipDialogOpen(false);
                if (nextAsmt.asmt === -1) {
                  dispatch(setCaGroupComp(true));
                } else {
                  let tempManage = structuredClone(caManage);
                  tempManage.currAsmt = nextAsmt.asmt;
                  dispatch(setCaManage(tempManage));
                }
              }}
            >
              Go to {nextAsmt.name}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setSkipDialogOpen(false);
              }}
            >
              Complete this part
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
  return content;
};

export default ConductAsmtGroup;
