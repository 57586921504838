import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const tagsAdapter = createEntityAdapter({
  selectId: (e) => e.tag_id,
});

const initialState = tagsAdapter.getInitialState();

export const tagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTagsByStudentId: builder.query({
      query: (studentId) => ({
        url: `/tags/student?id=${studentId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (studentId) => {
        return [{ type: "StudentTag", id: studentId }];
      },
    }),
    addStudentTag: builder.mutation({
      query: (studentTagData) => ({
        url: "/tags/student",
        method: "POST",
        body: {
          ...studentTagData,
        },
      }),
      invalidatesTags: (studentTagData) => [
        { type: "StudentTag", id: studentTagData.studentId },
      ],
    }),
    removeStudentTag: builder.mutation({
      query: (studentTagData) => ({
        url: "/tags/student",
        method: "PATCH",
        body: {
          ...studentTagData,
        },
      }),
      invalidatesTags: (studentTagData) => [
        { type: "StudentTag", id: studentTagData.studentId },
      ],
    }),
    getTagsByGroup: builder.query({
      query: () => ({
        url: "/tags",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedTags = responseData.map((tag) => {
          tag.id = tag.tag_id;
          return tag;
        });
        return tagsAdapter.setAll(initialState, loadedTags);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Tag", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "Tag",
              id,
            })),
          ];
        } else return [{ type: "Tag", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetTagsByStudentIdQuery,
  useAddStudentTagMutation,
  useRemoveStudentTagMutation,
  useGetTagsByGroupQuery,
} = tagsApiSlice;

// returns the query result object
export const selectTagsByGroupResult =
  tagsApiSlice.endpoints.getTagsByGroup.select();

// creates memoized selector
const selectTagsByGroupData = createSelector(
  selectTagsByGroupResult,
  (tagsByGroupResult) => tagsByGroupResult.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllTags,
  selectById: selectTagById,
  selectIds: selectTagIds,
  // Pass in a selector that returns the Students slice of state
} = tagsAdapter.getSelectors(
  (state) => selectTagsByGroupData(state) ?? initialState,
);
