import React, { useState } from "react";
import logoImage from "assets/MultiLit_RGB.png";
import CustomIcon from "components/common/CustomIcon";
import { NavLink } from "react-router-dom";
import { sidebarOptions } from "components/utilities/Constants";

const Sidebar = () => {
  const [hover, setHover] = useState("");

  return (
    <div class="w-[260px]  py-6 bg-white border-r border-[var(--Grey-30)] flex-col justify-start items-start gap-[33px] flex h-full">
      <div className="px-[18px]">
        <img className="w-[149px]" alt="MultiLit Logo" src={logoImage} />
      </div>
      <div class="flex-1 w-full  flex-col justify-start items-start gap-6 flex overflow-auto px-[18px]">
        <NavLink
          to="/home"
          end
          className={({ isActive }) =>
            `w-full p-3 rounded-lg justify-start items-center gap-4 group hover:bg-[var(--Blue-100)] inline-flex ${
              isActive
                ? "bg-[var(--Blue-100)] text-white"
                : "text-black group-hover:text-white"
            }`
          }
          onMouseEnter={() => setHover("Home")}
          onMouseLeave={() => setHover("")}
        >
          {({ isActive }) => (
            <>
              <CustomIcon
                className="w-6 h-6"
                stroke={
                  isActive
                    ? "#ffffff"
                    : hover === "Home"
                      ? "#ffffff"
                      : "#000000"
                }
                iconName="Home"
              />
              <span
                className={`text-base font-normal font-['Mulish'] leading-[17px] ${
                  isActive ? "text-white" : "text-black group-hover:text-white"
                }`}
              >
                Home
              </span>
            </>
          )}
        </NavLink>

        {sidebarOptions.map((sidebarOption) => (
          <div
            class="w-full flex-col justify-start items-start gap-3 flex"
            key={sidebarOption.key}
          >
            <h3 class=" text-[var(--Grey-70)] text-sm font-normal font-['Mulish'] leading-[16px]">
              {sidebarOption.key}
            </h3>
            {sidebarOption.options.map((item) => (
              <NavLink
                to={`/home/${item.text}`}
                end
                className={({ isActive }) =>
                  `w-full p-3 rounded-lg justify-start items-center gap-4 group hover:bg-[var(--Blue-100)] inline-flex ${
                    isActive ? "bg-[var(--Blue-100)]" : ""
                  }`
                }
                onMouseEnter={() => setHover(item.text)}
                onMouseLeave={() => setHover("")}
                key={item.text}
              >
                {({ isActive }) => (
                  <>
                    <div className="group-hover:stroke-white">
                      <CustomIcon
                        className="w-6 h-6"
                        stroke={
                          isActive
                            ? "#ffffff"
                            : hover === item.text
                              ? "#ffffff"
                              : "#000000"
                        }
                        iconName={item.icon}
                      />
                    </div>
                    <span
                      className={`text-base font-normal font-['Mulish'] leading-[17px] ${
                        isActive
                          ? "text-white"
                          : "text-black group-hover:text-white"
                      }`}
                    >
                      {item.text}
                    </span>
                  </>
                )}
              </NavLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
