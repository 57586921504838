import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// State
import { setCaManage } from "../../app/state";

// MUI Components
import {
  Box,
  useTheme,
  Button,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Stack,
  Tooltip,
  IconButton,
  styled,
} from "@mui/material";

// MUI Icons
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WarningIcon from "@mui/icons-material/Warning";
import NotesIcon from "@mui/icons-material/Notes";
import InfoIcon from "@mui/icons-material/Info";

// Custom components
import PostAssessmentInfo from "./PostAssessmentInfo";
import ConductAsmtGroup from "../assessment/ConductAsmtGroup";
import Skipped from "assets/icons/Skipped";
import SkipGroup from "features/dialogs/SkipGroup";
import RemoveSkipGroup from "features/dialogs/RemoveSkipGroup";
import DisplayMessage from "features/dialogs/DisplayMessage";
import BasicSnackbar from "components/common/BasicSnackbar";

// Custom hooks
// import useAuth from '../../hooks/useAuth'
import useTitle from "../../hooks/useTitle";

// APIs
import { useGetStudentProfileQuery } from "../../app/api/studentsApiSlice";
// import { selectCurrentToken } from '../auth/authSlice'

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import FlexBetween from "components/utilities/FlexBetween";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />
// })

const AssessmentGroupSummary = ({ progEnrolID, asmtGrpID }) => {
  useTitle("MAP: Conduct Assessment");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const studentId = useSelector((state) => state.global.studentId);
  const caManage = useSelector((state) => state.global.caManage);
  const [showGroupAsmt, setShowGroupAsmt] = useState(false);
  const [reviewAsmt, setReviewAsmt] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const [skipGroup, setSkipGroup] = useState(false);
  const [removeSkipGroup, setRemoveSkipGroup] = useState(false);
  const [snackConfig, setSnackConfig] = useState({});
  const [openSnack, setOpenSnack] = useState(false);

  const [skipAck, setSkipAck] = useState(false);
  const closeSkipMsg = () => {
    setSkipAck(true);
  };

  const { programEnrolmentId, asmtGroupId } = useParams();

  var progEnID;
  var asmtGID;
  if (progEnrolID) {
    progEnID = progEnrolID;
    asmtGID = asmtGrpID;
  } else {
    progEnID = programEnrolmentId;
    asmtGID = asmtGroupId;
  }

  let showInfo = false;

  if (studentId === undefined || studentId === 0) {
    navigate(`/home/students`);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary[100],
    },

    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    if (studentId === undefined || studentId === 0) {
      navigate(`/home/students`);
    }
  }, [navigate, studentId]);

  // GET DATA
  // Pull student profile
  const {
    data: studentProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentProfileQuery(studentId);

  const closeSkipGroup = () => {
    setSkipGroup(false);
  };

  const closeRemoveSkipGroup = () => {
    setRemoveSkipGroup(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    return <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const student = studentProfile[0];

    // Filter to program and group
    const prog = student.enrol_prog.filter(
      (p) => p.prog_enrol_id === parseInt(progEnID),
    );

    const grp = prog[0].asmt_groups.filter(
      (g) => g.asmt_group_id === parseInt(asmtGID),
    );

    // Get the asmts and flatten
    const asmts = student.enrol_prog
      .filter((p) => p.prog_enrol_id === parseInt(progEnID))
      .map((a) =>
        a.asmt_groups
          .filter((x) => x.asmt_group_id === parseInt(asmtGID))
          .map((b) => b.asmts),
      )
      .flat(Infinity);

    const handleCompleteGroupClick = (review, complete, asmt) => {
      if (!complete) {
        if (student.user_access_level === "read") {
          return;
        }
      }
      let tempManage = structuredClone(caManage);
      if (!review) {
        tempManage.currAsmt = 0;
      } else {
        tempManage.currAsmt = asmt;
      }
      dispatch(setCaManage(tempManage));
      setReviewAsmt(review);
      setShowGroupAsmt(true);
    };

    const handleSkipGroup = () => {
      setSkipGroup(true);
    };

    const handleRemoveSkipGroup = () => {
      setRemoveSkipGroup(true);
    };

    const handleSkipPart = () => {};

    const handleGoToSummary = () => {
      setShowSummary(true);
      setShowGroupAsmt(true);
    };

    const BackButton = () => {
      const goToProgEnrol = () => {
        navigate(
          `/home/students/enrolled/${student.student_id}/${prog[0].prog_enrol_id}`,
        );
      };
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Button
            variant="contained"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={goToProgEnrol}
            sx={{ m: "0 0 20px 0" }}
          >
            Back to Program Detail
          </Button>
        </Box>
      );
    };

    let resIcn;
    if (grp[0].benchmark === "high") {
      resIcn = (
        <CheckCircleIcon
          fontSize="large"
          sx={{ color: theme.palette.benchmark.high }}
        />
      );
    } else if (grp[0].benchmark === "mid") {
      resIcn = (
        <WarningAmberIcon
          fontSize="large"
          sx={{ color: theme.palette.benchmark.mid }}
        />
      );
    } else if (grp[0].benchmark === "low") {
      resIcn = (
        <WarningIcon
          fontSize="large"
          sx={{ color: theme.palette.benchmark.low }}
        />
      );
    } else {
      resIcn = "";
    }

    if (asmts.length === 0) {
      return (
        <p className="errmsg">
          No word list was found for this assessment. Please contact an
          administrator.
        </p>
      );
    } else {
      let termText = "";
      if (prog[0].EnrolTerm) {
        termText = " - Term " + prog[0].EnrolTerm;
      }
      const HeaderDetail = () => {
        return (
          <>
            <Typography variant="h2">
              {student.first_name + " " + student.last_name} - Year{" "}
              {student.accademic_year}
            </Typography>
            <Typography variant="h1" marginBottom="1rem">
              {grp[0].group_name}
              {termText}
            </Typography>
            {student.user_access_level !== "read" && (
              <Box
                id="asmt-group-actions"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gap="2rem"
                p="0 0 1rem 0"
              >
                <Button
                  variant="contained"
                  onClick={() => handleCompleteGroupClick(false, false, 0)}
                >
                  Administer test
                </Button>

                {grp[0].skipped_reason === null &&
                  grp[0].group_status !== "Completed" && (
                    <Button
                      variant="contained"
                      onClick={() => handleSkipGroup()}
                    >
                      Skip test
                    </Button>
                  )}
                {grp[0].skipped_reason && (
                  <Button
                    variant="contained"
                    onClick={() => handleRemoveSkipGroup()}
                  >
                    Remove skipped flag
                  </Button>
                )}
                <Button variant="contained" onClick={() => handleSkipPart()}>
                  Skip next part
                </Button>
                <Button variant="contained" onClick={() => handleGoToSummary()}>
                  Go To Summary
                </Button>
              </Box>
            )}
          </>
        );
      };
      const DataTable = () => {
        return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" sx={{ p: "0 0 0 4rem" }}>
                      Part
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Skills assessed
                    </StyledTableCell>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="center">Score</StyledTableCell>
                    {prog[0].show_pct && (
                      <StyledTableCell align="center">
                        Score pct
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left">Tester</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {asmts
                    .sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no))
                    .map((row, _key) => {
                      if (row.show_in_list) {
                        let score;
                        let pct = "";
                        if (row.explicit_result) {
                          score = row.explicit_result;
                        } else if (row.result_score !== null) {
                          score = row.result_score + " / " + row.max_score;
                          pct =
                            row.result_score !== null &&
                            !row.explicit_result &&
                            Math.round(
                              (row.result_score / row.max_score) * 100,
                            ) + "%";
                        }
                        return (
                          <StyledTableRow
                            key={_key}
                            hover={student.user_access_level !== "read"}
                            onClick={() =>
                              handleCompleteGroupClick(
                                row.assessor || row.skipped ? true : false,
                                row.assessor ? true : false,
                                _key,
                              )
                            }
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              align="left"
                              component="th"
                              scope="row"
                              sx={{
                                p: "0 0 0 4rem",
                              }}
                            >
                              {row.part}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <FlexBetween>
                                <Box>{row.asmt_name}</Box>
                                <div>
                                  {!row.include_in_results && (
                                    <Tooltip
                                      title={
                                        <Typography variant="h5">
                                          This assessment is not included in the
                                          total score.
                                        </Typography>
                                      }
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row.result_note && (
                                    <Tooltip
                                      title={
                                        <Typography variant="h5">
                                          {row.result_note}
                                        </Typography>
                                      }
                                    >
                                      <IconButton>
                                        <NotesIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row.skipped && (
                                    <Tooltip
                                      title={
                                        <Typography variant="h5">
                                          {row.skip_reason}
                                        </Typography>
                                      }
                                    >
                                      <IconButton>
                                        <Skipped />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </FlexBetween>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.result_date &&
                                new Date(row.result_date).toLocaleDateString(
                                  "en-AU",
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {score}
                            </StyledTableCell>
                            {prog[0].show_pct && (
                              <StyledTableCell align="center">
                                {pct}
                              </StyledTableCell>
                            )}
                            <StyledTableCell align="left">
                              {row.assessor}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        );
      };
      const FooterAvgScore = () => {
        return (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ p: "1rem 2rem" }}
          >
            <Box
              width="80%"
              borderRadius="0.5rem"
              sx={{
                backgroundColor: theme.palette.primary.main,
                p: "0.5rem 2rem",
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                divider={
                  <Divider orientation="vertical" color="#fff" flexItem />
                }
                gap="2rem"
              >
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.neutral.light }}
                >
                  {grp[0].group_total_score_inc &&
                    prog[0].summary_display_metric === "Total" &&
                    `Total Score ${grp[0].group_total_score_inc}`}
                  {prog[0].summary_display_metric === "Average" &&
                    `Average Score: ${Math.round(grp[0].avg_score)}`}
                  {prog[0].show_pct &&
                    grp[0].group_max_score_to_date_inc > 0 &&
                    ` (${Math.round(
                      (grp[0].group_total_score_inc /
                        grp[0].group_max_score_to_date_inc) *
                        100,
                    )}%)`}
                </Typography>
                <Typography variant="h4">{resIcn}</Typography>
              </Stack>
            </Box>
          </Box>
        );
      };

      if (
        grp[0].asmt_comp === grp[0].asmt_in_group &&
        /Initial/.test(grp[0].group_name)
      ) {
        showInfo = true;
      }

      content = (
        <ErrorBoundary customMessage="Something went wrong in assesment group summary">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="1rem"
          >
            {!progEnrolID && <BackButton />}
            <HeaderDetail />
          </Box>
          <DataTable />
          <FooterAvgScore />
          {/* If WARX initial Assessment and all completed then show the appropriate message */}
          {showInfo && (
            <PostAssessmentInfo
              program={prog[0].prog_name}
              grade={prog[0].enrol_year}
              term={prog[0].enrol_term}
              benchmark={grp[0].benchmark}
              avgScore={grp[0].avg_score}
              progEnrolID={prog[0].prog_enrol_id}
              isScreening={prog[0].is_screening}
            />
          )}
          {showGroupAsmt && (
            <ConductAsmtGroup
              grpId={grp[0].asmt_group_id}
              groupName={grp[0].group_name}
              progEnrolId={progEnID}
              asmts={asmts}
              review={reviewAsmt}
              showGroupAsmt={showGroupAsmt}
              setShowGroupAsmt={setShowGroupAsmt}
              showSummary={showSummary}
              setShowSummary={setShowSummary}
            />
          )}
          {skipGroup && (
            <SkipGroup
              skipOpen={skipGroup}
              skipClose={closeSkipGroup}
              skipSnackOpen={setOpenSnack}
              skipSnackConfig={setSnackConfig}
              title="Skip Assessment"
              content="Are you sure you want to skip this assessment? Please provide a reason."
              enrolGroupId={grp[0].enrol_group_id}
            />
          )}
          {removeSkipGroup && (
            <RemoveSkipGroup
              skipOpen={removeSkipGroup}
              skipClose={closeRemoveSkipGroup}
              skipSnackOpen={setOpenSnack}
              skipSnackConfig={setSnackConfig}
              skippedGroupId={grp[0].skipped_group_id}
            />
          )}

          {grp[0]?.skipped_reason !== null && skipAck === false && (
            <DisplayMessage
              msgOpen={true}
              msgClose={closeSkipMsg}
              title="Assessment Skipped"
              content={`This assessment was flagged as skipped by ${grp[0].skipped_by} for the following reason: ${grp[0].skipped_reason}`}
            />
          )}
          <BasicSnackbar
            open={openSnack}
            onClose={handleCloseSnack}
            severity={snackConfig.severity}
            message={snackConfig.message}
          />
        </ErrorBoundary>
      );
    }

    return content;
  }
};

export default AssessmentGroupSummary;
