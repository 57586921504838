export const buttonStyles = {
  correct: {
    width: "90%",
    backgroundColor: "rgb(0,200,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  yes: {
    width: "90%",
    backgroundColor: "rgb(0,200,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  excellent: {
    width: "90%",
    backgroundColor: "rgb(0,200,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  fluentlyRead: {
    width: "90%",
    backgroundColor: "rgb(0,200,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  soundedOut: {
    width: "90%",
    backgroundColor: "rgb(0,140,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  satisfactory: {
    width: "90%",
    backgroundColor: "rgb(0,140,83)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  zeroPoints: {
    width: "90%",
    backgroundColor: "rgb(212,37,66)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  onePoint: {
    width: "90%",
    backgroundColor: "rgb(87,185,41)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  twoPoints: {
    width: "90%",
    backgroundColor: "rgb(31,143,58)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(108,233,160)",
      cursor: "pointer",
    },
  },
  incorrect: {
    width: "90%",
    backgroundColor: "rgb(244,67,54)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(229,139,132)",
      cursor: "pointer",
    },
  },
  no: {
    width: "90%",
    backgroundColor: "rgb(244,67,54)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(229,139,132)",
      cursor: "pointer",
    },
  },
  poor: {
    width: "90%",
    backgroundColor: "rgb(244,67,54)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(229,139,132)",
      cursor: "pointer",
    },
  },
  selfCorrect: {
    width: "90%",
    backgroundColor: "rgb(150,135,0)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(110,161,131)",
      cursor: "pointer",
    },
  },
  incorrectlyFormed: {
    width: "90%",
    backgroundColor: "rgb(140,125,0)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(110,161,131)",
      cursor: "pointer",
    },
  },
  reversal: {
    width: "90%",
    backgroundColor: "rgb(160,155,0)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(110,161,131)",
      cursor: "pointer",
    },
  },
  nav: {
    width: "90%",
    backgroundColor: "rgb(30,136,229)",
    padding: "1rem",
    margin: "0.25rem 0",
    borderRadius: "1rem",
    fontSize: "1rem",
    "&:hover": {
      color: "#888",
      backgroundColor: "rgb(134,189,237)",
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
  toggleResult: {
    width: "3rem",
    backgroundColor: "rgb(166,195,219)",
    padding: "1rem",
    margin: "0.25rem",
    borderRadius: "1rem",
    fontSize: "1rem",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(0,0,0)",
      cursor: "pointer",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
      color: "rgba(0,0,0,0.65)",
      opacity: "0.5",
    },
  },
  lowBenchmark: {
    variant: "contained",
    backgroundColor: "rgb(230, 38, 58)",
    "&:hover": {
      color: "#888",
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
  midBenchmark: {
    variant: "contained",
    backgroundColor: "rgb(228, 186 ,62)",
    "&:hover": {
      color: "#888",
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
  highBenchmark: {
    variant: "contained",
    backgroundColor: "rgb(30, 188, 67)",
    "&:hover": {
      color: "#888",
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
  light: {
    variant: "contained",
    color: "rgb(0, 85, 140)",
    backgroundColor: "rgb(204, 221, 232)",
    "&:hover": {
      color: "#888",
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "0.5",
    },
  },
};

export const headerStyles = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#009be5",
    padding: "20px",
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "20px",
    "*": {
      marginRight: "5px",
    },
  },
  middleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginLeft: "320px",
  },
  link: {
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      color: "#fff",
      cursor: "pointer",
    },
  },
  webButton: {
    marginRight: "5px",
  },
};
