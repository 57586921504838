import React from "react";
import DOMPurify from "dompurify";

const RawHTML = ({ htmlString }) => {
  return (
    <div
      id="raw-html"
      width="100%"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(htmlString, {
          FORCE_BODY: true,
          ALLOWED_ATTR: ["class"],
          ALLOWED_TAGS: [
            "div",
            "span",
            "p",
            "ul",
            "li",
            "ol",
            "h1",
            "h2",
            "h3",
            "h4",
          ],
        }),
      }}
    />
  );
};

export default RawHTML;
