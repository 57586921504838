import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// State
import {
  setCaAsmtResult,
  setCaAsmtInfo,
  setCaExistingResult,
  setCaManage,
  setCaAsmtInstComp,
  setCaGroupComp,
  setCaNextAsmt,
} from "../../app/state";

// MUI Components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Box,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// MUI Icons

// Custom components
import DisplayAsmtInstruction from "./DisplayAsmtInstruction";
import DisplayAsmtQuestion from "./DisplayAsmtQuestion";
import DisplayAsmtResult from "./DisplayAsmtResult";
import BasicSnackbar from "components/common/BasicSnackbar";

// Custom hooks

// APIs
import api from "../../app/axAPI/getData";
import { selectCurrentToken } from "../../app/api/authSlice";
import { useGetAssessmentInstructionQuery } from "../../app/api/assessmentsApiSlice";
import {
  useAddNewAssessmentResultMutation,
  useUpdateAssessmentResultMutation,
} from "../../app/api/assessmentResultsApiSlice";
import { useGetScoringDetailsQuery } from "../../app/api/scoringApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import dayjs from "dayjs";

const CompleteAsmt = ({ asmtId, enAsmtId, asmt, handleExitGroup }) => {
  const dispatch = useDispatch();
  // Get token for pulling API
  const token = useSelector(selectCurrentToken);
  // Get the saved answers from the current session
  const studentId = useSelector((state) => state.global.studentId);
  const asmtRes = useSelector((state) => state.global.caAsmtResult);
  const nextAsmt = useSelector((state) => state.global.caNextAsmt);
  let asmtInfo = useSelector((state) => state.global.caAsmtInfo);
  const existingResult = useSelector((state) => state.global.caExistingResult);
  const instComp = useSelector((state) => state.global.caAsmtInstComp);
  const caManage = useSelector((state) => state.global.caManage);
  const hiddenInst = useSelector((state) => state.global.caHiddenAsmtInst);
  const errRef = useRef();

  // Track whether the insturctions and questions are complete
  const [questComp, setQuestComp] = useState(false);
  // const [asmtInst, setAsmtInst] = useState(null)
  // const [errMsg, setErrMsg] = useState(null);

  const [snackConfig, setSnackConfig] = useState({});
  const [openSnack, setOpenSnack] = useState(false);

  const [asmtNote, setAsmtNote] = useState(null);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [asmtDate, setAsmtDate] = useState(dayjs());
  const [errSaveMsg, setErrSaveMsg] = useState(null);
  const errClass = errSaveMsg ? "errmsg" : "offscreen";

  // Pull the assessment instruction information
  const {
    data: asmtInst,
    error: instError,
    isLoading: instLoading,
  } = useGetAssessmentInstructionQuery(asmtId);

  // Pull the scoring data
  const {
    data: scoringData,
    // error: scoringError,
    // isLoading: scoringLoading,
  } = useGetScoringDetailsQuery();

  // Pull asmt quesitons and detail and assess whther there are existing results
  // const getAsmtResults = async (id) => {

  // };

  const [
    addNewResult,
    {
      // isLoading: isLoadingNew,
      // isSuccess: isSuccessNew,
      // isError: isErrorNew,
      error: errorNew,
    },
  ] = useAddNewAssessmentResultMutation();

  const [
    updateResult,
    {
      // isLoading: isLoadingUpdate,
      // isSuccess: isSuccessUpdate,
      // isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateAssessmentResultMutation();

  // When the asmtId changes clear the session results and pull the instructions and questions
  useEffect(() => {
    dispatch(setCaAsmtResult([]));
    dispatch(setCaAsmtInfo({}));
    (async () => {
      try {
        const response = await api.get(
          `/assessments/templates?id=${enAsmtId}`,
          {
            headers: { Authorization: "Bearer " + token },
          },
        );

        dispatch(setCaAsmtInfo(response.data[0]));
        dispatch(setCaExistingResult(response.data[0].asmt_result_id !== null));
      } catch (err) {
        if (err.response) {
          // setErrMsg("Err Msg: " + err.response.message);
          console.log(err.response.message);
        } else {
          // setErrMsg(`Error: ${err.message}`);
          console.log(`Error: ${err.message}`);
        }
      }
    })();
  }, [dispatch, enAsmtId, token]);

  const finaliseAsmt = () => {
    let tempManage = structuredClone(caManage);
    if (nextAsmt.asmt === -1) {
      dispatch(setCaGroupComp(true));
    } else if (nextAsmt.asmt === -2) {
      handleExitGroup();
    } else {
      tempManage.currAsmt = nextAsmt.asmt;
    }
    tempManage.resultChange = false;
    tempManage.asmtEdit = false;
    setSaveDialogOpen(false);
    setErrSaveMsg(null);
    setQuestComp(false);
    dispatch(setCaAsmtInstComp(false));
    dispatch(setCaManage(tempManage));
  };

  const saveResults = async (e) => {
    e.preventDefault();
    let achievedScore = 0;
    let possibleScore = 0;
    let errors = 0;
    let row = 0;

    const aRes = asmtRes.map((a) => ({ ...a }));
    aRes.sort((a, b) => a.word_no - b.word_no);
    for (let i = 0; i < asmtRes.length; i++) {
      if (aRes[i].result) {
        let s = scoringData.find((x) => x.score_code === aRes[i].result);
        achievedScore += parseFloat(s?.score_award);
      }
      // If the row is different to the previous row, increment the errors - a skipped row is only 1 error
      if (aRes[i].result === "skippedRow" && aRes[i].row !== row) {
        row = aRes[i].row;
        errors++;
      }
      if (aRes[i].switched) {
        errors++;
      }
      if (aRes[i].concat) {
        errors++;
      }
      if (aRes[i].insert) {
        errors++;
      }
      possibleScore++;
    }
    if (existingResult && (!asmtNote || asmtNote === "")) {
      setErrSaveMsg("You must enter a note when editing an assessment result.");
      return;
    }
    if (
      achievedScore === 0 &&
      (!asmtNote || asmtNote === "") &&
      !asmtInfo.can_score_zero
    ) {
      setErrSaveMsg(
        "You must enter a note to save an assessment with a score of 0.",
      );
      return;
    }

    const enrolAsmtId = asmtInfo.enrol_asmt_id;
    const score = achievedScore;
    const baseScore = possibleScore;
    const dt = JSON.stringify(asmtRes);
    const asmtResultId = asmtInfo?.asmt_result_id;
    const asmtDateString = dayjs(asmtDate).format("YYYY-MM-DD");

    const tempArr = {};

    if (existingResult) {
      updateResult({
        asmtResultId,
        enrolAsmtId,
        dt,
        baseScore,
        score,
        errors,
        asmtNote,
        asmtDateString,
        studentId,
      })
        .unwrap()
        .then((fulfilled) => {
          tempArr.severity = "success";
          tempArr.message = "Assessment Saved";
        })
        .catch((rejected) => {
          if (errorUpdate?.status === 409) {
            tempArr.severity = "warning";
          } else {
            tempArr.severity = "error";
          }
          tempArr.message =
            "Results not saved - " +
            errorUpdate.status +
            ": " +
            errorUpdate.data.message;
        });
    } else {
      await addNewResult({
        enrolAsmtId,
        dt,
        baseScore,
        score,
        errors,
        asmtNote,
        asmtDateString,
        studentId,
      })
        .unwrap()
        .then((fulfilled) => {
          tempArr.severity = "success";
          tempArr.message = "Assessment Saved";
        })
        .catch((rejected) => {
          if (errorNew?.status === 409) {
            tempArr.severity = "warning";
          } else {
            tempArr.severity = "error";
          }
          tempArr.message =
            "Results not saved - " +
            errorNew.status +
            ": " +
            errorNew.data.message;
        });
    }

    setSnackConfig(tempArr);
    setOpenSnack(true);
    if (tempArr.severity !== "error") {
      finaliseAsmt();
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCloseSaveDialog = () => {
    // setErrMsg("");
    setSaveDialogOpen(false);
  };

  let content;

  if (instError) {
    return <p className="errmsg">{instError?.data?.message}</p>;
  }
  if (instLoading || Object.keys(asmtInfo)?.length === 0) {
    return <PulseLoader color={"#000"} />;
  } else {
    // Filter out any hidden instructions
    let asmtInstFiltered = asmtInst.filter(
      (x) => !hiddenInst.includes(x.asmt_instruction_id),
    );
    // If there were no instructions then set th be complete
    if (!instComp && asmtInstFiltered.length === 0) {
      dispatch(setCaAsmtInstComp(true));
    }
    content = (
      <>
        {!instComp && (
          <DisplayAsmtInstruction
            asmtName={asmtInfo.asmt_name}
            inst={asmtInstFiltered}
          />
        )}
        {instComp &&
          !questComp && ( // If there are session results, pass those to be displayed, if not use the template results
            <DisplayAsmtQuestion
              questionType={asmtInfo.asmt_type}
              questions={asmtRes.length > 0 ? asmtRes : asmtInfo.words}
              setQuestComp={setQuestComp}
            />
          )}
        {questComp && (
          <DisplayAsmtResult
            asmtName={asmtInfo.asmt_name}
            asmtResult={asmtRes}
            goBackToTest={setQuestComp}
            saveResults={setSaveDialogOpen}
            finaliseAsmt={finaliseAsmt}
          />
        )}
        <BasicSnackbar
          open={openSnack}
          onClose={handleCloseSnack}
          severity={snackConfig.severity}
          message={snackConfig.message}
        />
        <Dialog open={saveDialogOpen} onClose={handleCloseSaveDialog}>
          <DialogTitle>Save Part Results</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirm date, enter a note (optional) and hit Save
            </DialogContentText>
            <Box sx={{ p: "1rem" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={asmtDate}
                  disableFuture
                  label="Date of Assessment"
                  format="DD-MM-YYYY"
                  onChange={(newValue) => {
                    setAsmtDate(newValue);
                  }}
                />
              </LocalizationProvider>

              <TextField
                autoFocus
                margin="dense"
                id="asmt-note"
                name="asmt-note"
                label="Assessment Note"
                fullWidth
                variant="standard"
                onChange={(e) => setAsmtNote(e.target.value)}
              />
            </Box>
            <p ref={errRef} className={errClass} aria-live="assertive">
              {errSaveMsg}
            </p>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseSaveDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                saveResults(e);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return content;
};

export default CompleteAsmt;
