const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const HorizontalCenter = styled(Box)(({ align }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: align ? align : "center",
}));

export default HorizontalCenter;
