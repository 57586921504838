import { Button } from "@mui/material";
import React from "react";
import "./Button.module.scss";

function MLButton({
  btnText = "Button",
  btnVariant = "contained",
  classList,
  btnDisable = false,
  btnSize = "large",
  onClickFunc,
}) {
  return (
    <Button
      size={btnSize}
      variant={btnVariant}
      className={"btn-wrap " + classList}
      disabled={btnDisable}
      onClick={onClickFunc}
    >
      {btnText}
    </Button>
  );
}

export default MLButton;
