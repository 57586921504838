import React from "react";

// State

// MUI Components

// MUI Icons

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const Dashboard = () => {
  return <div>Dashboard Placeholder</div>;
};

export default Dashboard;
