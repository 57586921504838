import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function Skipped() {
  return (
    <SvgIcon>
      {/* used https://svgtrace.com/png-to-svg to convert to svg format */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 90 90"
      >
        <g>
          <g>
            <path d="M23.3,62.8H9.2C6.9,62.8,5,64.7,5,67v14.2c0,2.3,1.9,4.2,4.2,4.2h14.2c2.3,0,4.2-1.9,4.2-4.2V67    C27.5,64.7,25.6,62.8,23.3,62.8z" />
            <path d="M90.8,62.8H76.7c-2.3,0-4.2,1.9-4.2,4.2v14.2c0,2.3,1.9,4.2,4.2,4.2h14.2c2.3,0,4.2-1.9,4.2-4.2V67    C95,64.7,93.1,62.8,90.8,62.8z" />
            <path d="M57.1,62.8H42.9c-2.3,0-4.2,1.9-4.2,4.2v14.2c0,2.3,1.9,4.2,4.2,4.2h14.2c2.3,0,4.2-1.9,4.2-4.2V67    C61.2,64.7,59.4,62.8,57.1,62.8z M57.1,81.1C57.1,81.1,57.1,81.1,57.1,81.1l-14.2,0c0,0,0,0,0,0l0-14.2c0,0,0,0,0,0h14.2    c0,0,0,0,0,0L57.1,81.1z" />
            <path d="M87.5,52.5l5-24.4l-9,3c-3.4-4.6-7.8-8.4-12.9-11.2c-6.2-3.4-13.3-5.2-20.6-5.2c-22.4,0-40.6,17-40.6,37.8    c0,1.7,1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1C15.6,35.1,31,20.9,50,20.9c10.7,0,20.7,4.6,27.2,12.2L68.8,36L87.5,52.5z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
