const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const HorizontalCenter = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export default HorizontalCenter;
