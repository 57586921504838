import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import {
  Typography,
  styled,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";

// MUI Icons

// Custom components
import HorizontalCenter from "components/utilities/HorizontalCenter";
import TagSelector from "features/tags/TagSelector";

// Custom hooks

// APIs
import api from "../../app/axAPI/getData";
import { selectCurrentToken } from "../../app/api/authSlice";

// Images

// Random Others
// import PulseLoader from 'react-spinners/PulseLoader'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowWithTotal = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary[100],
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.neutral[50],
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary[300],
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary[100],
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.neutral[50],
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GroupSummary = ({ asmtGroupId, progEnrolId }) => {
  const token = useSelector(selectCurrentToken);

  const asmtInfo = useSelector((state) => state.global.caAsmtInfo);

  const [asmtGroupSummary, setAsmtGroupSummary] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(
          `/assessmentResults/group?grpid=${asmtGroupId}&progid=${progEnrolId}`,
          {
            headers: { Authorization: "Bearer " + token },
          },
        );
        setAsmtGroupSummary(response.data);
      } catch (err) {
        if (err.response) {
          setErrMsg("Err Msg: " + err.response.message);
          console.log(err.response.message);
        } else {
          setErrMsg(`Error: ${err.message}`);
          console.log(`Error: ${err.message}`);
        }
      }
    })();
  }, [asmtGroupId, progEnrolId, token]);

  let content;

  if (errMsg) {
    return <p className="errmsg">{errMsg}</p>;
  } else if (asmtGroupSummary.length === 0) {
    return <p className="errmsg">No results were found for this assessment.</p>;
  } else {
    let groupIds = asmtGroupSummary.map((x) => x.asmt_summary_group_id);
    let totalScore = asmtGroupSummary.reduce((acc, obj) => {
      if (obj.include_in_results === true) {
        return acc + obj.score;
      } else {
        return acc;
      }
    }, 0);
    let maxTotalScore = asmtGroupSummary.reduce((acc, obj) => {
      if (obj.include_in_results === true) {
        return acc + obj.max_score;
      } else {
        return acc;
      }
    }, 0);
    let suppParts = asmtGroupSummary.filter(
      (x) => x.is_supplimental_result && x.asmt_date,
    );
    content = (
      <HorizontalCenter>
        <Typography variant="h2">Assessment Results</Typography>
        <Box sx={{ width: "70%" }}>
          <TableContainer>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Task</StyledTableCell>
                  <StyledTableCell align="center">Score</StyledTableCell>
                  <StyledTableCell align="center">%</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asmtGroupSummary
                  .filter((x) => x.include_in_results)
                  .sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no))
                  .map((task, _key) => {
                    return (
                      <StyledTableRow
                        key={_key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {task.summary_group}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {task.score}/{task.max_score}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {Math.round((task.score / task.max_score) * 100)}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                <StyledTableRowWithTotal
                  key="99"
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell align="center" component="th" scope="row">
                    Total
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {totalScore}/{maxTotalScore}
                  </StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {Math.round((totalScore / maxTotalScore) * 100)}
                  </StyledTableCell>
                </StyledTableRowWithTotal>
              </TableBody>
            </Table>
          </TableContainer>
          {/* supplimentary parts */}
          {suppParts.length > 0 && (
            <>
              <Typography variant="h4" p="2rem 0 0 1rem">
                Supplementary parts (not included in the total score above)
              </Typography>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Task</StyledTableCell>
                      <StyledTableCell align="center">Score</StyledTableCell>
                      <StyledTableCell align="center">%</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suppParts
                      .sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no))
                      .map((task, _key) => {
                        return (
                          <StyledTableRow
                            key={_key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {task.summary_group}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {task.score}/{task.max_score}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {Math.round((task.score / task.max_score) * 100)}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>

        <Box id="tags" width="70%">
          <TagSelector enrolGroupId={asmtInfo.enrolled_group_id} />
        </Box>
      </HorizontalCenter>
    );
  }
  return content;
};

export default GroupSummary;
