import React from "react";
import Button from "@mui/material/Button";

const StyledButton = ({
  children,
  disabled,
  size,
  style,
  sxProps,
  variant,
  onClick,
  onMouseUp,
  onMouseDown,
}) => {
  const vari = !variant ? "contained" : variant;
  const props = { ...style, ...sxProps };

  return (
    <Button
      disabled={disabled}
      size={size}
      sx={props}
      variant={vari}
      onClick={onClick}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
