import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

// MUI Components
import { useMediaQuery } from "@mui/material";

// Custom components
import Navbar from "components/layout/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const DashLayout = () => {
  //console.log("Render: DashLayout")
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { data } = []; //useGetUserQuery(userId)
  const { pathname } = useLocation();

  const [path, setPath] = useState(pathname);
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  return (
    <>
      <div className="flex items-start h-full">
        <Sidebar
          user={data || {}}
          isNonMobile={isNonMobile}
          drawerWidth="18rem"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <div className="flex-1 h-full w-[calc(100%_-_260px)]">
          <Navbar
            user={data || {}}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            drawerWidth="18rem"
          />
          <div className="px-8 py-10 overflow-auto h-[calc(100%_-_81px)] flex flex-col">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashLayout;
