///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// NOT CURRENTLY USED - CAN BE IGNORED
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////import React from 'react'
import { useNavigate } from "react-router-dom";

// State

// MUI Components
import { Typography, useTheme } from "@mui/material";

// Custom hooks
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import MLTable from "components/common/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useGetStudentsSummaryQuery } from "app/api/studentsApiSlice";
import { setStudentId } from "app/state";
import { useEffect, useState } from "react";
import MLModal from "components/common/Modal/Modal";

import "./StudentHome.module.scss";
import { MLSearchInput } from "components/common/SearchInput/SearchInput";
import MLButton from "components/common/Button/Button";

const StudentHome = () => {
  const { username } = useAuth();
  const [studentList, setStudentList] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredRows, setFilteredRows] = useState();

  useTitle(`MultiLit: ${username}`);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const studentId = useSelector((state) => state.global.studentId);

  const {
    data: students,
    isLoading,
    isSuccess,
  } = useGetStudentsSummaryQuery("studentsSummaryList", {
    pollingInterval: 300000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const handleEnrollClick = (id) => {
    dispatch(setStudentId(id));
    if (id) {
      setIsModalOpen(true);
    }
  };

  const createStudentTableRow = (student) => {
    if (!student) {
      return {};
    }

    let row = {
      identifer: student.student_identifier,
      name: `${student.first_name} ${student.last_name}`,
      classField: student.class_name,
      grade: student.accademic_year,
      studentID: student.student_id,
    };

    if (student.enrol_prog && student.enrol_prog.length) {
      let program = [];
      const programTypes = [
        "InitiaLit-F",
        "InitiaLit-1",
        "WARN",
        "WARP",
        "WARL",
      ];

      programTypes.forEach((programName) => {
        const programData = student.enrol_prog?.find(
          (prog) => prog.prog_name === programName
        );

        if (programData) {
          if (programData.prog_name === "WARP") {
            program.push("WP");
          } else if (programData.prog_name === "WARL") {
            program.push("WL");
          } else if (programData.prog_name === "WARN") {
            program.push("WN");
          } else if (programData.prog_name === "InitiaLit-F") {
            program.push("IF");
          } else if (programData.prog_name === "InitiaLit-1") {
            program.push("I1");
          }
        }
      });

      row.program = program;
    }

    return row;
  };

  useEffect(() => {
    if (isSuccess) {
      setStudentList(Object.values(students.entities));
    }
  }, [isSuccess, students]);
  const rows = studentList?.map((student) => {
    return createStudentTableRow(student);
  });

  useEffect(() => {
    if (!searchText) {
      setFilteredRows([...(rows ?? [])]);
    } else {
      const filteredData = rows?.filter((data) => {
        const name = data.name ? data.name.toLowerCase() : "";
        const searchTerm = searchText ? searchText.toLowerCase() : "";
        return name.includes(searchTerm);
      });
      setFilteredRows([...(filteredData ?? [])]);
    }
  }, [searchText, studentList]);

  const columns = [
    { id: "identifer", label: "Identifier", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 250 },
    {
      id: "classField",
      label: "Class",
      minWidth: 100,
    },
    {
      id: "grade",
      label: "Grade",
      minWidth: 100,
      align: "center",
    },
    {
      id: "program",
      label: "Program",
      minWidth: 150,
      align: "center",
    },
    {
      id: "actionBtn",
      label: "",
      minWidth: 200,
      align: "right",
    },
  ];

  return (
    <>
      <div className="flex items-end flex-wrap gap-4 justify-between mb-3">
        <div className="flex  flex-col items-start gap-2">
          <Typography fontWeight="700" className="font-bold" variant="h3">
            Students
          </Typography>
          <Typography variant="h5">
            {filteredRows?.length} Students enrolled
          </Typography>
        </div>
        <div className="flex items-center gap-6">
          <MLSearchInput
            placeholderText="Search"
            setSearchChange={setSearchText}
          />
          <MLButton
            btnSize="large"
            btnVariant="contained"
            btnText="Add student"
            classList="btn-red"
          />
        </div>
      </div>

      <div className="student-list-table h-full max-h-[calc(100%_-_69px)]">
        <MLTable
          columns={columns}
          rows={filteredRows}
          handleEnrollClick={handleEnrollClick}
        />
        {isModalOpen && (
          <MLModal
            open={isModalOpen}
            setOpenModal={setIsModalOpen}
            studentID={studentId}
          >
            <div>
              <h1>This is testing heading</h1>
            </div>
          </MLModal>
        )}
      </div>
    </>
  );
};
export default StudentHome;
