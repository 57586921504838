const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const FlexBetween = styled(Box)(({ wrap = false }) => ({
  display: "flex",
  flexWrap: wrap ? "wrap" : "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default FlexBetween;
