import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import DashLayout from "./components/layout/DashLayout";
import Welcome from "./features/Welcome";
import StudentsHome from "./features/StudentHome/StudentsHome";
import WarnHome from "./features/warnTest/warnHome";
import AssessmentGroupSummary from "features/assssmentResults/AssessmentGroupSummary";
import WarpHome from "./features/warpTest/warpHome";
import EditStudent from "./features/students/EditStudent";
import NewStudent from "./features/students/NewStudent";
import ReportsList from "./features/reports/ReportsList";
import ResultsByBenchmark_frm from "./features/reports/ResultsByBenchmark_frm";
import ClassBandMatrix_frm from "./features/reports/ClassBandMatrix_frm";
import StudentEnrolledProgram from "./features/students/StudentEnrolledProgram";
import StudentProfile from "./features/students/StudentProfile";
import Prefetch from "./features/auth/Prefetch";
import PrefetchReports from "./features/reports/PrefetchReports";
import PrefetchConfig from "./features/admin/PrefetchConfig";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import AdminHome from "./features/admin/AdminHome";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { themeSettings } from "theme";
import { useMemo } from "react";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import ReportInitiaLitSnapshot from "features/reports/ReportInitiaLitSnapshot";
import ReportInitiaLitYTD from "features/reports/ReportInitiaLitYTD";
import ReportInitiaLitTabularData from "features/reports/charts/ReportInitialitTabular";
import ReportinitialitStudentWise from "features/reports/charts/ReportinitialitStudentWise";
import NewClass from "features/admin/NewClass";
import ClassList from "features/admin/ClassList";

function App() {
  useTitle("MultiLit Assessment Platform");

  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <ErrorBoundary customMessage="Something went wrong for App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route index element={<Public />} />
            <Route path="login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>
              <Route
                element={
                  <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
                }
              >
                <Route element={<Prefetch />}>
                  <Route path="home" element={<DashLayout />}>
                    <Route index element={<Welcome />} />
                    <Route
                      element={
                        <RequireAuth
                          allowedRoles={[ROLES.Manager, ROLES.Admin]}
                        />
                      }
                    >
                      {/* user routes when built */}
                    </Route>

                    <Route path="students">
                      <Route
                        index
                        element={
                          <ErrorBoundary customMessage="Something went wrong with the Student Home">
                            <StudentsHome />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="profile/:sId"
                        element={
                          <ErrorBoundary customMessage="Something went wrong with the Student Profile">
                            <StudentProfile />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="enrolled/:sId/:programEnrolmentId"
                        element={
                          <ErrorBoundary customMessage="Something went wrong with the Enrolled Program">
                            <StudentEnrolledProgram />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="assessment-group-summary/:programEnrolmentId/:asmtGroupId"
                        element={
                          <ErrorBoundary customMessage="Something went wrong with the Assesment Group Summary">
                            <AssessmentGroupSummary />
                          </ErrorBoundary>
                        }
                      />
                    </Route>

                    <Route path="warn">
                      <Route
                        index
                        element={
                          <ErrorBoundary customMessage="Something went wrong in warn section">
                            <WarnHome />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={[ROLES.Manager, ROLES.Admin]}
                          />
                        }
                      ></Route>
                    </Route>

                    <Route path="warp">
                      <Route
                        index
                        element={
                          <ErrorBoundary customMessage="Something went wrong in warp section">
                            <WarpHome />
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={[ROLES.Manager, ROLES.Admin]}
                          />
                        }
                      >
                        {/* <Route path="test/:id" element={<WarpTest />} />
											<Route path="new" element={<WarpNew />} />
											<Route
												path="new-incremental"
												element={<WarpNewIncremental />}
											/> */}
                      </Route>
                    </Route>

                    <Route element={<PrefetchReports />}>
                      <Route path="reports">
                        <Route
                          index
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports list section">
                              <ReportsList />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="results-by-benchmark"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports benchmark section">
                              <ResultsByBenchmark_frm />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="band-matrix/:program"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports band matrix section">
                              <ClassBandMatrix_frm />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="initialit-snapshot"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports initialit-snapshot">
                              <ReportInitiaLitSnapshot />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="initialit-ytd"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports initialit-ytd">
                              <ReportInitiaLitYTD />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="initialit-tabular"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports initialit-tabular">
                              <ReportInitiaLitTabularData />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="initialit-studentwise"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in reports initialit-studentwise">
                              <ReportinitialitStudentWise />
                            </ErrorBoundary>
                          }
                        />
                      </Route>
                    </Route>

                    {/* <Route element={<PrefetchConfig />}> */}
                    <Route path="admin">
                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={[ROLES.Manager, ROLES.Admin]}
                          />
                        }
                      >
                        <Route
                          index
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin section">
                              <AdminHome />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="new-class"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin new class">
                              <NewClass />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="update-class/:classId"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin update class">
                              <NewClass />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="class-list"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin class list">
                              <ClassList />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="new-student"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin new student">
                              <NewStudent />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="update-student/:studentId"
                          element={
                            <ErrorBoundary customMessage="Something went wrong in admin update student">
                              <NewStudent />
                            </ErrorBoundary>
                          }
                        />
                      </Route>
                    </Route>
                    {/* </Route> */}
                  </Route>
                  {/* End Dash */}
                </Route>
              </Route>
            </Route>
            {/* End Protected Routes */}
          </Route>
        </Routes>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
