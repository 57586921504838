import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Put new initial states in here
  studentId: 0,
  termNumber: "",
  studentFilters: [],
  conductAssessment_ShowAddInfo: false,
  reportDrilldown_StudentSelected: false,
  // Conduct Asmt State
  caAsmtId: 0,
  caAsmtInfo: {},
  caManage: { asmtEdit: false, resultChange: false, currAsmt: 0 },
  caAsmtResult: [],
  caNextAsmt: {},
  caExistingResult: false,
  caGroupInstComp: false,
  caGroupComp: false,
  caAsmtInstComp: false,
  caHiddenGroupInst: [],
  caHiddenAsmtInst: [],
  caTimerOn: false,
  caFinalEdit: false,
  rptEnrolledProgId: 0,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setStudentId: (state, action) => {
      state.studentId = action.payload;
    },
    setTermNumber: (state, action) => {
      state.termNumber = action.payload;
    },
    setStudentFilters: (state, action) => {
      state.studentFilters = action.payload;
    },
    setConductAssessment_ShowAddInfo: (state, action) => {
      state.conductAssessment_ShowAddInfo = action.payload;
    },
    setReportDrilldown_StudentSelected: (state, action) => {
      state.reportDrilldown_StudentSelected = action.payload;
    },

    // Conduct Asmt State
    setCaAsmtId: (state, action) => {
      state.caAsmtId = action.payload;
    },
    setCaAsmtInfo: (state, action) => {
      state.caAsmtInfo = action.payload;
    },
    setCaManage: (state, action) => {
      state.caManage = action.payload;
    },
    setCaAsmtResult: (state, action) => {
      state.caAsmtResult = action.payload;
    },
    setCaNextAsmt: (state, action) => {
      state.caNextAsmt = action.payload;
    },
    setCaExistingResult: (state, action) => {
      state.caExistingResult = action.payload;
    },
    setCaGroupInstComp: (state, action) => {
      state.caGroupInstComp = action.payload;
    },
    setCaGroupComp: (state, action) => {
      state.caGroupComp = action.payload;
    },
    setCaAsmtInstComp: (state, action) => {
      state.caAsmtInstComp = action.payload;
    },
    setCaHiddenGroupInst: (state, action) => {
      state.caHiddenGroupInst = action.payload;
    },
    setCaHiddenAsmtInst: (state, action) => {
      state.caHiddenAsmtInst = action.payload;
    },
    setCaTimerOn: (state, action) => {
      state.caTimerOn = action.payload;
    },
    setCaFinalEdit: (state, action) => {
      state.caFinalEdit = action.payload;
    },

    // report state
    setRptEnrolledProgId: (state, action) => {
      state.rptEnrolledProgId = action.payload;
    },

    // Add more reducers for state that we need to set here
  },
});

export const {
  setStudentId,
  setTermNumber,
  setStudentFilters,
  setConductAssessment_ShowAddInfo,
  setReportDrilldown_StudentSelected,
  setCaAsmtId,
  setCaAsmtInfo,
  setCaManage,
  setCaAsmtResult,
  setCaNextAsmt,
  setCaExistingResult,
  setCaGroupInstComp,
  setCaGroupComp,
  setCaAsmtInstComp,
  setCaHiddenGroupInst,
  setCaHiddenAsmtInst,
  setCaTimerOn,
  setCaFinalEdit,
  setRptEnrolledProgId,
} = globalSlice.actions;

export default globalSlice.reducer;
