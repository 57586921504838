import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, useTheme, InputBase, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import Tag from "./Tag";
import FlexBetween from "components/utilities/FlexBetween";
import {
  useAddStudentTagMutation,
  useRemoveStudentTagMutation,
  useGetTagsByStudentIdQuery,
  useGetTagsByGroupQuery,
} from "../../app/api/tagsApiSlice";
import PulseLoader from "react-spinners/PulseLoader";

const TagSelector = ({ enrolGroupId = null }) => {
  const studentId = useSelector((state) => state.global.studentId);
  const theme = useTheme();

  const [s, setS] = useState("");
  const [localStudentTags, setLocalStudentTags] = useState([]);

  // Fetch tags by student ID
  const {
    data: studentTagsData,
    isLoading: isLoadingStudentTags,
    isError: isErrorStudentTags,
    error: errorStudentTags,
  } = useGetTagsByStudentIdQuery(studentId);

  // Fetch all tags by group
  const {
    data: allTags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
    error: errorTags,
  } = useGetTagsByGroupQuery();

  const [addTag] = useAddStudentTagMutation();
  const [removeTag] = useRemoveStudentTagMutation();

  // Initialize local state when student tags data is fetched
  useEffect(() => {
    if (studentTagsData) {
      setLocalStudentTags(studentTagsData);
    }
  }, [studentTagsData]);

  // Handle adding a tag
  const handleAddTag = async (tagId) => {
    await addTag({
      tagId: tagId,
      studentId: studentId,
      enrolledGroupId: enrolGroupId,
    });
    // Update local state
    setLocalStudentTags((prev) => [
      ...prev,
      { tag_id: tagId, removed_date: null },
    ]);
  };

  // Handle removing a tag
  const handleRemoveTag = async (tagAppliedId) => {
    await removeTag({ studentId: studentId, tagAppliedId: tagAppliedId });
    setLocalStudentTags((prev) =>
      prev.filter((tag) => tag.tag_applied_id !== tagAppliedId),
    );
  };

  // Loading and error states
  if (isLoadingStudentTags || isLoadingTags) {
    return <PulseLoader color={"#FFF"} />;
  }

  if (isErrorStudentTags || isErrorTags) {
    console.error("Error in TagSelector:", {
      errorStudentTags,
      errorTags,
    });
    return <p className="errmsg">Check console for error</p>;
  }

  // Filter and process tags for display
  const { ids, entities } = allTags || {};
  const tags = ids
    ? ids.map((tag_id) => {
        const tag = entities[tag_id];
        const studentTag = localStudentTags.find(
          (studentTag) =>
            studentTag.tag_id === tag.tag_id &&
            studentTag.removed_date === null,
        );

        return {
          ...tag,
          tag_applied: !!studentTag,
          tag_applied_id: studentTag?.tag_applied_id || null,
          applied_user: studentTag?.applied_user || null,
          applied_date: studentTag?.applied_date || null,
          program_name: studentTag?.program_name || null,
          group_name: studentTag?.group_name || null,
        };
      })
    : [];

  // Filter tags based on search input
  const filteredTags = tags.filter(
    (tag) => s === "" || tag.tag_name.toLowerCase().includes(s.toLowerCase()),
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      border="solid 1px #000"
      m="1rem"
      borderRadius="0.5rem"
    >
      <FlexBetween
        backgroundColor={theme.palette.primary[100]}
        borderRadius="180px"
        gap="3rem"
        p="0.1rem 1.5rem"
        m="0.2rem 0"
        width="40%"
      >
        <InputBase
          placeholder="Search..."
          value={s}
          onChange={(e) => setS(e.target.value)}
        />
        <IconButton>
          <Search sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </FlexBetween>
      <Box display="flex" justifyContent="center" flexWrap="wrap" m="1rem">
        {filteredTags.length > 0 ? (
          filteredTags.map((tag) => (
            <Tag
              key={tag.tag_id}
              tag={tag}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
            />
          ))
        ) : (
          <p className="errmsg">No tags found</p>
        )}
      </Box>
    </Box>
  );
};

export default TagSelector;
