import React from "react";

// State

// MUI Components
import { useTheme, Typography, Button, Tooltip } from "@mui/material";

// MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const Tag = ({ tag, handleAddTag, handleRemoveTag }) => {
  const theme = useTheme();

  let applied;
  if (tag.tag_applied) {
    let str =
      "Tag applied by " +
      tag.applied_user +
      " on " +
      new Date(tag.applied_date).toLocaleDateString("en-AU") +
      ". The tag was applied " +
      (tag.program_name
        ? " on " + tag.group_name + "."
        : "in the student profile.");
    applied = <Typography variant="h5">{str}</Typography>;
  }

  return (
    <Tooltip title={applied}>
      <Button
        variant="contained"
        sx={{
          borderRadius: "1rem",
          backgroundColor: tag.tag_applied
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
          m: "0.5rem",
        }}
        onClick={() => {
          if (!tag.tag_applied) {
            handleAddTag(tag.tag_id);
          }
        }}
      >
        <Typography variant="body2" p="0 0.5rem 0 0">
          {tag.tag_name}
        </Typography>
        {tag.tag_applied && (
          <CancelIcon
            onClick={() => {
              if (tag.tag_applied) {
                handleRemoveTag(tag.tag_applied_id);
              }
            }}
            fontSize="small"
          />
        )}
      </Button>
    </Tooltip>
  );
};

export default Tag;
