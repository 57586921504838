import { Menu, MenuItem } from "@mui/material";
import React from "react";
import "./MenuPopover.module.scss";

function MLMenuPopover({ anchorEl, isOpen, setAnchorEl, menuOptions }) {
  return (
    <Menu
      className="menu-popover-wrap"
      classes={{ paper: "menu-popover-paper" }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {menuOptions?.map((option) => (
        <MenuItem selected={false} key={option.text} onClick={option?.func}>
          {option.text}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MLMenuPopover;
