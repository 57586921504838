import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// State
import {
  setCaGroupInstComp,
  setCaAsmtInstComp,
  setCaHiddenAsmtInst,
  setCaManage,
} from "../../app/state";

// MUI Components
import { Button } from "@mui/material";

// MUI Icons

// Custom components
import RawHTML from "components/common/RawHTML";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";

// Custom hooks

// APIs

// Images

// Random Others
import { imageFormatter } from "utils/index";

const DisplayAsmtInstruction = ({ asmtName, instType, inst }) => {
  const dispatch = useDispatch();

  const caManage = useSelector((state) => state.global.caManage);
  const hiddenInst = useSelector((state) => state.global.caHiddenAsmtInst);
  const asmt = caManage.currAsmt;

  let stimuli;

  const [i, setI] = useState(0);

  const handleNextClick = (moveNext = true) => {
    if (i + 1 >= instCnt) {
      dispatch(setCaAsmtInstComp(true));
    } else if (moveNext) {
      setI(i + 1);
    }
  };

  const handlePrevClick = () => {
    if (i === 0) {
      // If the first instruction of the first assessment, go back to the group instructions
      if (asmt === 0) {
        dispatch(setCaGroupInstComp(false));
      } else {
        // If the first instruction of the assessment, go back to the previous assessment
        let tempManage = structuredClone(caManage);
        tempManage.currAsmt = asmt - 1;
        dispatch(setCaManage(tempManage));
      }
    } else {
      setI(i - 1);
    }
  };

  const hideThisInst = (id) => {
    let h = [...hiddenInst];
    h.push(id);
    dispatch(setCaHiddenAsmtInst([...h]));
    handleNextClick(false);
  };

  const instSeq = [...inst];
  instSeq.sort((a, b) => parseInt(a.seq_no) - parseInt(b.seq_no));

  var instCnt = instSeq.length;

  if (instCnt === 0) {
    dispatch(setCaAsmtInstComp(true));
  } else {
    if (instSeq[i].display_type === "image") {
      stimuli = imageFormatter(
        instSeq[i].image_file,
        "mapImages/",
        instSeq[i].display_type,
        "auto",
        "25vh",
        "center",
        "scale-down",
      );
    }
  }

  let content = (
    <VertCenter id="container" sx={{ width: "100%", height: "80vh" }}>
      <HorizontalCenter id="prev-button" sx={{ width: "15%" }}>
        <Button
          variant="contained"
          disabled={i === 0 && asmt === 0}
          onClick={() => handlePrevClick()}
        >
          Previous
        </Button>
      </HorizontalCenter>
      <HorizontalCenter sx={{ p: "3rem", width: "70%", height: "100%" }}>
        {/* <Typography variant="h2">{asmtName}</Typography> */}
        <HorizontalCenter sx={{ p: "2.5rem 0rem", width: "100%" }}>
          <RawHTML htmlString={instSeq[i].instruction} />
          {instSeq[i].display_type === "image" && stimuli}
        </HorizontalCenter>
        {instSeq[i].can_be_skipped === true && (
          <Button
            variant="contained"
            onClick={() => hideThisInst(instSeq[i].asmt_instruction_id)}
          >
            Hide for This Session
          </Button>
        )}
      </HorizontalCenter>
      <HorizontalCenter id="next-button" sx={{ width: "15%" }}>
        <Button variant="contained" onClick={() => handleNextClick()}>
          Next
        </Button>
      </HorizontalCenter>
    </VertCenter>
  );

  return content;
};

export default DisplayAsmtInstruction;
