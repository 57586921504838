import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// State
import { setCaHiddenGroupInst, setCaHiddenAsmtInst } from "../../app/state";

// Custom hooks
import useAuth from "../../hooks/useAuth";

// APIs
import { useSendLogoutMutation } from "../../app/api/authApiSlice";

// Images
import profileImage from "assets/profile.jpg";
import { MLSearchInput } from "components/common/SearchInput/SearchInput";
import CustomIcon from "components/common/CustomIcon";
import MLMenuPopover from "components/common/MenuePopover/MenuPopover";

// Random Others

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen, drawerWidth }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const navigate = useNavigate();
  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  const { username, status } = useAuth();

  const showHiddenInst = () => {
    dispatch(setCaHiddenGroupInst([]));
    dispatch(setCaHiddenAsmtInst([]));
  };

  const profileMenuOptions = [
    { text: "Log out", func: sendLogout },
    { text: "Show Hidden Instructions", func: showHiddenInst },
  ];

  return (
    <div className="flex justify-between gap-4 items-center px-[32px] py-[10px] h-[81px] border-b border-[var(--Grey-30)] ">
      <MLSearchInput classList="max-w-[371px]" placeholderText="Search" />
      <div
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`flex items-center gap-2 cursor-pointer relative group hover:border-[var(--Blue-100)]  border transition transform  p-[5px_10px_5px_5px] rounded-[48px] ${
          anchorEl ? "border-[var(--Blue-100)]" : "border-transparent"
        }`}
      >
        <img
          className="h-[47px] w-[47px] rounded-full object-cover"
          src={profileImage}
          alt="profileimg"
        />
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-[2px]">
            <p className="text-[var(--Grey-100)] text-base  font-normal leading-tight">
              {username}
            </p>
            <span
              className={` group-hover:text-[var(--Blue-100)] text-base font-normal leading-tight ${
                anchorEl ? "text-[var(--Blue-100)]" : "text-[var(--Grey-40)]"
              }`}
            >
              {status}
            </span>
          </div>
          <div
            className={`icon-wrap transition ease-in-out duration-200 ${
              anchorEl ? "transform rotate-180" : ""
            }`}
          >
            <CustomIcon iconName="Down" stroke="var(--Grey-40)" />
          </div>
        </div>
      </div>

      <MLMenuPopover
        anchorEl={anchorEl}
        isOpen={isOpen}
        setAnchorEl={setAnchorEl}
        sendLogout={sendLogout}
        menuOptions={profileMenuOptions}
      />
    </div>
  );
};

export default Navbar;
