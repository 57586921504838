import React from "react";
import { useState } from "react";

// State

// MUI Components
import Button from "@mui/material/Button";

// MUI Icons
import LoupeIcon from "@mui/icons-material/Loupe";

// Custom components
import EnrolStudentInProgram from "features/programs/EnrolStudentInProgram";

const EnrolButton = ({ style, sxProps }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const props = { ...style, ...sxProps };

  let content = (
    <>
      <Button
        variant="contained"
        startIcon={<LoupeIcon />}
        sx={props}
        onClick={handleClickOpen}
      >
        Enrol In Program
      </Button>
      {open && <EnrolStudentInProgram setOpen={setOpen} />}
    </>
  );

  return content;
};

export default EnrolButton;
