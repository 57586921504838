import { Outlet, Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import usePersist from "../../hooks/usePersist";
import useVerifyToken from "../../hooks/useVerifyToken";

const PersistLogin = () => {
  const [persist] = usePersist();
  const { isTokenVerified, isLoading, isError, error, isSuccess, token } =
    useVerifyToken(persist);

  if (!persist) return <Outlet />;

  if (isLoading) return <PulseLoader color="#FFF" />;

  if (isError) {
    return (
      <p className="errmsg">
        {`${error?.data?.message} - `}
        <Link to="/login">Please login again</Link>.
      </p>
    );
  }

  if ((isSuccess && isTokenVerified) || (token && isLoading === false)) {
    return <Outlet />;
  }

  return null;
};

export default PersistLogin;
