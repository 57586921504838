import React from "react";
import { useNavigate } from "react-router-dom";

// State

// MUI Components
import { Button } from "@mui/material";

// MUI Icons

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const ReportsList = () => {
  const navigate = useNavigate();
  return (
    <div width="80%">
      <Button
        variant="contained"
        sx={{ m: "0.5rem" }}
        onClick={() => navigate(`/home/reports/initialit-ytd`)}
      >
        InitiaLit YTD
      </Button>
      <Button
        variant="contained"
        sx={{ m: "0.5rem" }}
        onClick={() => navigate(`/home/reports/initialit-snapshot`)}
      >
        InitiaLit Snapshot
      </Button>
      <Button
        variant="contained"
        sx={{ m: "0.5rem" }}
        onClick={() => navigate(`/home/reports/initialit-tabular`)}
      >
        InitiaLit Tabular
      </Button>
      <Button
        variant="contained"
        sx={{ m: "0.5rem" }}
        onClick={() => navigate(`/home/reports/initialit-studentwise`)}
      >
        InitiaLit Studentwise
      </Button>
      {/* <Template1 testItem={testItem} funcs={funcs} /> */}
    </div>
  );
};

export default ReportsList;
