import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// State

// MUI Components
import {
  Box,
  useTheme,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

// MUI Icons
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

// Custom components
import StudentEnrolledProgramForm from "./StudentEnrolledProgramForm";
import Field from "components/Field";

// Custom hooks
import useTitle from "../../hooks/useTitle";

// APIs
import { useGetStudentProfileQuery } from "../../app/api/studentsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

const StudentEnrolledProgram = () => {
  useTitle("MAP: Enrolled Programs");
  const [status, setStatus] = useState("Enrolled");
  const navigate = useNavigate();
  const theme = useTheme();

  let studentId = useSelector((state) => state.global.studentId);

  const { programEnrolmentId, sId } = useParams();

  if (studentId === 0 || !studentId) {
    studentId = sId;
  }

  // Pull student profile
  const {
    data: studentProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentProfileQuery(studentId);

  const goToProgEnrol = () => {
    navigate(`/home/students/profile/${studentId}`);
  };

  // Change enrollment status
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    return <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    let studentHeight = "30vh";

    const student = studentProfile[0];

    content = (
      <ErrorBoundary customMessage="Something went wrong in Student Enrolled Program Page">
        <Box m="20px" shadow="8">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <Button
              variant="contained"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={goToProgEnrol}
              sx={{ m: "0 0 20px 0" }}
            >
              Back to Student Profile
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.primary[200],
            }}
            borderRadius="10px"
            padding="20px"
          >
            <Box width="65%">
              <Grid container spacing={2}>
                <Grid item xs={4} md={6}>
                  <Field
                    label="Name"
                    value={student.first_name + " " + student.last_name}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <Field label="Grade" value={student.accademic_year} />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Field label="Student Id" value={studentId} />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Field label="School" value={student.school_name} />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Field label="Class" value={student.class_name} />
                </Grid>
                {student?.user_access_level &&
                student?.user_access_level === "admin" ? (
                  <Grid item xs={4} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="enrollmentStatus">Status</InputLabel>
                      <Select
                        labelId="enrollmentStatusLabel"
                        id="enrollmentStatus"
                        value={status}
                        label="Status"
                        onChange={handleChangeStatus}
                      >
                        <MenuItem value="Enrolled" selected>
                          Enrolled
                        </MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                        <MenuItem value="Un-enrolled">Un-enrolled</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          </Box>
          <Box>
            <StudentEnrolledProgramForm
              student={student}
              programEnrolmentId={programEnrolmentId}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    );
  }

  return content;
};

export default StudentEnrolledProgram;
