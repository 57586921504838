import { useState, useEffect, useRef } from "react";
import { useRefreshMutation } from "../app/api/authApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../app/api/authSlice";

const useVerifyToken = (persist) => {
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  const [refresh, { isLoading, isError, error, isSuccess }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current || process.env.NODE_ENV !== "development") {
      const verifyRefreshToken = async () => {
        try {
          await refresh();
          setIsTokenVerified(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => {
      effectRan.current = true;
    };
  }, [token, persist, refresh]);

  return { isTokenVerified, isLoading, isError, error, isSuccess, token };
};

export default useVerifyToken;
