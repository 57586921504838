import React, { useEffect, useState } from "react";

// MUI Components
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

// APIs
import { useGetConfigForUserQuery } from "app/api/configApiSlice";
import {
  useAddNewStudentMutation,
  useGetStudentProfileQuery,
  useUpdateStudentMutation,
} from "app/api/studentsApiSlice";

// Other components
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Reusable Select component
const SelectField = ({ label, value, onChange, options, disabled }) => (
  <FormControl fullWidth>
    <InputLabel id={`select-${label}`}>{label}</InputLabel>
    <Select
      labelId={`select-${label}`}
      value={value}
      label={label}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const CreateStudent = () => {
  const { studentId } = useParams();

  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filteredClassOptions, setFilteredClassOptions] = useState([]);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    academicYear: "",
    dateOfBirth: null,
    selectedGender: "",
    selectedRegion: "",
    selectedSchool: "",
    selectedClass: "",
    selectedEnrollmentStatus: "",
    selectedGroupEnrollmentStatus: "",
    selectedAtsi: "",
    selectedEald: "",
  });

  const {
    data: studentProfile,
    isLoading: isStudentProfileLoading,
    isSuccess: isSuccessStudentProfile,
    isError: isErrorStudentProfile,
  } = useGetStudentProfileQuery(studentId, {
    skip: !studentId,
  });

  const {
    data: config,
    isLoading: dataLoading,
    isError: dataError,
    isSuccess,
    error,
  } = useGetConfigForUserQuery("configList", { refetchOnFocus: false });

  const extractConfigOptions = (key) =>
    config?.find((item) => item.config === key)?.options || [];

  const genderOptions = extractConfigOptions("gender");
  const academicYearOptions = extractConfigOptions("accademic_year");
  const atsiOptions = extractConfigOptions("atsi");
  const ealdOptions = extractConfigOptions("eald");
  const schoolOptions = extractConfigOptions("school");
  const classOptions = extractConfigOptions("class");

  useEffect(() => {
    if (isSuccessStudentProfile) {
      if (studentProfile && (config ?? []).length) {
        const [studentData] = studentProfile;

        const class_id = studentData.class_id;
        const school_id = studentData.school_id;

        const filteredClasses = extractConfigOptions("class").filter(
          (data) => +data.school_id === +school_id,
        );

        const selectedAcademicYearID = extractConfigOptions(
          "accademic_year",
        ).find(
          (option) => option.accademic_year === studentData.accademic_year,
        ).accademic_year_id;

        const selectedGender = extractConfigOptions("gender").find(
          (option) => option.gender === studentData.gender,
        ).gender_id;

        setFilteredClassOptions(filteredClasses);

        setFormData((prevData) => ({
          ...prevData,
          firstName: studentData.first_name,
          lastName: studentData.last_name,
          academicYear: selectedAcademicYearID,
          dateOfBirth: studentData.date_of_birth
            ? dayjs(studentData.date_of_birth).format("YYYY-MM-DD")
            : null,
          selectedAtsi: studentData.atsi_id ?? null,
          selectedEald: studentData.eald_id ?? null,
          selectedRegion:
            studentData.region_code + " " + studentData.region_name,
          selectedGender: selectedGender,
          selectedSchool: {
            school_name: studentData.school_name,
            school_id: school_id,
          },
          selectedClass: class_id,
        }));
      }
    }
    if (isErrorStudentProfile) {
      console.error("Error loading student profile:", error);
    }
  }, [isSuccessStudentProfile, studentProfile, config]);

  useEffect(() => {
    if (isSuccess) {
      console.log(config);
    }
  }, [isSuccess, config]);

  const [createStudent, createResult] = useAddNewStudentMutation();
  const [updatesStudent, updatesStudentResult] = useUpdateStudentMutation();

  useEffect(() => {
    if (createResult.isSuccess || updatesStudentResult.isSuccess) {
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/home/students");
      }, 500);
    }
  }, [createResult.isSuccess, updatesStudentResult.isSuccess, navigate]);

  // Handler to close the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Validate form
  const validateForm = () => {
    let tempErrors = {};

    if (!formData.firstName) tempErrors.firstName = "First name is required.";
    if (!formData.lastName) tempErrors.lastName = "Last name is required.";
    if (!formData.selectedAtsi) tempErrors.selectedAtsi = "Atsi is required.";
    if (!formData.selectedEald) tempErrors.selectedEald = "Eald is required.";
    if (!formData.academicYear)
      tempErrors.academicYear = "Academic year is required.";
    if (!formData.selectedGender)
      tempErrors.selectedGender = "Gender is required.";
    if (!formData.selectedSchool)
      tempErrors.selectedSchool = "School is required.";
    if (!formData.selectedClass)
      tempErrors.selectedClass = "Class is required.";
    if (!formData.dateOfBirth) tempErrors.dateOfBirth = "DOB is required.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  // Control On Schoool Select
  const onSelectSchool = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedSchool: value,
    }));

    if (value) {
      const filteredClasses = classOptions.filter(
        (data) => +data.school_id === +value.school_id,
      );
      setFilteredClassOptions(filteredClasses);
    } else {
      setFilteredClassOptions([]);
    }
  };

  // Handle Form Submission
  const handleSubmit = () => async (e) => {
    if (e) e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const studentData = {
      accademic_year_id: formData.academicYear,
      class_id: formData.selectedClass,
      atsi_id: +formData.selectedAtsi,
      date_of_birth: dayjs(formData.dateOfBirth).format("YYYY-MM-DD"),
      eald_id: +formData.selectedEald,
      student_identifier: `NBUI-BIOU-${Math.random(0, 100) * 100}`,
      first_name: formData.firstName,
      last_name: formData.lastName,
      is_active: true,
      gender_id: +formData.selectedGender,
    };

    if (studentId) {
      studentData.student_id = studentId;
      await updatesStudent(studentData);
    } else {
      await createStudent(studentData);
    }
  };

  if (dataError) return <p className="errmsg">{error?.data?.message}</p>;
  if (dataLoading || isStudentProfileLoading)
    return <PulseLoader color="#000" />;

  return (
    <Box sx={{ padding: 3, bgcolor: "#f5f5f5", borderRadius: 2 }}>
      {/* Snackbar to display the success message */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          {studentId
            ? "Student updated successfully."
            : "Student created successfully."}
        </Alert>
      </Snackbar>
      <Typography variant="h4" gutterBottom>
        {studentId ? "Update Student" : "Create Student"}
      </Typography>
      <Grid container spacing={3}>
        {/*First Name */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            sy={{ m: 1 }}
            required
            id="Firstname"
            label="Firstname"
            value={formData.firstName}
            onChange={(event) => {
              setFormData({ ...formData, firstName: event.target.value });
            }}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        {/* Last Name */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            sy={{ m: 1 }}
            required
            id="Lastname"
            label="Lastname"
            value={formData.lastName}
            onChange={(event) => {
              setFormData({ ...formData, lastName: event.target.value });
            }}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>

        {/* Academic Year Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.academicYear}>
            <SelectField
              label="Academic Year"
              value={formData.academicYear}
              onChange={(e) => {
                setFormData({ ...formData, academicYear: e.target.value });
              }}
              options={academicYearOptions.map(
                ({ accademic_year_id, accademic_year }) => ({
                  id: accademic_year_id,
                  name: accademic_year,
                }),
              )}
              required
            />
            <FormHelperText>{errors.academicYear}</FormHelperText>
          </FormControl>
        </Grid>

        {/* Date of birth */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.dateOfBirth}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date of birth"
                  value={dayjs(formData.dateOfBirth)}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      dateOfBirth: date,
                    });
                  }}
                  slotProps={{
                    textField: {
                      error: !!errors.dateOfBirth,
                      helperText: errors.dateOfBirth || "",
                    },
                  }}
                  disableFuture
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </Grid>

        {/* Atsi Selection */}
        <Grid item xs={12} md={12}>
          <FormControl component="fieldset" error={!!errors.selectedAtsi}>
            <FormLabel component="legend">Atsi</FormLabel>
            <RadioGroup
              row
              name="atsi"
              value={formData.selectedAtsi}
              onChange={(e) =>
                setFormData({ ...formData, selectedAtsi: e.target.value })
              }
            >
              {atsiOptions.map((option) => (
                <FormControlLabel
                  key={option.atsi_id}
                  value={+option.atsi_id}
                  control={<Radio />}
                  label={option.atsi_desc}
                />
              ))}
            </RadioGroup>
            {errors.selectedAtsi && (
              <FormHelperText>{errors.selectedAtsi}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Gender Selection */}
        <Grid item xs={12}>
          <FormControl component="fieldset" error={!!errors.selectedGender}>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              row
              name="gender"
              value={formData.selectedGender}
              onChange={(e) =>
                setFormData({ ...formData, selectedGender: e.target.value })
              }
            >
              {genderOptions.map((option) => (
                <FormControlLabel
                  key={option.gender_id}
                  value={option.gender_id}
                  control={<Radio />}
                  label={option.gender}
                />
              ))}
            </RadioGroup>
            {errors.selectedGender && (
              <FormHelperText>{errors.selectedGender}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Eald Selection */}
        <Grid item xs={12}>
          <FormControl component="fieldset" error={!!errors.selectedEald}>
            <FormLabel component="legend">Eald</FormLabel>
            <RadioGroup
              row
              name="eald"
              value={formData.selectedEald}
              onChange={(e) =>
                setFormData({ ...formData, selectedEald: e.target.value })
              }
            >
              {ealdOptions.map((option) => (
                <FormControlLabel
                  key={option.eald_id}
                  value={+option.eald_id}
                  control={<Radio />}
                  label={option.eald_desc}
                />
              ))}
            </RadioGroup>
            {errors.selectedEald && (
              <FormHelperText>{errors.selectedEald}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* School Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.selectedSchool}>
            <Autocomplete
              options={schoolOptions}
              getOptionLabel={(option) => option.school_name || ""}
              onChange={(event, value) => onSelectSchool(event, value)}
              value={formData.selectedSchool || null}
              isOptionEqualToValue={(option, value) =>
                option.school_name === value?.school_name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="School"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            {errors.selectedSchool && (
              <FormHelperText>{errors.selectedSchool}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Class Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.selectedClass}>
            <SelectField
              label="Class"
              value={formData.selectedClass}
              onChange={(e) =>
                setFormData({ ...formData, selectedClass: e.target.value })
              }
              options={filteredClassOptions.map(({ class_id, class_name }) => ({
                id: class_id,
                name: class_name,
              }))}
              disabled={!formData.selectedSchool}
            />
            {errors.selectedClass && (
              <FormHelperText>{errors.selectedClass}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            onClick={handleSubmit()}
            disabled={createResult.isLoading || updatesStudentResult.isLoading}
          >
            {createResult.isLoading || updatesStudentResult.isLoading ? (
              <PulseLoader color="#fff" />
            ) : studentId ? (
              "Update Student"
            ) : (
              "Create Student"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateStudent;
