import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// State
import { setCaTimerOn, setCaFinalEdit } from "../../app/state";

// MUI Components
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// MUI Icons

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const Timer = ({ initSecs }) => {
  const dispatch = useDispatch();

  const timerOn = useSelector((state) => state.global.caTimerOn);
  const finalEdit = useSelector((state) => state.global.caFinalEdit);

  const [seconds, setSeconds] = useState(initSecs);

  const theme = useTheme();

  useEffect(() => {
    if (timerOn) {
      seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
      if (seconds === 0) dispatch(setCaTimerOn(false));
    }
  }, [dispatch, seconds, timerOn]);

  const reset = () => {
    dispatch(setCaTimerOn(false));
    setSeconds(initSecs);
    // new Promise((r) => resetData())
  };

  const TimerButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    variant: "contained",
    fontSize: "1rem",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.neutral.white,
    border: "1px solid #000",
    borderRadius: theme.spacing(0.5),
  }));

  let mins = Math.floor(seconds / 60);
  let secs = seconds % 60;

  let backColour =
    seconds < 10 && (timerOn || seconds === 0)
      ? theme.palette.secondary.main
      : theme.palette.neutral.white;

  return (
    <Box
      className="timer"
      width="100%"
      backgroundColor={backColour}
      sx={{
        display: "flex",
        flexDirection: "columns",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        p: "0.5rem",
      }}
    >
      <Typography variant="h2Plain" sx={{ fontWeight: "bold" }}>
        {seconds === 0
          ? "0:00"
          : " " + mins + ":" + (secs < 10 ? "0" + secs : secs)}
      </Typography>

      <Box
        className="timer-buttons"
        sx={{
          display: "flex",
          flexDirection: "columns",
          justifyContent: "center",
          gap: "5px",
          p: "0 1rem",
        }}
      >
        <TimerButton onClick={() => dispatch(setCaTimerOn(!timerOn))}>
          {timerOn ? "Pause" : "Start"}
        </TimerButton>
        <TimerButton onClick={() => reset()} disabled={timerOn}>
          Reset
        </TimerButton>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={finalEdit}
              disabled={seconds > 0}
              onChange={() => dispatch(setCaFinalEdit(!finalEdit))}
              color="primary"
              inputProps={{
                "aria-label": "final edits",
              }}
            />
          }
          label="Final Edits"
          labelPlacement="left"
        />
      </FormGroup>
    </Box>
  );
};

export default Timer;
