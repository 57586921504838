import React from "react";
// import { useNavigate } from 'react-router-dom'

// State

// MUI Components
import { Box, Typography, useTheme } from "@mui/material";

// MUI Icons

// Custom components
import Dashboard from "./reports/Dashboard";

// Custom hooks
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";

// APIs

// Images
import logoImage from "assets/MultiLit_RGB.png";

// Random Others

const Welcome = () => {
  const { username } = useAuth();

  useTitle(`MultiLit: ${username}`);
  const theme = useTheme();
  // const navigate = useNavigate()

  const date = new Date();
  const today = new Intl.DateTimeFormat("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  }).format(date);

  const content = (
    <Box
      id="main-container"
      sx={{
        borderRadius: "1rem",
        bgcolor: theme.palette.neutral.light,
        p: "0.5rem",
        height: "90vh",
      }}
    >
      <Box
        id="white-container"
        display="flex"
        flexDirection="column"
        sx={{
          height: "100%",
          p: "1rem",
          borderRadius: "1rem",
          bgcolor: theme.palette.neutral.white,
        }}
      >
        <Box id="header" flex="0 1 auto">
          <Box id="date-user-container">
            <Typography
              fontWeight="bold"
              fontSize="0.9rem"
              sx={{ color: theme.palette.primary.main }}
            >
              {today}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              Welcome {username}!
            </Typography>
          </Box>
          <Box
            id="logo-container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              alignItems="center"
              component="img"
              fit="scale-down"
              sx={{
                width: "30%",
              }}
              alt="MultiLit Logo"
              src={logoImage}
            />
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                p: "1rem",
              }}
            >
              Multi
              <Box
                component="span"
                sx={{ color: theme.palette.secondary.main }}
              >
                Lit
              </Box>{" "}
              Assessment Platform
            </Typography>
          </Box>
        </Box>
        <Dashboard />
      </Box>
    </Box>
  );

  return content;
};
export default Welcome;
