import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const assessmentsAdapter = createEntityAdapter({
  selectId: (e) => e.assessment_id,
});

const initialState = assessmentsAdapter.getInitialState();

export const assessmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: () => ({
        url: "/assessments",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAssessments = responseData.map((assessment) => {
          assessment.id = assessment._id;
          return assessment;
        });
        return assessmentsAdapter.setAll(initialState, loadedAssessments);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Assessment", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Assessment", id })),
          ];
        } else return [{ type: "Assessment", id: "LIST" }];
      },
    }),
    addNewAssessment: builder.mutation({
      query: (initialAssessmentData) => ({
        url: "/assessments",
        method: "POST",
        body: {
          ...initialAssessmentData,
        },
      }),
      invalidatesTags: [
        { type: "Assessment", id: "LIST" },
        { type: "WAR-Results", id: "LIST" },
      ],
    }),
    updateAssessment: builder.mutation({
      query: (initialAssessmentData) => ({
        url: "/assessments",
        method: "PATCH",
        body: {
          ...initialAssessmentData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Assessment", id: arg.id },
        { type: "WAR-Results", id: "LIST" },
      ],
    }),
    deleteAssessment: builder.mutation({
      query: ({ id }) => ({
        url: `/assessments`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Assessment", id: arg.id },
        { type: "WAR-Results", id: "LIST" },
      ],
    }),
    getAssessmentInstruction: builder.query({
      query: (instId) => ({
        url: `/assessments/instruction?id=${instId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    addHideAsmtInst: builder.mutation({
      query: ({ asmtInstId }) => ({
        url: "/assessments/instruction/hide/add",
        method: "POST",
        body: {
          asmtInstId,
        },
      }),
    }),
    addHideGroupInst: builder.mutation({
      query: ({ groupInstId }) => ({
        url: "/assessments/group-instruction/hide/add",
        method: "POST",
        body: {
          groupInstId,
        },
      }),
    }),
    removeHideAsmtInst: builder.mutation({
      query: ({ asmtInstId }) => ({
        url: "/assessments/instruction/hide/delete",
        method: "POST",
        body: {
          asmtInstId,
        },
      }),
    }),
    removeHideGroupInst: builder.mutation({
      query: ({ groupInstId }) => ({
        url: "/assessments/group-instruction/hide/delete",
        method: "POST",
        body: {
          groupInstId,
        },
      }),
    }),
    skipAsmtGroup: builder.mutation({
      query: (skipDetails) => ({
        url: "/assessments/skip",
        method: "POST",
        body: {
          ...skipDetails,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
    removeSkipAsmtGroup: builder.mutation({
      query: ({ skippedGroupId, studentId }) => ({
        url: "/assessments/skip",
        method: "DELETE",
        body: {
          skippedGroupId,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
  }),
});

export const {
  useGetAssessmentsQuery,
  useAddNewAssessmentMutation,
  useUpdateAssessmentMutation,
  useDeleteAssessmentMutation,
  useAddHideAsmtInstMutation,
  useAddHideGroupInstMutation,
  useRemoveHideAsmtInstMutation,
  useRemoveHideGroupInstMutation,
  useGetAssessmentInstructionQuery,
  useSkipAsmtGroupMutation,
  useRemoveSkipAsmtGroupMutation,
} = assessmentsApiSlice;

// returns the query result object
export const selectAssessments =
  assessmentsApiSlice.endpoints.getAssessments.select();

// creates memoized selector
const selectAssessmentsData = createSelector(
  selectAssessments,
  (assessments) => assessments.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAssessments,
  selectById: selectAssessmentById,
  selectIds: selectAssessmentIds,
  // Pass in a selector that returns the Assessments slice of state
} = assessmentsAdapter.getSelectors(
  (state) => selectAssessmentsData(state) ?? initialState,
);
