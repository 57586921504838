import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// State
import { setStudentId } from "../../app/state";

// MUI Components
import {
  Box,
  useTheme,
  styled,
  Table,
  Typography,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Divider,
} from "@mui/material";

// MUI Icons

// Custom components
import HorizontalCenter from "components/utilities/HorizontalCenter";
import FlexBetween from "components/utilities/FlexBetween";
import StyledButton from "components/common/StyledButton";
import ProgramProgressChart from "features/reports/charts/ProgramProgressChart";
import EnrolButton from "components/common/EnrolButton";
import TagSelector from "features/tags/TagSelector";

// Custom hooks
import useTitle from "../../hooks/useTitle";

// APIs
import { useGetStudentProfileQuery } from "../../app/api/studentsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import { buttonStyles } from "styles";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

const StudentProfile = () => {
  useTitle("MAP: Student Profile");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary[100],
    },

    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const studentId = useSelector((state) => state.global.studentId);

  const { sId } = useParams();
  const stId = parseInt(sId);

  if (stId !== studentId) {
    dispatch(setStudentId(stId));
  }

  // Pull student profile
  const {
    data: studentProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentProfileQuery(stId);

  const goToProgEnrol = (enrolId) => {
    navigate(`/home/students/enrolled/${stId}/${enrolId}`);
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const sP = studentProfile[0];
    content = (
      <ErrorBoundary customMessage="Something went wrong in Student Profile Page">
        <HorizontalCenter>
          <HorizontalCenter
            id="banner"
            width="100%"
            height="6rem"
            backgroundColor={theme.palette.primary.main}
            borderRadius="1rem"
            color={theme.palette.neutral.white}
          >
            <Typography variant="h1">
              {sP.first_name + " " + sP.last_name}
            </Typography>
            <Typography variant="h2">Class: {sP.class_name}</Typography>
          </HorizontalCenter>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              id="left-column"
              sx={{ width: "30%", height: "100%" }}
              padding="1rem"
            >
              <Grid id="student-info" container spacing={2} margin="0">
                <Grid item xs={5}>
                  <Typography variant="h4">Student Identifier:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">{sP.student_identifier}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">Date of Birth:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">{sP.date_of_birth}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">Grade:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">{sP.accademic_year}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">Gender:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">{sP.gender}</Typography>
                </Grid>
              </Grid>
              <Divider
                aria-hidden="true"
                variant="middle"
                sx={{ height: "2rem" }}
              />
              <Box sx={{ width: "100%", height: "50vh" }}>
                {sP.enrol_prog.length > 0 && <ProgramProgressChart />}
              </Box>
            </Box>
            <Box id="right-column" sx={{ width: "70%" }} padding="1rem">
              <TableContainer
                id="current-enrolments"
                sx={{
                  maxHeight: "57vh",
                  border: "1px solid black",
                  borderRadius: "1rem",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" sx={{ p: "0 0 0 2rem" }}>
                        Current enrolments
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Date of enrolment
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Next assessment
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {sP.user_access_level !== "read" && (
                          <EnrolButton style={buttonStyles.light} />
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sP.enrol_prog
                      .filter((p) => p.prog_enrol_status === "Enrolled")
                      .sort((a, b) => a.prog_enrol_date - b.prog_enrol_date)
                      .map((row, _key) => {
                        return (
                          <StyledTableRow
                            key={_key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              align="left"
                              component="th"
                              scope="row"
                              sx={{
                                p: "0 0 0 2rem",
                              }}
                            >
                              <FlexBetween>
                                {row.prog_name +
                                  (row.enrol_term === 0
                                    ? ""
                                    : " (term " + row.enrol_term + ")")}
                                <StyledButton
                                  children="View results"
                                  style={
                                    buttonStyles[
                                      row.last_asmt_benchmark + "Benchmark"
                                    ]
                                  }
                                  onClick={() =>
                                    goToProgEnrol(row.prog_enrol_id)
                                  }
                                />
                              </FlexBetween>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.prog_enrol_date}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.next_asmt_name}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="contained"
                                onClick={() =>
                                  navigate(
                                    `../assessment-group-summary/${row.prog_enrol_id}/${row.next_asmt_id}`,
                                    {
                                      relative: "path",
                                    },
                                  )
                                }
                                disabled={sP.user_access_level === "read"}
                              >
                                Administer test
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer
                id="past-enrolments"
                sx={{
                  maxHeight: "57vh",
                  border: "1px solid black",
                  borderRadius: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Table stickyHeader sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" sx={{ p: "0 0 0 2rem" }}>
                        Past enrolments
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Date of enrolment
                      </StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left">
                        Last assessment completed
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sP.enrol_prog
                      .filter((p) => p.prog_enrol_status !== "Enrolled")
                      .sort((a, b) => a.prog_enrol_date - b.prog_enrol_date)
                      .map((row, _key) => {
                        return (
                          <StyledTableRow
                            key={_key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              align="left"
                              component="th"
                              scope="row"
                              sx={{
                                p: "0 0 0 2rem",
                              }}
                            >
                              {row.prog_name +
                                (row.enrol_term === 0
                                  ? ""
                                  : " (term " + row.enrol_term + ")")}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.prog_enrol_date}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.prog_enrol_status}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <FlexBetween>
                                {row.last_asmt_name
                                  ? row.last_asmt_name
                                  : "none"}
                                <StyledButton
                                  children="View results"
                                  style={
                                    buttonStyles[
                                      row.last_asmt_benchmark + "Benchmark"
                                    ]
                                  }
                                  onClick={() =>
                                    goToProgEnrol(row.prog_enrol_id)
                                  }
                                />
                              </FlexBetween>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TagSelector />
            </Box>
          </Box>
        </HorizontalCenter>
      </ErrorBoundary>
    );
  }
  return content;
};

export default StudentProfile;
