import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const configAdapter = createEntityAdapter({
  selectId: (e) => e.config,
});

const initialState = configAdapter.getInitialState();

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConfigForUser: builder.query({
      query: () => ({
        url: `/config`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Config", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Config", id })),
          ];
        } else return [{ type: "Config", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetConfigForUserQuery } = configApiSlice;

// returns the query result object
export const selectConfigForUserResult =
  configApiSlice.endpoints.getConfigForUser.select();

// creates memoized selector
const selectConfigForUserData = createSelector(
  selectConfigForUserResult,
  (configForUserResult) => configForUserResult.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllConfig,
  selectById: selectConfigById,
  selectIds: selectConfigIds,
  // Pass in a selector that returns the Config slice of state
} = configAdapter.getSelectors(
  (state) => selectConfigForUserData(state) ?? initialState,
);
