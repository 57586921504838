import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./Table.module.scss";
import MLButton from "../Button/Button";
import { NavLink } from "react-router-dom";

const MLTable = ({ columns, rows, handleEnrollClick }) => {
  if (!(rows ?? []).length) {
    return <h3>No data found !</h3>;
  }

  return (
    <div className="table-wrap">
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.identifier}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    // Handle the action buton column separately
                    if (column.id === "actionBtn") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <MLButton
                            btnText="Enroll"
                            btnSize="medium"
                            btnVariant="outlined"
                            classList="btn-outlined"
                            onClickFunc={() => handleEnrollClick(row.studentID)}
                          />
                        </TableCell>
                      );
                    }

                    // Handle the program column with its specific logic
                    if (column.id === "program") {
                      console.log(value);
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <div className="program-data-wrap">
                            {(value ?? []).length === 0
                              ? "-"
                              : value?.map((val) => (
                                  <span className="initialLit-data">{val}</span>
                                ))}
                          </div>
                        </TableCell>
                      );
                    }

                    // Handle the name column with the navigation logic
                    if (column.id === "name") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <NavLink
                            className="name-text"
                            to={`profile/${row.studentID}`}
                          >
                            {value}
                          </NavLink>
                        </TableCell>
                      );
                    }

                    // Default case for other columns
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MLTable;
