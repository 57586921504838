import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const assessmentResultsAdapter = createEntityAdapter({
  selectId: (e) => e.assessmentResult_id,
});

const initialState = assessmentResultsAdapter.getInitialState();

export const assessmentResultsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssessmentResults: builder.query({
      query: () => ({
        url: "/assessmentResults",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedAssessmentResults = responseData.map((assessmentResult) => {
          assessmentResult.id = assessmentResult._id;
          return assessmentResult;
        });
        return assessmentResultsAdapter.setAll(
          initialState,
          loadedAssessmentResults,
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "AssessmentResult", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "AssessmentResult",
              id,
            })),
          ];
        } else return [{ type: "AssessmentResult", id: "LIST" }];
      },
    }),
    enrolStudent: builder.mutation({
      query: (enrolDetails) => ({
        url: "/assessmentResults/enrolInAssessmentResult",
        method: "POST",
        body: { ...enrolDetails },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
    addNewAssessmentResult: builder.mutation({
      query: (initialAssessmentResultData) => ({
        url: "/assessmentResults",
        method: "POST",
        body: {
          ...initialAssessmentResultData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
    delAssessmentResult: builder.mutation({
      query: ({ enrolAsmtId }) => ({
        url: `/assessmentResults/delete`,
        method: "POST",
        body: { enrolAsmtId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
    updateAssessmentResult: builder.mutation({
      query: (initialAssessmentResultData) => ({
        url: "/assessmentResults",
        method: "PATCH",
        body: {
          ...initialAssessmentResultData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
  }),
});

export const {
  useGetAssessmentResultsQuery,
  useEnrolStudentMutation,
  useAddNewAssessmentResultMutation,
  useUpdateAssessmentResultMutation,
  useDelAssessmentResultMutation,
} = assessmentResultsApiSlice;

// returns the query result object
export const selectAssessmentResultsResult =
  assessmentResultsApiSlice.endpoints.getAssessmentResults.select();

// creates memoized selector
const selectAssessmentResultsData = createSelector(
  selectAssessmentResultsResult,
  (assessmentResultsResult) => assessmentResultsResult.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllAssessmentResults,
  selectById: selectAssessmentResultById,
  selectIds: selectAssessmentResultIds,
  // Pass in a selector that returns the AssessmentResults slice of state
} = assessmentResultsAdapter.getSelectors(
  (state) => selectAssessmentResultsData(state) ?? initialState,
);
