///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PAGE IS TO BE RE-WRITTEN - NO NEED TO REVIEW
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// State
import {
  setStudentId,
  setReportDrilldown_StudentSelected,
} from "../../app/state";

// MUI Components
import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// MUI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WarningIcon from "@mui/icons-material/Warning";

// Custom components

// Custom hooks
import useTitle from "../../hooks/useTitle";

// APIs
import { useGetStudentsByBenchmarkQuery } from "../../app/api/reportsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

const StudentsListFiltered = ({
  regionName,
  schoolName,
  className,
  programName,
  groupName,
  accYear,
  termNumber,
  benchmark,
}) => {
  useTitle("MultiLit: Students List");
  const dispatch = useDispatch();

  const studentSelected = useSelector(
    (state) => state.global.reportDrilldown_StudentSelected,
  );

  const theme = useTheme();
  const [progEnrolID, setProgEnrolID] = useState();
  const [asmtID, setAsmtID] = useState();
  let resIcn;

  const {
    data: studentReportList,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentsByBenchmarkQuery({
    regionName,
    schoolName,
    className,
    programName,
    groupName,
    accYear,
    termNumber,
    benchmark,
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    cursor: "pointer",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary[100],
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    tableRowSelected: {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const handleRowClick = (studentID, progEnrolID, asmtID) => {
    dispatch(setStudentId(studentID));
    setProgEnrolID(progEnrolID);
    setAsmtID(asmtID);
    dispatch(setReportDrilldown_StudentSelected(true));
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#000"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    content = (
      <Box width="90%">
        <Paper sx={{ width: "100%" }}>
          <TableContainer width="100%" sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              width="100%"
              sx={{ minWidth: 650 }}
              aria-label="students for selected benchmark"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Student Name</StyledTableCell>
                  <StyledTableCell align="center">Class</StyledTableCell>
                  <StyledTableCell align="center">Grade</StyledTableCell>
                  <StyledTableCell align="center">Term</StyledTableCell>
                  <StyledTableCell align="center">
                    Assessment Group
                  </StyledTableCell>
                  <StyledTableCell align="center">Avg Score</StyledTableCell>
                  <StyledTableCell align="center">Benchmark</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflow: "auto" }}>
                {studentReportList.map((row, i) => {
                  if (row.benchmark === "high") {
                    resIcn = (
                      <CheckCircleIcon
                        sx={{
                          color: theme.palette.benchmark.high,
                        }}
                      />
                    );
                  } else if (row.benchmark === "mid") {
                    resIcn = (
                      <WarningAmberIcon
                        sx={{
                          color: theme.palette.benchmark.mid,
                        }}
                      />
                    );
                  } else if (row.benchmark === "low") {
                    resIcn = (
                      <WarningIcon
                        sx={{
                          color: theme.palette.benchmark.low,
                        }}
                      />
                    );
                  } else {
                    resIcn = "";
                  }

                  return (
                    <StyledTableRow
                      hover
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() =>
                        handleRowClick(
                          row.student_id,
                          row.program_enrolment_id,
                          row.assessment_group_id,
                        )
                      }
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.student_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.class_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.accademic_year}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.term_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.group_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {Math.round(row.avg_score)}
                      </StyledTableCell>
                      <StyledTableCell align="center">{resIcn}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  }

  return content;
};
export default StudentsListFiltered;
