import React from "react";

// State

// MUI Components
import { Tooltip, useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

// MUI Icons

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const ResultWord = ({ word }) => {
  const theme = useTheme();

  const clr = theme.palette.result[word.result];

  return (
    <Tooltip
      title={
        word.given_answer && (
          <Typography variant="h4">{word.given_answer}</Typography>
        )
      }
      placement="top-end"
    >
      <Chip
        label={word.word}
        size="medium"
        sx={{ bgcolor: clr, p: "0.5rem", m: "0.5rem" }}
      />
    </Tooltip>
  );
};

export default ResultWord;
