import React from "react";

function CustomIcon({
  iconName,
  stroke = "#000000",
  fill,
  width = "24px",
  height = "24px",
}) {
  let svgIcon = "";
  switch (iconName) {
    case "Home":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.25 12L11.2045 3.04551C11.6438 2.60617 12.3562 2.60617 12.7955 3.04551L21.75 12M4.5 9.75001V19.875C4.5 20.4963 5.00368 21 5.625 21H9.75V16.125C9.75 15.5037 10.2537 15 10.875 15H13.125C13.7463 15 14.25 15.5037 14.25 16.125V21H18.375C18.9963 21 19.5 20.4963 19.5 19.875V9.75001M8.25 21H16.5"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    case "Test":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.5 14.25V11.625C19.5 9.76104 17.989 8.25 16.125 8.25H14.625C14.0037 8.25 13.5 7.74632 13.5 7.125V5.625C13.5 3.76104 11.989 2.25 10.125 2.25H8.25M9 16.5V17.25M12 14.25V17.25M15 12V17.25M10.5 2.25H5.625C5.00368 2.25 4.5 2.75368 4.5 3.375V20.625C4.5 21.2463 5.00368 21.75 5.625 21.75H18.375C18.9963 21.75 19.5 21.2463 19.5 20.625V11.25C19.5 6.27944 15.4706 2.25 10.5 2.25Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    case "Charts":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 13.125C3 12.5037 3.50368 12 4.125 12H6.375C6.99632 12 7.5 12.5037 7.5 13.125V19.875C7.5 20.4963 6.99632 21 6.375 21H4.125C3.50368 21 3 20.4963 3 19.875V13.125Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.75 8.625C9.75 8.00368 10.2537 7.5 10.875 7.5H13.125C13.7463 7.5 14.25 8.00368 14.25 8.625V19.875C14.25 20.4963 13.7463 21 13.125 21H10.875C10.2537 21 9.75 20.4963 9.75 19.875V8.625Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.5 4.125C16.5 3.50368 17.0037 3 17.625 3H19.875C20.4963 3 21 3.50368 21 4.125V19.875C21 20.4963 20.4963 21 19.875 21H17.625C17.0037 21 16.5 20.4963 16.5 19.875V4.125Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    case "Settings":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.59353 3.94013C9.68394 3.39767 10.1533 3.00008 10.7032 3.00008H13.2972C13.8471 3.00008 14.3165 3.39767 14.4069 3.94013L14.6204 5.22118C14.6827 5.59522 14.9327 5.90679 15.2645 6.09044C15.3386 6.13149 15.412 6.17391 15.4844 6.21765C15.8094 6.41392 16.2048 6.47494 16.5603 6.34173L17.7772 5.88585C18.2922 5.69292 18.8712 5.90058 19.1461 6.37685L20.4431 8.62329C20.7181 9.09955 20.6084 9.70481 20.1839 10.0543L19.1795 10.8812C18.887 11.122 18.742 11.4938 18.749 11.8726C18.7498 11.915 18.7502 11.9575 18.7502 12.0001C18.7502 12.0427 18.7498 12.0851 18.749 12.1275C18.742 12.5063 18.887 12.8782 19.1795 13.119L20.1839 13.9458C20.6084 14.2953 20.7181 14.9006 20.4431 15.3769L19.1461 17.6233C18.8712 18.0996 18.2922 18.3072 17.7772 18.1143L16.5603 17.6584C16.2048 17.5252 15.8094 17.5862 15.4844 17.7825C15.412 17.8262 15.3386 17.8687 15.2645 17.9097C14.9327 18.0934 14.6827 18.4049 14.6204 18.779L14.4069 20.06C14.3165 20.6025 13.8471 21.0001 13.2972 21.0001H10.7032C10.1533 21.0001 9.68394 20.6025 9.59353 20.06L9.38002 18.779C9.31768 18.4049 9.06771 18.0934 8.73594 17.9097C8.66176 17.8687 8.58844 17.8262 8.51601 17.7825C8.19098 17.5862 7.79565 17.5252 7.44008 17.6584L6.22322 18.1143C5.70822 18.3072 5.12923 18.0996 4.85426 17.6233L3.55728 15.3769C3.28231 14.9006 3.39196 14.2953 3.81654 13.9458L4.82089 13.119C5.1134 12.8782 5.2584 12.5064 5.25138 12.1275C5.2506 12.0852 5.2502 12.0427 5.2502 12.0001C5.2502 11.9575 5.2506 11.915 5.25138 11.8726C5.2584 11.4938 5.1134 11.122 4.82089 10.8812L3.81654 10.0544C3.39196 9.70482 3.28231 9.09957 3.55728 8.6233L4.85426 6.37686C5.12923 5.9006 5.70822 5.69293 6.22321 5.88587L7.44007 6.34174C7.79563 6.47495 8.19096 6.41393 8.516 6.21766C8.58843 6.17391 8.66176 6.1315 8.73594 6.09044C9.06771 5.90679 9.31768 5.59522 9.38002 5.22118L9.59353 3.94013Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 12C15 13.6569 13.6568 15 12 15C10.3431 15 8.99997 13.6569 8.99997 12C8.99997 10.3431 10.3431 9 12 9C13.6568 9 15 10.3431 15 12Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    case "Search":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 21L15.8033 15.8033M15.8033 15.8033C17.1605 14.4461 18 12.5711 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.5711 18 14.4461 17.1605 15.8033 15.8033Z"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case "Down":
      svgIcon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M19.5 8.75L12 16.25L4.5 8.75"
            stroke={stroke}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    default:
      break;
  }

  return svgIcon;
}

export default CustomIcon;
