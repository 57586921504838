import React from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import { Box } from "@mui/material";

// MUI Icons

// Custom components
import ResultWord from "components/common/ResultWord";

// Custom hooks

// APIs

// Images

// Random Others

const ResultSummary = () => {
  const asmtRes = useSelector((state) => state.global.caAsmtResult);
  let asmtInfo = useSelector((state) => state.global.caAsmtInfo);

  let wordList = asmtRes
    .filter(
      (word) =>
        asmtInfo.result_summary_list.includes(word.result) ||
        asmtInfo.result_summary_list === "all",
    )
    .map((word) => <ResultWord word={word} key={word.word_no} />);

  return (
    <Box width="80%" border="solid 1px #000" m="1rem" borderRadius="0.5rem">
      {wordList}
    </Box>
  );
};

export default ResultSummary;
