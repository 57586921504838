import React from "react";
import useTitle from "../../hooks/useTitle";
import { Link, Box } from "@mui/material";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PLACEHOLDER FILE - CAN BE IGNORED
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const WarpHome = () => {
  useTitle("MultiLit: WARP");
  return (
    <Box
      sx={{
        borderRadius: "1rem",
        bgcolor: "neutral.light",
        p: "0.5rem",
        height: "100vh",
      }}
    >
      warpHome
      <br />
      {/* <Link
				href="./warp/new"
				underline="hover"
				aria-label="Create new WARP Test"
			>
				Create new WARP Test
			</Link>
			<br />
			<Link
				href="./warp/new-incremental"
				underline="hover"
				aria-label="Create new WARP Test - Incrementally growing list"
			>
				Create new WARP Test - Incrementally growing list
			</Link> */}
      <br />
      <Link
        href="./warp/new-hover"
        underline="hover"
        aria-label="Create new WARP Test - Hover for Keys"
      >
        Create new WARP Test - Hover for Keys
      </Link>
      <br />
      <Link
        href="./warp/new-multiClick"
        underline="hover"
        aria-label="Create new WARP Test - Multi Click for Result Change"
      >
        Create new WARP Test - Multi Click for Result Change
      </Link>
      <br />
      <Link
        href="./warp/new-draggable"
        underline="hover"
        aria-label="Create new WARP Test - With Draggable Words"
      >
        Create new WARP Test - With Draggable Words
      </Link>
      <br />
    </Box>
  );
};

export default WarpHome;
