///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PAGE IS TO BE RE-WRITTEN - NO NEED TO REVIEW
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetStudentResultsByBenchmarkQuery } from "../../app/api/reportsApiSlice";
import ResultsByBenchmark from "./ResultsByBenchmark";

const ResultsByBenchmark_frm = () => {
  useTitle("MAP: Benchmark Results");

  const {
    data: rptData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentResultsByBenchmarkQuery("studentResultByBenchmarkList", {
    pollingInterval: 150000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  let content;

  if (isLoading) {
    return <PulseLoader color={"#000"} />;
  } else if (rptData.length === 0) {
    return <p className="errmsg">No results data was found.</p>;
  } else if (isError) {
    return <p className="errmsg">{error}</p>;
  } else if (isSuccess) {
    content = <ResultsByBenchmark data={rptData} />;
  }

  return content;
};

export default ResultsByBenchmark_frm;
