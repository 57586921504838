export const sidebarOptions = [
  {
    key: "Test Areas",
    options: [
      {
        text: "WARN",
        icon: "Test",
      },
      {
        text: "WARP",
        icon: "Test",
      },
      {
        text: "Other",
        icon: "Test",
      },
    ],
  },
  {
    key: "Management",
    options: [
      {
        text: "Students",
        icon: "Test",
      },
      {
        text: "Admin",
        icon: "Charts",
        show: "Admin",
      },
      {
        text: "Reports",
        icon: "Settings",
        show: "Manager",
      },
    ],
  },
];
