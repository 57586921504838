// import React from 'react'

import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  useGetClassByIdQuery,
  useSaveClassMutation,
  useUpdateClassMutation,
} from "app/api/adminApiSlice";
import { useGetConfigForUserQuery } from "app/api/configApiSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import CheckIcon from "@mui/icons-material/Check";

const NewClass = () => {
  const [selectedSchool, setSelectedSchool] = useState("");
  const [createClassName, setCreateClassName] = useState("");
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();

  const { classId } = useParams();
  const {
    data: classData,
    isLoading: classDataLoading,
    isSuccess: classDataSuccess,
    isError: classDataError,
    refetch,
  } = useGetClassByIdQuery(classId, {
    skip: !classId,
  });

  useEffect(() => {
    if (classId) {
      refetch();
    }
  }, [classId, refetch]);

  const [
    addNewClass,
    { isLoading: isLoadingCreateClass, isSuccess: isSuccessCreateClass },
  ] = useSaveClassMutation();

  const [
    updateClass,
    { isLoading: isLoadingUpdateClass, isSuccess: isSuccessUpdateClass },
  ] = useUpdateClassMutation();

  useEffect(() => {
    if (classData && classId) {
      setCreateClassName(classData.class_name);
      setSelectedSchool(classData.school_id);
    }
  }, [classData]);

  const {
    data: config,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetConfigForUserQuery("configList", {
    refetchOnFocus: false,
  });

  const extractConfigOptions = (key) =>
    config?.find((item) => item.config === key)?.options || [];
  const schoolOptions = extractConfigOptions("school");

  const handleSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
  };

  const validateForm = () => {
    const tempErrors = {};

    if (createClassName.trim() === "")
      tempErrors.createClassName = "Class name is required";
    if (selectedSchool === "")
      tempErrors.selectedSchool = "School name is required";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handeSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const payload = { school_id: selectedSchool, class_name: createClassName };
    if (classId) {
      payload.class_id = classId;
      updateClass(payload);
    } else {
      addNewClass(payload);
    }
  };

  useEffect(() => {
    if (isSuccessCreateClass || isSuccessUpdateClass) {
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/home/admin");
      }, 500);
    }
  }, [isSuccessCreateClass, isSuccessUpdateClass]);

  let content;
  if (isLoading || classDataLoading) content = <PulseLoader color="#000" />;
  if (isError)
    content = content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <>
        <h2>{classId ? "Update class" : "Create class"}</h2>
        <Grid container spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            autoHideDuration={5000}
          >
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              {classId
                ? "Class updated successfully."
                : "Class created successfully."}
            </Alert>
          </Snackbar>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth error={!!errors.createClassName}>
              <TextField
                value={createClassName}
                onChange={(e) => setCreateClassName(e.target.value)}
                id="Class name"
                label="Class name"
              />
              <FormHelperText>{errors.createClassName}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth error={!!errors.selectedSchool}>
              <InputLabel id="school">School</InputLabel>
              <Select
                labelId="school"
                id="school-id"
                label="School"
                value={selectedSchool}
                onChange={handleSchoolChange}
              >
                {schoolOptions.map((option, index) => (
                  <MenuItem key={index} value={option.school_id}>
                    {option.school_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.selectedSchool}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button
              onClick={handeSubmit}
              variant="contained"
              disabled={isLoadingCreateClass || isLoadingUpdateClass}
            >
              {isLoadingCreateClass || isLoadingUpdateClass ? (
                <PulseLoader color="#fff" />
              ) : classId ? (
                "Update class"
              ) : (
                "Create class"
              )}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return content;
};

export default NewClass;
