///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PAGE IS TO BE RE-WRITTEN - NO NEED TO REVIEW
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useState } from "react";

import { useDispatch } from "react-redux";

// State
import {
  setConductAssessment_ShowAddInfo,
  setReportDrilldown_StudentSelected,
} from "../../app/state";

// MUI Components
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// MUI Icons

// Custom components
import ClassBandMatrix from "./ClassBandMatrix";
import StudentsListFiltered_BandMatrix from "features/students/StudentsListFiltered_BandMatrix";

// Custom hooks
import useTitle from "../../hooks/useTitle";

// APIs
import { useGetStudentsSummaryQuery } from "../../app/api/studentsApiSlice";
import { useGetProgramsQuery } from "../../app/api/programsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

const ClassBandMatrix_frm = () => {
  useTitle("MAP: Band Matrix");

  const theme = useTheme();
  const dispatch = useDispatch();

  const [yearA, setYearA] = useState(new Date().getFullYear());
  const [termA, setTermA] = useState(1);
  const [termB, setTermB] = useState(2);
  const [groupA, setGroupA] = useState("Initial Assessment");
  const [groupB, setGroupB] = useState("Post-Test");
  const [cls, setCls] = useState();
  const [clsArr, setClsArr] = useState([]);
  const [program, setProgram] = useState("WARN");
  const [groups, setGroups] = useState();
  const [theBand, setTheBand] = useState();
  const [progArr, setProgArr] = useState([]);

  const {
    data: students,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentsSummaryQuery("studentsList", {
    pollingInterval: 150000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: progs,
    isLoading: progLoading,
    isSuccess: progSuccess,
    isError: progIsError,
    error: progError,
  } = useGetProgramsQuery("programsList");

  const setBand = (band) => {
    setTheBand(band);
    dispatch(setConductAssessment_ShowAddInfo(false));
    dispatch(setReportDrilldown_StudentSelected(false));
  };

  const handleSetProgram = (prog) => {
    setGroupA("Initial Assessment");
    setGroupB("Post-Test");
    setTheBand(null);
    setProgram(prog);
  };
  const handleSetCls = (cls) => {
    setTheBand(null);
    setCls(cls);
  };
  const handleSetYearA = (year) => {
    setTheBand(null);
    setYearA(year);
  };
  const handleSetTermA = (term) => {
    setTheBand(null);
    setTermA(term);
  };
  const handleSetTermB = (term) => {
    setTheBand(null);
    setTermB(term);
  };
  const handleSetGroupA = (group) => {
    setTheBand(null);
    setGroupA(group);
  };
  const handleSetGroupB = (group) => {
    setTheBand(null);
    setGroupB(group);
  };

  let content;
  if (isLoading || progLoading) content = <PulseLoader color={"#000"} />;

  if (isError || progIsError) {
    content = (
      <>
        <p className="errmsg">{error?.data?.message}</p>
        <p className="errmsg">{progError?.data?.message}</p>
      </>
    );
  }
  if (isSuccess && progSuccess) {
    if (progArr.length === 0) {
      const { ids: pIds, entities: pEntities } = progs;
      //const p = pEntities.filter((e) => e.program_name === program)
      let pp = [];
      pIds.map((pId) => pp.push(pEntities[pId]));
      setProgArr([...pp]);
    }
    let p = [];
    p = progArr.slice().filter((e) => e.program_name === program);

    const pArr = p[0]?.asmt_groups
      .slice()
      .sort((a, b) => a.group_seq_no - b.group_seq_no);

    if (clsArr.length === 0) {
      const { ids, entities } = students;
      let arrStudents = [];
      ids.map((student_Id) => arrStudents.push(entities[student_Id]));

      const classes = [...new Set(arrStudents.map((item) => item.class_name))];

      if (classes.length > 0 && !cls) {
        setCls(classes[0]);
      }
      setClsArr([...classes]);
    }
    content = (
      <ErrorBoundary customMessage="Something went wrong in Class Band Matrix Page">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="0.5rem"
          width="100%"
        >
          <Typography variant="h1">Class Band Matrix</Typography>
          <Stack
            direction="row"
            padding="0.5rem"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            width="60%"
            sx={{
              backgroundColor: theme.palette.neutral.white,
              borderRadius: "0.5rem",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-class-label">Select Class</InputLabel>
              <Select
                labelId="select-class-label"
                id="select-class"
                width="100%"
                value={cls}
                onChange={(e) => handleSetCls(e.target.value)}
              >
                {clsArr.map((obj, key) => {
                  return (
                    <MenuItem key={key} value={obj}>
                      {obj}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-program-label">Select Program</InputLabel>
              <Select
                labelId="select-program-label"
                id="select-program"
                width="100%"
                value={program}
                onChange={(e) => handleSetProgram(e.target.value)}
              >
                {progArr.map((obj, key) => {
                  return (
                    <MenuItem key={key} value={obj.program_name}>
                      {obj.program_name}
                    </MenuItem>
                  );
                })}
                {/* <MenuItem key={0} value={"WARN"}>
								WARN
							</MenuItem>
							<MenuItem key={1} value={"WARL"}>
								WARL
							</MenuItem>
							<MenuItem key={2} value={"WARP"}>
								WARP
							</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-start-year-label">Select Year</InputLabel>
              <Select
                labelId="select-start-year-label"
                id="select-start-year"
                value={yearA}
                onChange={(e) => handleSetYearA(e.target.value)}
              >
                <MenuItem key={-1} value={yearA - 1}>
                  {yearA - 1}
                </MenuItem>
                <MenuItem key={1} value={yearA}>
                  {yearA}
                </MenuItem>
                <MenuItem key={2} value={yearA + 1}>
                  {yearA + 1}
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              minWidth: "80%",
              minHeight: "50vh",
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: theme.palette.neutral[50],
            }}
          >
            <Stack direction="column" width="100%">
              {/* End filters */}
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={3}></Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    flexGrow: 1,
                  }}
                  container
                >
                  {/* End */}
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    <FormControl
                      sx={{
                        marginLeft: 1,
                        minWidth: 120,
                      }}
                    >
                      <InputLabel id="select-start-term-label">
                        End Term
                      </InputLabel>
                      <Select
                        labelId="select-end-term-label"
                        id="select-end-term"
                        value={termB}
                        onChange={(e) => handleSetTermB(e.target.value)}
                      >
                        <MenuItem key={1} value={1}>
                          Term 1
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Term 2
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Term 3
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Term 4
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{
                        marginLeft: 1,
                        minWidth: 120,
                      }}
                    >
                      <InputLabel id="select-end-group-label">
                        End Test
                      </InputLabel>
                      <Select
                        labelId="select-end-group-label"
                        id="select-end-group"
                        value={groupB}
                        onChange={(e) => handleSetGroupB(e.target.value)}
                      >
                        {pArr?.map((obj, key) => {
                          return (
                            <MenuItem key={key} value={obj.group_short_name}>
                              {obj.group_short_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              {/* Start filters and grid */}
              <Grid container alignItems="center" sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    flexGrow: 1,
                    minHeight: "30vh",
                    alignItems: "center",
                  }}
                  container
                >
                  {/* Start */}
                  <Stack
                    direction="column"
                    spacing={2}
                    alignItems="flex-end"
                    sx={{
                      height: "100%",
                      width: "100%",
                      paddingRight: "0.5rem",
                    }}
                  >
                    <FormControl
                      sx={{
                        marginLeft: 1,
                        minWidth: "70%",
                      }}
                    >
                      <InputLabel id="select-start-term-label">
                        Start Term
                      </InputLabel>
                      <Select
                        labelId="select-start-term-label"
                        id="select-start-term"
                        value={termA}
                        onChange={(e) => handleSetTermA(e.target.value)}
                      >
                        <MenuItem key={1} value={1}>
                          Term 1
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Term 2
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Term 3
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Term 4
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{
                        marginLeft: 1,
                        minWidth: "70%",
                      }}
                    >
                      <InputLabel id="select-start-group-label">
                        Start Test
                      </InputLabel>
                      <Select
                        labelId="select-start-group-label"
                        id="select-start-group"
                        value={groupA}
                        onChange={(e) => handleSetGroupA(e.target.value)}
                      >
                        {pArr?.map((obj, key) => {
                          return (
                            <MenuItem key={key} value={obj.group_short_name}>
                              {obj.group_short_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={9} sx={{ flexGrow: 1 }}>
                  <ClassBandMatrix
                    year={yearA}
                    className={cls}
                    program={program}
                    termA={termA}
                    termB={termB}
                    groupA={groupA}
                    groupB={groupB}
                    band={theBand}
                    setBand={setBand}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          {theBand && (
            <StudentsListFiltered_BandMatrix
              className={cls}
              program={program}
              year={yearA}
              termA={termA}
              termB={termB}
              groupA={groupA}
              groupB={groupB}
              band={theBand}
            />
          )}
        </Box>
      </ErrorBoundary>
    );
  }

  return content;
};

export default ClassBandMatrix_frm;
