import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const reportsAdapter = createEntityAdapter({});

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudentResultsByBenchmark: builder.query({
      query: () => ({
        url: "/reports/student-results-by-benchmark",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [{ type: "WAR-Results", id: "LIST" }];
        } else return [{ type: "WAR-Results", id: "LIST" }];
      },
    }),
    getClassBandMatrix: builder.query({
      query: (qryVars) => ({
        url: "/reports/class-band-matrix",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        params: {
          ...qryVars,
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [{ type: "WAR-Results", id: "LIST" }];
        } else return [{ type: "WAR-Results", id: "LIST" }];
      },
    }),
    getClassBandMatrixStudents: builder.query({
      query: (qryVars) => ({
        url: "/reports/class-band-matrix-students",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        params: {
          ...qryVars,
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [{ type: "WAR-Results", id: "LIST" }];
        } else return [{ type: "WAR-Results", id: "LIST" }];
      },
    }),
    getStudentsByBenchmark: builder.query({
      query: (qryVars) => ({
        url: "/reports/students-by-benchmark",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        params: {
          ...qryVars,
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [{ type: "WAR-Results", id: "LIST" }];
        } else return [{ type: "WAR-Results", id: "LIST" }];
      },
    }),
    getInitiaLitSnapshotData: builder.query({
      query: () => ({
        url: "/reports/initialit-snapshot",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getInitiaLitTabularData: builder.query({
      query: () => ({
        url: "/reports/initialit-tabular",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useGetStudentResultsByBenchmarkQuery,
  useGetClassBandMatrixQuery,
  useGetClassBandMatrixStudentsQuery,
  useGetStudentsByBenchmarkQuery,
  useGetInitiaLitSnapshotDataQuery,
  useGetInitiaLitTabularDataQuery,
} = reportsApiSlice;
