import React from "react";
// import { useRef } from 'react'

// State

// MUI Components
import { Typography, useTheme } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

// MUI Icons

// Custom components
import FlexBetween from "components/utilities/FlexBetween";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";

// Custom hooks

// APIs

// Images

// Random Others
import { imageFormatter } from "utils/index";

const AsmtProgressRibbon = ({ questions, activeQuestion, funcs }) => {
  const theme = useTheme();
  // const containerRef = useRef(null)

  const lastQ = questions.length - 1;

  if (!questions[activeQuestion].show_ribbon) {
    return <></>;
  }

  let cellPrev;
  if (activeQuestion === 0) {
    cellPrev = <VertCenter sx={{ height: "100%" }}>Start</VertCenter>;
  } else if (questions[activeQuestion - 1].display_type === "image") {
    cellPrev = imageFormatter(
      questions[activeQuestion - 1].image_file,
      "mapImages/",
      questions[activeQuestion - 1].display_type,
      "auto",
      "10vh",
      "center",
      "scale-down",
    );
  } else if (questions[activeQuestion - 1].display_type === "word") {
    cellPrev = (
      <VertCenter sx={{ height: "10vh", width: "auto" }}>
        <Typography
          sx={{ fontSize: 40, fontWeight: "bold" }}
          color={theme.palette.primary.main}
        >
          {questions[activeQuestion - 1].word}
        </Typography>
      </VertCenter>
    );
  } else {
    cellPrev = questions[activeQuestion - 1].word;
  }

  let cellCurr;
  if (questions[activeQuestion].display_type === "image") {
    cellCurr = imageFormatter(
      questions[activeQuestion].image_file,
      "mapImages/",
      questions[activeQuestion].display_type,
      "auto",
      "10vh",
      "center",
      "scale-down",
    );
  } else if (questions[activeQuestion].display_type === "word") {
    cellCurr = (
      <VertCenter sx={{ height: "10vh", width: "auto" }}>
        <Typography
          sx={{ fontSize: 40, fontWeight: "bold" }}
          color={theme.palette.primary.main}
        >
          {questions[activeQuestion].word}
        </Typography>
      </VertCenter>
    );
  } else {
    cellCurr = questions[activeQuestion].word;
  }

  let cellNext;
  if (activeQuestion === lastQ) {
    cellNext = <VertCenter sx={{ height: "100%" }}>End of Test</VertCenter>;
  } else if (questions[activeQuestion + 1].display_type === "image") {
    cellNext = imageFormatter(
      questions[activeQuestion + 1].image_file,
      "mapImages/",
      questions[activeQuestion + 1].display_type,
      "auto",
      "10vh",
      "center",
      "scale-down",
    );
  } else if (questions[activeQuestion + 1].display_type === "word") {
    cellNext = (
      <VertCenter sx={{ height: "10vh", width: "auto" }}>
        <Typography
          sx={{ fontSize: 40, fontWeight: "bold" }}
          color={theme.palette.primary.main}
        >
          {questions[activeQuestion + 1].word}
        </Typography>
      </VertCenter>
    );
  } else {
    cellNext = questions[activeQuestion + 1].word;
  }

  return (
    <HorizontalCenter id="progress-ribbon" sx={{ p: "1rem" }}>
      <MobileStepper
        variant="dots"
        steps={questions.length}
        position="static"
        activeStep={activeQuestion}
        sx={{ flexGrow: 1 }}
      />
      <FlexBetween
        shadow="8"
        sx={{
          p: "1rem",
          width: "60%",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "1rem",
        }}
      >
        <HorizontalCenter
          sx={{
            width: "20vh",
            backgroundColor: theme.palette.neutral.white,
            borderRadius: "1rem",
            height: "100%",
            opacity: "0.5",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => funcs["handleNavBack"]()}
        >
          {cellPrev}
        </HorizontalCenter>
        <HorizontalCenter
          sx={{
            width: "20vh",
            backgroundColor: theme.palette.neutral.white,
            borderRadius: "1rem",
            border: "5px solid",
            borderColor: theme.palette.secondary.main,
          }}
        >
          {cellCurr}
        </HorizontalCenter>
        <HorizontalCenter
          sx={{
            width: "20vh",
            backgroundColor: theme.palette.neutral.white,
            borderRadius: "1rem",
            height: "100%",
            opacity: "0.5",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => funcs["moveToNext"]()}
        >
          {cellNext}
        </HorizontalCenter>
      </FlexBetween>
    </HorizontalCenter>
  );
};

export default AsmtProgressRibbon;
