import React from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import {
  TextField,
  Fab,
  Divider,
  styled,
  Box,
  Typography,
  useTheme,
} from "@mui/material";

// MUI Icons
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined"; // Switched
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined"; // Skipped
import SettingsEthernetOutlinedIcon from "@mui/icons-material/SettingsEthernetOutlined"; // Skipped Row
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; // Insert Word
// import JoinInner from '@mui/icons-material/JoinInner' // Concatenated

// Custom components
import StyledButton from "components/common/StyledButton";
import RawHTML from "components/common/RawHTML";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";
import FlexBetween from "components/utilities/FlexBetween";
import { buttonStyles } from "styles";

// Custom hooks

// APIs

// Images

// Random Others

const AsmtMatrix = ({ questions, funcs }) => {
  const theme = useTheme();

  const existingResult = useSelector((state) => state.global.caExistingResult);
  const caManage = useSelector((state) => state.global.caManage);
  const edit = caManage.asmtEdit;
  const asmtInfo = useSelector((state) => state.global.caAsmtInfo);
  const timerOn = useSelector((state) => state.global.caTimerOn);
  const finalEdit = useSelector((state) => state.global.caFinalEdit);

  let grp = 1;

  let qs = [...questions];
  const numRows = qs.sort((a, b) => parseInt(b.row) - parseInt(a.row))[0].row;
  qs.sort((a, b) => a.row - b.row || a.word_no - b.word_no);
  const inst = qs[0].instruction;

  const Row = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    flexGrow: 2,
    flexWrap: asmtInfo.wrap_words ? "wrap" : "no-wrap",
    width: "100%",
    alignItems: "center",
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.neutral.light,
  }));

  const colours = {
    correct: theme.palette.result.correct,
    incorrect: theme.palette.result.incorrect,
    selfCorrect: theme.palette.result.selfCorrect,
    skippedRow: theme.palette.result.skippedRow,
    skipped: theme.palette.result.skipped,
    insert: theme.palette.result.insert,
  };

  let score = { correct: 0, attempted: 0, errors: 0, attempt_minus_error: 0 };
  let r = 0;
  qs.sort((a, b) => a.word_no - b.word_no).forEach((a) => {
    if (a.result !== null && a.result !== "skippedRow") {
      score.attempted += 1;
    }
    if (a.result === "correct" || a.result === "selfCorrect") {
      score.correct += 1;
    }
    if (
      a.result !== null &&
      a.result !== "correct" &&
      a.result !== "selfCorrect" &&
      a.result !== "skippedRow"
    ) {
      score.errors += 1;
    }
    if (a.result === "skippedRow" && a.row !== r) {
      score.errors += 1;
      r = a.row;
    }
    if (a.concat) {
      score.errors += 1;
    }
    if (a.insert) {
      score.errors += 1;
    }
    if (a.switched) {
      score.errors += 1;
    }
  });

  score.attempt_minus_error = score.attempted - score.errors;

  const handleTextInput = (e, word_no) => {
    let s = {};
    s.given_answer = e.target.value;
    funcs["updateResultSuppField"](s, word_no);
  };

  // Drag events
  const handleOnDrag = (e, word) => {
    e.dataTransfer.setData("WordNo", word);
  };

  const handleOnDrop = (e, wordNo) => {
    const newWordNo = e.dataTransfer.getData("WordNo");
    if (Math.abs(newWordNo - wordNo) === 1) {
      let s = {};
      s.switched = true;
      funcs["updateResultSuppField"](s, Math.min(newWordNo, wordNo));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const rows = [];
  const wordWidth = asmtInfo.asmt_type === "Word List" ? "6rem" : "auto";
  const rowAlign = asmtInfo.asmt_type === "Passage" ? "flex-start" : "center";

  for (let i = 1; i <= numRows; i++) {
    let rowObj = qs.find((a) => a.row === i);
    const disabled =
      (existingResult && !edit) ||
      (asmtInfo.timer && !finalEdit && !timerOn && !existingResult);
    rows.push({
      group: rowObj.group,
      row: (
        <>
          <Box
            key={i + 500}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Row>
              {/* row number, if configured to have one */}
              {asmtInfo.show_row_number && (
                <Box
                  id={`row-${i}`}
                  key={`row_${i}`}
                  sx={{ width: "2rem", textAlign: "center" }}
                >
                  <Typography variant="h4">{i}.</Typography>
                </Box>
              )}
              {/* skipped row button */}
              {rowObj.opt_skip_row && (
                <Fab
                  key={"row" + i}
                  disabled={disabled}
                  onClick={() => funcs["handleSkippedRow"](i, false)}
                  variant="extended"
                  size="small"
                  sx={{
                    bgcolor: theme.palette.result.skippedRow,
                    marginRight: 1,
                  }}
                >
                  <SettingsEthernetOutlinedIcon /> Skipped Row
                </Fab>
              )}
              {qs
                .sort((a, b) => a.word_no - b.word_no)
                .filter((e) => e.row === i)
                .map(
                  ({
                    word,
                    word_no,
                    result,
                    action_buttons,
                    textbox,
                    textbox_disable_answers,
                    given_answer,
                    opt_skip,
                    opt_switch,
                    opt_insert,
                    switched,
                    insert,
                  }) => {
                    let p = {};
                    p.width = wordWidth;
                    p.minWidth = textbox ? "5rem" : "3rem";
                    p.textTransform = "none";
                    p.fontSize = "1.25rem";
                    p.padding = "0.75rem";
                    if (result) {
                      p.backgroundColor = colours[result];
                    }
                    let icnClr = {};
                    let icnSize = "medium";
                    if (insert) {
                      icnClr.color = colours["insert"];
                      icnSize = "large";
                    }

                    return (
                      <FlexBetween>
                        <HorizontalCenter p="0.1rem">
                          {opt_skip && (
                            <RedoOutlinedIcon
                              onClick={(e) => {
                                if (disabled) return;
                                let s = {};
                                s.result = "skipped";
                                funcs["updateResultSuppField"](s, word_no);
                              }}
                            />
                          )}
                          <HorizontalCenter
                            draggable={opt_switch && !disabled}
                            onDragStart={(e) => handleOnDrag(e, word_no)}
                            onDrop={(e) => handleOnDrop(e, word_no)}
                            onDragOver={handleDragOver}
                            sx={{
                              backgroundColor: colours[result],
                              borderRadius: "1rem",
                              m: "0 0.25rem",
                            }}
                          >
                            <StyledButton
                              key={word}
                              children={word}
                              disabled={disabled}
                              style={buttonStyles.toggleResult}
                              removeHover={true}
                              sxProps={p}
                              onMounsseDown={(e) => e.preventDefault()}
                              onMouseUp={() =>
                                funcs["handleToggleResult"](
                                  result,
                                  word_no,
                                  action_buttons,
                                )
                              }
                            />

                            {textbox &&
                              !(
                                textbox_disable_answers.includes(result) ||
                                result === null
                              ) && (
                                <TextField
                                  label="Given Answer"
                                  variant="outlined"
                                  sx={{
                                    m: "0.25rem",
                                    width: "5rem",
                                    minWidth: "7rem",
                                  }}
                                  defaultValue={given_answer}
                                  disabled={
                                    textbox_disable_answers.includes(result) ||
                                    result === null ||
                                    (existingResult && !edit)
                                  }
                                  onBlur={(e) => handleTextInput(e, word_no)}
                                />
                              )}
                          </HorizontalCenter>
                        </HorizontalCenter>
                        {(opt_insert || switched) && (
                          <HorizontalCenter>
                            {opt_insert && (
                              <KeyboardArrowUpIcon
                                onClick={(e) => {
                                  if (disabled) return;
                                  let s = {};
                                  s.insert = !insert;
                                  funcs["updateResultSuppField"](s, word_no);
                                }}
                                fontSize={icnSize}
                                sx={icnClr}
                              />
                            )}
                            {switched && (
                              <SwapHorizontalCircleOutlinedIcon
                                onClick={(e) => {
                                  if (disabled) return;
                                  let s = {};
                                  s.switched = false;
                                  funcs["updateResultSuppField"](s, word_no);
                                }}
                                sx={{
                                  color: theme.palette.result.switched,
                                }}
                              />
                            )}
                          </HorizontalCenter>
                        )}
                        {!opt_insert && !switched && (
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            width="3.5rem"
                          />
                        )}
                      </FlexBetween>
                    );
                  },
                )}
            </Row>
          </Box>
        </>
      ),
    });
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <FlexBetween p="2rem" width="100%">
        <RawHTML htmlString={inst} />
        <VertCenter
          height="100%"
          p="1rem"
          m="1rem"
          sx={{
            width: "35%",
            backgroundColor: theme.palette.primary[300],
            border: "3px solid",
            borderColor: theme.palette.primary.main,
            borderRadius: "1rem",
          }}
        >
          <Typography variant="h2">
            Score: {score[asmtInfo.scoring_method]}/{asmtInfo.max_score}
          </Typography>
        </VertCenter>
      </FlexBetween>
      <HorizontalCenter
        id="word-container"
        width="100%"
        height="100%"
        align={rowAlign}
        sx={{ p: "2rem" }}
      >
        {rows.map(({ group, row }, i) => {
          let divider = null;
          if (group !== grp) {
            divider = (
              <Divider
                flexItem
                key={i + 1000}
                aria-hidden="true"
                sx={{ m: "2rem 0" }}
              />
            );
          }
          grp = group;
          return (
            <>
              {divider}
              {row}
            </>
          );
        })}
      </HorizontalCenter>
      <FlexBetween width="100%" p="2rem">
        <StyledButton
          children={"Back"}
          style={buttonStyles.nav}
          sxProps={{ width: "20%" }}
          onClick={() => funcs["handleNavBack"]()}
        />
        <StyledButton
          children={"Review Results"}
          style={buttonStyles.correct}
          sxProps={{ width: "30%" }}
          onClick={() => funcs["moveToNext"]()}
        />
      </FlexBetween>
    </Box>
  );
};

export default AsmtMatrix;
