import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { useTheme } from "@mui/material";
import { createTrend } from "../../../utils/getTrendLine.ts";

const WARProgressChart = ({ data }) => {
  const theme = useTheme();
  const trend = createTrend(data, "index", "Result", true);

  if (data[0]) {
    data[0].Trend = trend.yStart;
  }
  if (data[6]) {
    data[6].Trend = trend.calcY(6);
  }
  return (
    // <ResponsiveContainer width="100%" height="100%">
    <LineChart
      width={800}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        strokeDasharray="3 3"
        dataKey="Pct25"
        stroke={theme.palette.benchmark.low}
        fill={theme.palette.benchmark.low}
        activeDot={{ r: 8 }}
      />
      <Line
        type="monotone"
        strokeDasharray="3 3"
        dataKey="Pct40"
        stroke={theme.palette.benchmark.mid}
        fill={theme.palette.benchmark.mid}
      />
      <Line
        type="monotone"
        connectNulls
        dataKey="Result"
        strokeWidth={2}
        stroke={theme.palette.benchmark.high}
        fill={theme.palette.benchmark.high}
      />
      <Line
        type="monotone"
        connectNulls
        dataKey="Trend"
        strokeWidth={2}
        strokeDasharray="3 3"
        stroke={theme.palette.primary.main}
        fill={theme.palette.primary.main}
      />
    </LineChart>
    // </ResponsiveContainer>
  );
};
export default WARProgressChart;
