import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

const handleQueryStarted = async (
  queryFulfilled,
  dispatch,
  successCallback = () => {},
  errorCallback = (err) => console.log(err),
) => {
  try {
    const { data } = await queryFulfilled;
    successCallback(data);
  } catch (err) {
    errorCallback(err);
  }
};

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        handleQueryStarted(queryFulfilled, dispatch, () => {
          dispatch(logOut());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        });
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        handleQueryStarted(queryFulfilled, dispatch, (data) => {
          const { accessToken } = data;
          dispatch(setCredentials({ accessToken }));
        });
      },
    }),
  }),
});

export const { useLoginMutation, useSendLogoutMutation, useRefreshMutation } =
  authApiSlice;
