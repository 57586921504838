import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// State
import { setCaGroupInstComp, setCaHiddenGroupInst } from "../../app/state";

// MUI Components
import { useTheme, Button, Typography } from "@mui/material";

// MUI Icons

// Custom components
import RawHTML from "components/common/RawHTML";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";

// Custom hooks

// APIs

// Images

// Random Others

const DisplayGroupInstruction = ({ headerName, instType, inst }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const hiddenInst = useSelector((state) => state.global.caHiddenGroupInst);

  const filteredInst = inst.filter(
    (e) => !hiddenInst.includes(e.asmt_group_instruction_id),
  );
  var instCnt = filteredInst.length;

  const [i, setI] = useState(0);

  if (instCnt === 0 || instCnt < i) {
    dispatch(setCaGroupInstComp(true));
  }

  const handleNextClick = (moveNext) => {
    if (i + 1 >= instCnt) {
      dispatch(setCaGroupInstComp(true));
    } else if (moveNext) {
      setI(i + 1);
    }
  };

  const hideThisInst = (id) => {
    let h = [...hiddenInst];
    h.push(id);
    dispatch(setCaHiddenGroupInst([...h]));
    handleNextClick(false);
  };

  const handlePrevClick = () => {
    setI(i - 1);
  };

  let instSeq;

  instSeq = filteredInst.sort(
    (a, b) => parseInt(a.instruction_seq_no) - parseInt(b.instruction_seq_no),
  );

  let content = (
    <VertCenter id="container" sx={{ width: "100%", p: "2rem" }}>
      <HorizontalCenter id="prev-button" sx={{ width: "15%" }}>
        <Button
          variant="contained"
          disabled={i === 0}
          onClick={() => handlePrevClick()}
        >
          Previous
        </Button>
      </HorizontalCenter>
      <HorizontalCenter
        sx={{
          width: "70%",
          height: "auto",
          p: "2rem",
          border: "2px solid",
          borderColor: theme.palette.primary.main,
          borderRadius: "2rem",
          backgroundColor: theme.palette.primary[200],
        }}
      >
        <Typography variant="h2">Instruction for {headerName}</Typography>
        <RawHTML htmlString={instSeq[i].instruction} />
        {instSeq[i].can_be_skipped === true && (
          <Button
            sx={{ m: "1rem 0 0 0" }}
            variant="contained"
            onClick={() => hideThisInst(instSeq[i].asmt_group_instruction_id)}
          >
            Hide for This Session
          </Button>
        )}
      </HorizontalCenter>
      <HorizontalCenter id="next-button" sx={{ width: "15%" }}>
        <Button variant="contained" onClick={() => handleNextClick(true)}>
          Next
        </Button>
      </HorizontalCenter>
    </VertCenter>
  );

  return content;
};

export default DisplayGroupInstruction;
