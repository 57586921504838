import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const scoringAdapter = createEntityAdapter({
  selectId: (e) => e.score_id,
});

const initialState = scoringAdapter.getInitialState();

export const scoringApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScoringDetails: builder.query({
      query: () => ({
        url: "/assessmentResults/scoring",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const { useGetScoringDetailsQuery } = scoringApiSlice;
