import React from "react";

// State

// MUI Components
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// MUI Icons

// Custom components

// Custom hooks

// APIs

// Images

// Random Others

const DisplayMessage = ({ msgOpen, msgClose, title, content }) => {
  return (
    <>
      <Dialog open={msgOpen} onClose={msgClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5">{content}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={msgClose}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisplayMessage;
