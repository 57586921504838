import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// State

// MUI Components
import { Box, Button, useTheme } from "@mui/material";

// MUI Icons

// Custom components
import FlexBetween from "components/utilities/FlexBetween";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";

// Chart Components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  SubTitle,
} from "chart.js";

// Custom hooks

// APIs

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  SubTitle,
);

const InitiaLitSnapshotChart = ({
  chartData,
  width = 500,
  height = 400,
  yAxisMax,
}) => {
  const theme = useTheme();

  const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
      sum += tooltipItem.parsed.y;
    });
    return "Total: " + sum;
  };

  const data = {
    labels: ["Low", "Mid", "High"],
    datasets: chartData.map((item) => {
      return {
        label: item.label,
        data: item.data,
        backgroundColor: [
          theme.palette.benchmark.lowFill,
          theme.palette.benchmark.midFill,
          theme.palette.benchmark.highFill,
        ],
        borderWidth: 2,
        borderRadius: 15,
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: true,

    scales: {
      x: {
        stacked: true,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Count of Students",
          // color: '#191',
          font: {
            family: "Muli",
            size: 20,
            style: "normal",
            lineHeight: 1.2,
          },
          padding: { top: 30, left: 0, right: 0, bottom: 0 },
        },
        stacked: true,
        ticks: {
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
        max: yAxisMax,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      title: {
        display: true,
        text: "Benchmark of Students",
        font: {
          family: "Muli",
          size: 30,
          style: "normal",
          lineHeight: 1.2,
        },
      },
      subtitle: {
        display: true,
        text: "From Most Recent Screener or Cumulative Review",
        font: {
          family: "Muli",
          size: 20,
          style: "normal",
          lineHeight: 1.2,
        },
      },
      tooltip: {
        callbacks: {
          footer: footer,
        },
      },
    },
  };

  let content;

  content = (
    <Box sx={{ p: "1rem", width: { width }, height: { height } }}>
      <Bar data={data} options={options} />
    </Box>
  );

  return content;
};

export default InitiaLitSnapshotChart;
