import { store } from "../../app/store";
import { reportsApiSlice } from "../../app/api/reportsApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const PrefetchReports = () => {
  useEffect(() => {
    store.dispatch(
      reportsApiSlice.util.prefetch(
        "getStudentResultsByBenchmark",
        "studentResultByBenchmarkList",
        {
          force: true,
        },
      ),
    );
    store.dispatch(
      reportsApiSlice.util.prefetch(
        "getClassBandMatrix",
        "classBandMatrixList",
        {
          force: true,
        },
      ),
    );
  }, []);

  return <Outlet />;
};
export default PrefetchReports;
