import React from "react";
import useTitle from "../../hooks/useTitle";
import { Box, Button } from "@mui/material";
import BannerHeader from "../../components/common/BannerHeader";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PLACEHOLDER FILE - CAN BE IGNORED
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const WarnHome = () => {
  useTitle("MultiLit: WARN");
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        borderRadius: "1rem",
        bgcolor: "neutral.light",
        p: "0.5rem",
        height: "100vh",
      }}
    >
      <BannerHeader title="WARN - Home Page" />
      <Box
        id="user-summary"
        width="100%"
        height="15vh"
        sx={{ border: "1px solid", p: "0.5rem" }}
      >
        User Summary
      </Box>
      <Box id="left-column" display="flex" flexDirection="row">
        <Box
          width="50%"
          height="60vh"
          sx={{ border: "1px solid", p: "0.5rem" }}
        >
          Student List
        </Box>
        <Box
          id="right-column"
          display="flex"
          flexDirection="column"
          width="50%"
        >
          <Box
            width="100%"
            height="30vh"
            sx={{ border: "1px solid", p: "0.5rem" }}
          >
            Assessments
          </Box>
          <Box
            width="100%"
            height="30vh"
            sx={{ border: "1px solid", p: "0.5rem" }}
          >
            <Button
              variant="contained"
              startIcon={<KeyboardArrowRightIcon />}
              onClick={() => navigate(`/home/reports/results-by-benchmark`)}
              sx={{ p: "0.rem" }}
            >
              Results By Benchmark
            </Button>
            <Button
              variant="contained"
              startIcon={<KeyboardArrowRightIcon />}
              onClick={() => navigate(`/home/reports/band-matrix/warn`)}
              sx={{ p: "0.rem" }}
            >
              Band Matrix
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WarnHome;
