import React from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import { Box, Typography, useTheme, TextField } from "@mui/material";

// MUI Icons
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Custom components
import StyledButton from "components/common/StyledButton";
import RawHTML from "components/common/RawHTML";
import VertCenter from "components/utilities/VertCenter";
import FlexBetween from "components/utilities/FlexBetween";

// Custom hooks

// APIs

// Images

// Random Others
import { imageFormatter } from "utils/index";
import { buttonStyles } from "styles";

const AsmtQuestion = ({ testItem, funcs }) => {
  const theme = useTheme();

  const existingResult = useSelector((state) => state.global.caExistingResult);
  const caManage = useSelector((state) => state.global.caManage);
  const edit = caManage.asmtEdit;

  let stimuli;
  if (testItem.display_type === "image") {
    stimuli = imageFormatter(
      testItem.image_file,
      "mapImages/",
      testItem.display_type,
      "auto",
      "25vh",
      "center",
      "scale-down",
    );
  } else if (testItem.display_type === "word") {
    stimuli = (
      <VertCenter id="stimuli" sx={{ height: "auto", width: "auto" }}>
        <Typography
          sx={{ fontSize: 80, fontWeight: "bold" }}
          color={theme.palette.primary.main}
        >
          {testItem.word}
        </Typography>
      </VertCenter>
    );
  }

  const handleTextInput = (e, word_no, moveNext) => {
    let s = {};
    s.given_answer = e.target.value;
    funcs["updateResultSuppField"](s, word_no);
    if (moveNext) {
      funcs["handleNavForward"]("nav");
    }
  };

  let actionB = [...testItem.action_buttons];

  actionB.sort((a, b) => parseInt(a.button_seq) - parseInt(b.button_seq));

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Box
        id="instruction"
        sx={{
          p: "2rem 3rem 0 3rem",
          // height: '20vh',
          minHeight: "20vh",
          width: "80%",
        }}
      >
        <RawHTML htmlString={testItem.instruction} />
      </Box>
      <Box
        id="centered"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          id="stimulant-response"
          width="100%"
          paddingTop="2rem"
          display="flex"
          flexDirection="row"
          justifyContent={testItem.show_stimuli ? "space-between" : "center"}
          alignItems="flex-end"
        >
          {testItem.show_stimuli && (
            <>
              <Box id="left-panel" width="20%" sx={{ p: "1rem" }}>
                {/* placeholder to even the screen up */}
              </Box>
              <FlexBetween width="60%">
                <StyledButton
                  children={<NavigateBeforeIcon sx={{ fontSize: 80 }} />}
                  variant="text"
                  style={buttonStyles.nav}
                  sxProps={{
                    maxWidth: "1rem",
                    minHeight: "4rem",
                    backgroundColor: "none",
                    marginLeft: "4rem",
                  }}
                  onClick={() => funcs["handleNavBack"]("nav")}
                />

                <Box
                  id="stimulant"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: "1rem", height: "35vh" }}
                >
                  {stimuli}
                  {testItem.textbox &&
                    !testItem.textbox_disable_answers.includes(
                      testItem.result,
                    ) &&
                    testItem.result !== null && (
                      <TextField
                        label="Enter Given Answer"
                        variant="outlined"
                        sx={{ m: "0.25rem" }}
                        value={testItem.given_answer}
                        disabled={existingResult && !edit}
                        autoFocus={true}
                        onChange={(e) => handleTextInput(e, testItem.word_no)}
                        onKeyDown={(e) =>
                          e.key === "Enter"
                            ? funcs["handleNavForward"]("nav")
                            : null
                        }
                      />
                    )}

                  <Typography
                    id="footer"
                    variant="h3"
                    sx={{
                      color: theme.palette.primary.main,
                      p: "1rem",
                    }}
                  >
                    {testItem.expected_answer &&
                      `Expected answer: ${testItem.expected_answer}`}
                  </Typography>
                </Box>

                <StyledButton
                  children={<NavigateNextIcon sx={{ fontSize: 80 }} />}
                  variant="text"
                  style={buttonStyles.nav}
                  sxProps={{
                    maxWidth: "1rem",
                    minHeight: "4rem",
                    backgroundColor: "none",
                    marginRight: "4rem",
                  }}
                  onClick={() => funcs["handleNavForward"]("nav")}
                />
              </FlexBetween>
            </>
          )}
          <Box id="action-buttons" width="20%" gap="0.5rem" sx={{ p: "1rem" }}>
            {actionB.map((btn) => {
              let p = {};
              if (
                testItem.result !== null &&
                testItem.result === btn.button_type
              ) {
                p.border = "6px solid #888";
                p.fontWeight = "bold";
              }

              return (
                <StyledButton
                  children={btn.button_text}
                  disabled={existingResult && !edit}
                  sxProps={p}
                  style={buttonStyles[btn.button_type]}
                  onClick={() =>
                    funcs[btn.button_action](
                      btn.button_type,
                      testItem.word_no,
                      !testItem.textbox ||
                        testItem.textbox_disable_answers.includes(
                          btn.button_type,
                        ) ||
                        testItem.given_answer !== null,
                    )
                  }
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AsmtQuestion;
