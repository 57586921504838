import React from "react";

import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const AdminHome = () => {
  return (
    <div>
      <Link to="new-class">
        <Button variant="contained">Create Class</Button>
      </Link>
      <Link to="class-list">
        <Button variant="contained">Class List</Button>
      </Link>
      <Link to="new-student">
        <Button>Create Student</Button>
      </Link>
    </div>
  );
};

export default AdminHome;
