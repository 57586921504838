import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const programsAdapter = createEntityAdapter({
  selectId: (e) => e.program_id,
});

const initialState = programsAdapter.getInitialState();

export const programsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: () => ({
        url: "/programs",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedPrograms = responseData.map((program) => {
          program.id = program.program_id;
          return program;
        });

        loadedPrograms.sort((a, b) => a.sort_order - b.sort_order);
        return programsAdapter.setAll(initialState, loadedPrograms);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Program", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Program", id })),
          ];
        } else return [{ type: "Program", id: "LIST" }];
      },
    }),
    enrolStudent: builder.mutation({
      query: (enrolDetails) => ({
        url: "/programs/enrolInProgram",
        method: "POST",
        body: { ...enrolDetails },
      }),
      invalidatesTags: (result, error, arg) =>
        error ? [] : [{ type: "Student", id: arg.studentId }],
    }),
    convertEnrolmentToFull: builder.mutation({
      query: (enrolDetails) => ({
        url: "/programs/convertScreeningEnrolmentToFull",
        method: "POST",
        body: { ...enrolDetails },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.studentId },
      ],
    }),
    addNewProgram: builder.mutation({
      query: (initialProgramData) => ({
        url: "/programs",
        method: "POST",
        body: {
          ...initialProgramData,
        },
      }),
      invalidatesTags: [{ type: "Program", id: "LIST" }],
    }),
    updateProgram: builder.mutation({
      query: (initialProgramData) => ({
        url: "/programs",
        method: "PATCH",
        body: {
          ...initialProgramData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Program", id: arg.id },
      ],
    }),
    deleteProgram: builder.mutation({
      query: ({ id }) => ({
        url: `/programs`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Program", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetProgramsQuery,
  useEnrolStudentMutation,
  useConvertEnrolmentToFullMutation,
  useAddNewProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
} = programsApiSlice;

// returns the query result object
export const selectProgramsResult =
  programsApiSlice.endpoints.getPrograms.select();

// creates memoized selector
const selectProgramsData = createSelector(
  selectProgramsResult,
  (programsResult) => programsResult.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllPrograms,
  selectById: selectProgramById,
  selectIds: selectProgramIds,
  // Pass in a selector that returns the Programs slice of state
} = programsAdapter.getSelectors(
  (state) => selectProgramsData(state) ?? initialState,
);
