import { store } from "../../app/store";
import { studentsApiSlice } from "../../app/api/studentsApiSlice";
import { programsApiSlice } from "../../app/api/programsApiSlice";
// import { tagsApiSlice } from '../tags/tagsApiSlice'
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
  useEffect(() => {
    // store.dispatch(
    // 	studentsApiSlice.util.prefetch('getStudents', 'studentsList', {
    // 		force: true,
    // 	})
    // )
    store.dispatch(
      studentsApiSlice.util.prefetch(
        "getStudentsSummary",
        "studentsSummaryList",
        {
          force: true,
        },
      ),
    );
    store.dispatch(
      programsApiSlice.util.prefetch("getPrograms", "programsList", {
        force: true,
      }),
    );
  }, []);

  return <Outlet />;
};
export default Prefetch;
