import { FormControl, InputAdornment, TextField } from "@mui/material";
import CustomIcon from "../CustomIcon";
import "./SearchInput.module.scss";

export const MLSearchInput = ({
  classList,
  placeholderText,
  setSearchChange,
}) => {
  const handleChange = (event) => {
    setSearchChange(event.target.value);
  };

  return (
    <FormControl className={"input-wrap " + classList}>
      <TextField
        size="small"
        placeholder={placeholderText}
        variant="outlined"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomIcon stroke="#B5B5B5" iconName="Search" />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
