import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import {
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// MUI Icons

// Custom components

// Custom hooks

// APIs
import { useSkipAsmtGroupMutation } from "../../app/api/assessmentsApiSlice";

// Images

// Random Others

const SkipGroup = ({
  skipOpen,
  skipClose,
  skipSnackOpen,
  skipSnackConfig,
  title,
  content,
  enrolGroupId,
}) => {
  const errRef = useRef();

  const [skipNote, setSkipNote] = useState("");

  const [errSaveMsg, setErrSaveMsg] = useState(null);
  const errClass = errSaveMsg ? "errmsg" : "offscreen";

  const studentId = useSelector((state) => state.global.studentId);

  const [skipGroup, { error: errorUpdate }] = useSkipAsmtGroupMutation();

  const saveSkip = async (e) => {
    const tempArr = {};

    if (skipNote === "") {
      setErrSaveMsg("Please enter a reason for skipping");
      return;
    }

    var reason = skipNote;
    var enrolgrpid = enrolGroupId;

    await skipGroup({
      enrolgrpid,
      reason,
      studentId,
    })
      .unwrap()
      .then((fulfilled) => {
        tempArr.severity = "success";
        tempArr.message = "Assessment Skipped";
      })
      .catch((rejected) => {
        if (rejected?.status === 409) {
          tempArr.severity = "warning";
        } else {
          tempArr.severity = "error";
        }
        tempArr.message =
          "Skip not saved - " + rejected.status + ": " + rejected.data.message;
      });

    if (tempArr.severity === "success") skipClose();
    skipSnackConfig(tempArr);
    skipSnackOpen(true);
  };

  return (
    <>
      <Dialog open={skipOpen} onClose={skipClose}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5">{content}</Typography>
          </DialogContentText>
          <Box>
            <TextField
              autoFocus
              margin="dense"
              id="skip-note"
              name="skip-note"
              label="Reason for skipping"
              fullWidth
              variant="standard"
              onChange={(e) => setSkipNote(e.target.value)}
            />
          </Box>
          <p ref={errRef} className={errClass} aria-live="assertive">
            {errSaveMsg}
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={skipClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              saveSkip(e);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SkipGroup;
