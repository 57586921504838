import React from "react";

import { useDispatch } from "react-redux";

// State
import { setConductAssessment_ShowAddInfo } from "../../app/state";

// MUI Components
import { Box, useTheme, Typography } from "@mui/material";

// MUI Icons

// Custom components
import ConvertScreenEnrolmentToFull from "features/programs/ConvertScreenEnrolmentToFull";

// Custom hooks

// APIs

// Images

// Random Others

const PostAssessmentInfo = ({
  program,
  grade,
  term,
  benchmark,
  avgScore,
  progEnrolID,
  isScreening,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  let showConvertButton = false;

  let addInfo1 = "";
  let addInfo2 = "";
  let addInfo3 = "";
  let resInfo = <></>;
  if (program === "WARN") {
    if (benchmark === "mid") {
      addInfo1 = `Student's decoding fluency is below average but not in the bottom quartile.`;
      addInfo2 = `Readminister the WARN Initial Assessment at the start of next term.`;
    } else if (benchmark === "high") {
      if (term === 1 || term === 2) {
        addInfo1 = `Student's decoding fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARN Initial Assessment at Start of Term 3`;
      } else if (term === 3) {
        addInfo1 = `Student's decoding fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARN Initial Assessment at End of Term 4`;
      } else if (term === 4) {
        addInfo1 = `Student's decoding fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARN Initial Assessment at Start of next year`;
      }
    } else if (benchmark === "low") {
      addInfo1 = `Student's decoding fluency is significantly below average.`;
      addInfo2 = `Administer the MiniLit Sage Placement Test if the student is not already on that program.`;
    }
  } else if (program === "WARL") {
    if (benchmark === "mid") {
      addInfo1 = `Student's word identification fluency is below average but not in the bottom quartile.`;
      addInfo2 = `Readminister the WARL Initial Assessment at the start of next term. `;
    } else if (benchmark === "high") {
      if (term === 1 || term === 2) {
        addInfo1 = `Student's word identification fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARL Initial Assessment at Start of Term 3`;
      } else if (term === 3) {
        addInfo1 = `Student's word identification fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARL Initial Assessment at End of Term 4`;
      } else if (term === 4) {
        addInfo1 = `Student's word identification fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARL Initial Assessment at Start of next year`;
      }
    } else if (benchmark === "low") {
      addInfo1 = `Student's word identification fluency is significantly below average.`;
      addInfo2 = `Administer the MiniLit Sage Placement Test if the student is not already on that program.`;
    }
  } else if (program === "WARP") {
    if (benchmark === "mid") {
      addInfo1 = `Student's passage reading fluency is below average but not in the bottom quartile.`;
      addInfo2 = `Readminister the WARP Initial Assessment at the start of next term.`;
    } else if (benchmark === "high") {
      if (term === 1 || term === 2) {
        addInfo1 = `Student's passage reading fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARP Initial Assessment at  Start of Term 3`;
      } else if (term === 3) {
        addInfo1 = `Student's passage reading fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARP Initial Assessment at  End of Term 4`;
      } else if (term === 4) {
        addInfo1 = `Student's passage reading fluency is average or above average for their grade.`;
        addInfo2 = `Readminister the WARP Initial Assessment at  Start of next year`;
      }
    } else if (benchmark === "low") {
      if (grade === "2") {
        addInfo1 = `Student's passage reading fluency is significantly below average.`;
        addInfo2 = `Administer the MiniLit Sage Placement Test if the student is not already on that program.`;
      } else if (/([3-6])/.test(grade)) {
        addInfo1 = `Student's passage reading fluency is significantly below average.`;
        addInfo2 = `Administer the MacqLit Placement Test if the student is not already on that program.`;
      }
      if (avgScore < 20) {
        addInfo3 = `Administer the WARL Initial Assessment and track student progress using that assessment, which is more appropriate for measuring small gains at low levels.`;
      }
    }
  }
  if (addInfo1 !== "") {
    let clr;
    if (benchmark === "low") {
      clr = theme.palette.benchmark.lowFill;
      showConvertButton = true;
    } else if (benchmark === "mid") {
      clr = theme.palette.benchmark.midFill;
    } else {
      clr = theme.palette.benchmark.highFill;
    }
    resInfo = (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ p: "1rem 2rem" }}
      >
        <Box
          width="80%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderRadius="0.5rem"
          sx={{
            backgroundColor: clr,
            p: "0.5rem 2rem",
            color: "#000",
          }}
        >
          <Typography variant="h4" align="center" paragraph>
            {addInfo1}
          </Typography>
          <Typography variant="h4" align="center" paragraph>
            {addInfo2}
          </Typography>
          {addInfo3 !== "" && (
            <Typography variant="h4" align="center" paragraph>
              {addInfo3}
            </Typography>
          )}
          {showConvertButton && (
            <ConvertScreenEnrolmentToFull
              progEnrolID={progEnrolID}
              programName={program}
              isScreening={isScreening}
            />
          )}
        </Box>
      </Box>
    );

    dispatch(setConductAssessment_ShowAddInfo(true));
  } else {
    dispatch(setConductAssessment_ShowAddInfo(false));
  }
  return resInfo;
};

export default PostAssessmentInfo;
