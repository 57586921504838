import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// State

// MUI Components
import {
  Box,
  useTheme,
  InputLabel,
  OutlinedInput,
  FormControl,
  FormGroup,
  Select,
} from "@mui/material";

// MUI Icons

// Chart Components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

// Custom components

// Custom hooks

// APIs
import { useGetStudentProfileQuery } from "../../../app/api/studentsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

const ProgramProgressChart = () => {
  const theme = useTheme();

  const studentId = useSelector((state) => state.global.studentId);

  const [enrolProgId, setEnrolProgId] = useState(0);
  const [maxTextWidth, setMaxTextWidth] = useState(0);

  // Pull student profile
  const {
    data: studentProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentProfileQuery(studentId);

  let ctx;

  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      ctx = document.createElement("canvas").getContext("2d");
      ctx.font = "14px 'Helvetica Neue";
    }

    return ctx.measureText(text).width;
  };

  const BAR_AXIS_SPACE = 10;

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    return <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const sP = studentProfile[0];

    // Get list of Programs
    const progs = sP.enrol_prog.map((p) => p);

    if (progs.length === 0) {
      content = <p className="errmsg">No programs enrolled</p>;
    }

    if (progs.length > 0 && enrolProgId === 0) {
      setEnrolProgId(progs[0].prog_enrol_id);
    }

    const progList = progs.map((p) => (
      <option key={p.prog_enrol_id} value={p.prog_enrol_id}>
        {p.prog_name}
        {"     "}
        {p.enrol_term !== 0
          ? " Grd: " + p.enrol_year + " Term: " + p.enrol_term
          : ""}
      </option>
    ));

    let progSelect = (
      <FormGroup sx={{ paddingTop: "2rem" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor="select-program">Program</InputLabel>
          <Select
            native
            value={enrolProgId}
            onChange={(e) => setEnrolProgId(e.target.value)}
            input={<OutlinedInput label="Program" id="select-program" />}
          >
            {progList}
          </Select>
        </FormControl>
      </FormGroup>
    );

    let data = [];

    const grps = progs.filter((p) => p.prog_enrol_id === enrolProgId);
    if (grps.length > 0) {
      grps[0].asmt_groups.map((g) =>
        data.push({
          id: g.group_seq_no,
          name: g.group_short_name,
          abbv_name: g.group_abbrv_name,
          pct: Math.round((g.group_total_score / g.group_max_score) * 100),
          score: g.group_total_score,
          max_score: g.group_max_score,
          fill: theme.palette.benchmark[g.benchmark + "Fill"],
        }),
      );
    }
    data.sort((a, b) => a.id - b.id);

    if (maxTextWidth === 0) {
      let mTW = data.reduce((acc, cur) => {
        const value = cur.pct;
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0);
      setMaxTextWidth(mTW);
    }

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p>{`${payload[0].payload.name}`}</p>
            <p>{`${payload[0].payload.score} out of ${payload[0].payload.max_score} (${payload[0].payload.pct}%)`}</p>
          </div>
        );
      }

      return null;
    };

    let chart = (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={400}
          height={500}
          data={data}
          maxBarSize={60}
          margin={{
            left: 10,
            right: maxTextWidth + BAR_AXIS_SPACE,
          }}
        >
          <XAxis hide axisLine={false} type="number" padding={{ right: 30 }} />
          <YAxis
            yAxisId={0}
            dataKey="abbv_name"
            type="category"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey="pct"
            type="category"
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => value.toLocaleString() + "%"}
            mirror
            tick={{
              transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="pct" minPointSize={2}>
            {data.map((entry, index) => (
              <Cell key={entry.name} fill={entry.fill} cursor="pointer" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );

    content = (
      <Box width="100%" height="100%">
        {progSelect}
        {chart}
      </Box>
    );
  }

  return content;
};

export default ProgramProgressChart;
