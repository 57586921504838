import React from "react";
import { useSelector } from "react-redux";

// State

// MUI Components
import { Box, useTheme, Typography } from "@mui/material";

// MUI Icons
import RuleIcon from "@mui/icons-material/Rule";

// Custom components
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";
import FlexBetween from "components/utilities/FlexBetween";
import StyledButton from "components/common/StyledButton";
import ResultSummary from "./ResultSummary";
import RawHTML from "components/common/RawHTML";

// Custom hooks

// APIs
import { useGetScoringDetailsQuery } from "../../app/api/scoringApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";
import { buttonStyles } from "styles";

const DisplayAsmtResult = ({
  asmtName,
  asmtResult,
  goBackToTest,
  saveResults,
  finaliseAsmt,
}) => {
  const theme = useTheme();
  const nextAsmt = useSelector((state) => state.global.caNextAsmt);
  let asmtInfo = useSelector((state) => state.global.caAsmtInfo);
  const nextAsmtName = nextAsmt.name;
  const existingResult = useSelector((state) => state.global.caExistingResult);
  const caManage = useSelector((state) => state.global.caManage);
  const resultChange = caManage.resultChange;

  // Pull the scoring data
  const {
    data: scoringData,
    error: scoringError,
    isLoading: scoringLoading,
  } = useGetScoringDetailsQuery();

  let score = 0;
  let error = 0;
  let total = 0;
  let row = 0;
  const aRes = asmtResult.map((a) => ({ ...a }));
  aRes.sort((a, b) => a.word_no - b.word_no);

  if (scoringError) {
    console.log("Error loading scoring data");
    return <div>Error loading scoring data</div>;
  }
  if (scoringLoading) {
    return <PulseLoader color={"#000"} />;
  }

  for (let i = 0; i < aRes.length; i++) {
    if (aRes[i].result) {
      let s = scoringData.find((x) => x.score_code === aRes[i].result);

      score += parseFloat(s?.score_award);
    }
    // If the row is different to the previous row, increment the errors - a skipped row is only 1 error
    if (aRes[i].result === "skippedRow" && aRes[i].row !== row) {
      row = aRes[i].row;
      error++;
    }
    if (aRes[i].switched) {
      error++;
    }
    if (aRes[i].concat) {
      error++;
    }
    if (aRes[i].insert) {
      error++;
    }
    total++;
  }

  const handleReturnToTest = () => {
    goBackToTest(false);
  };

  const handleNextGroup = async () => {
    if ((existingResult && resultChange) || !existingResult) {
      saveResults(true);
    } else {
      finaliseAsmt();
    }
  };

  return (
    <HorizontalCenter>
      <VertCenter
        sx={{
          p: "1rem",
          m: "2rem",
          width: "70%",
          height: "15vh",
          minHeight: "3rem",
          borderRadius: "1rem",
          border: "3px solid",
          backgroundColor: theme.palette.primary[300],
        }}
      >
        <Box
          sx={{
            border: "4px solid",
            borderRadius: "1rem",
            borderColor: theme.palette.neutral.white,
            m: "1rem",
            p: "1rem",
          }}
        >
          <RuleIcon
            sx={{
              color: theme.palette.neutral.white,
              fontSize: 60,
            }}
          />
        </Box>
        <Typography variant="h2" sx={{ color: theme.palette.neutral.white }}>
          {asmtInfo.scoring_method === "explicit"
            ? `Response selected for ${asmtName}: ${aRes[0].result}`
            : `Total Score for ${asmtName} = ${score - error}/
					${asmtInfo.max_score}`}
        </Typography>
      </VertCenter>
      {asmtInfo.post_asmt_instruction && (
        <HorizontalCenter sx={{ p: "1.5rem 0rem", width: "80%" }}>
          <RawHTML htmlString={asmtInfo.post_asmt_instruction} />
        </HorizontalCenter>
      )}
      {asmtInfo.result_summary_list && (
        <HorizontalCenter sx={{ p: "0 0 1.5rem 0rem", width: "80%" }}>
          <ResultSummary />
        </HorizontalCenter>
      )}
      <FlexBetween sx={{ width: "70%" }}>
        <HorizontalCenter sx={{ width: "20%" }}>
          <StyledButton
            children="Back"
            style={buttonStyles.nav}
            onClick={() => handleReturnToTest()}
          />
        </HorizontalCenter>
        <HorizontalCenter
          sx={{
            width: "40%",
            height: "100%",
            justifyContent: "flex-end",
          }}
        >
          <StyledButton
            children={
              ((existingResult && resultChange) || !existingResult
                ? "Save and "
                : "") +
              "Go to " +
              nextAsmtName
            }
            style={buttonStyles.correct}
            onClick={() => handleNextGroup()}
          />
        </HorizontalCenter>
      </FlexBetween>
    </HorizontalCenter>
  );
};

export default DisplayAsmtResult;
