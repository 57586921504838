import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const studentsAdapter = createEntityAdapter({
  selectId: (e) => e.student_id,
});

const initialState = studentsAdapter.getInitialState();

export const studentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudentsSummary: builder.query({
      query: () => ({
        url: "/students/summary",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedStudents = responseData.map((student) => {
          student.id = student._id;
          return student;
        });
        return studentsAdapter.setAll(initialState, loadedStudents);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Student", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Student", id })),
          ];
        } else return [{ type: "Student", id: "LIST" }];
      },
    }),
    getStudentProfile: builder.query({
      query: (studentId) => ({
        url: `/students/profile?id=${studentId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, studentId) => {
        return [{ type: "Student", id: studentId }];
      },
    }),
    addNewStudent: builder.mutation({
      query: (initialStudentData) => ({
        url: "/students",
        method: "POST",
        body: {
          ...initialStudentData,
        },
      }),
      invalidatesTags: [{ type: "Student", id: "LIST" }],
    }),
    updateStudent: builder.mutation({
      query: (initialStudentData) => ({
        url: "/students",
        method: "PATCH",
        body: {
          ...initialStudentData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.id },
      ],
    }),
    deleteStudent: builder.mutation({
      query: ({ id }) => ({
        url: `/students`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Student", id: arg.id },
      ],
    }),
  }),
});

export const {
  // useGetStudentsQuery,
  useGetStudentsSummaryQuery,
  useGetStudentProfileQuery,
  useAddNewStudentMutation,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
} = studentsApiSlice;

// returns the query result object
export const selectStudentsResult =
  studentsApiSlice.endpoints.getStudentsSummary.select();

// creates memoized selector
const selectStudentsData = createSelector(
  selectStudentsResult,
  (studentsResult) => studentsResult.data, // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllStudents,
  selectById: selectStudentById,
  selectIds: selectStudentIds,
  // Pass in a selector that returns the Students slice of state
} = studentsAdapter.getSelectors(
  (state) => selectStudentsData(state) ?? initialState,
);
