import { useSelector } from "react-redux";
import { selectCurrentToken } from "../app/api/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isManager = false;
  let isAdmin = false;
  let isSuperUser = false;
  let status = "Employee";
  let userid = -1;

  if (token) {
    const decoded = jwtDecode(token);
    const { username, userid, roles } = decoded.UserInfo;

    if (roles) {
      isManager = roles.includes("Manager");
      isAdmin = roles.includes("Admin");
      isSuperUser = roles.includes("SuperUser");

      if (isManager) status = "Manager";
      if (isAdmin) status = "Admin";
    }
    return {
      username,
      userid,
      roles,
      status,
      isManager,
      isAdmin,
      isSuperUser,
    };
  }

  return {
    username: "",
    userid,
    roles: [],
    isManager,
    isAdmin,
    isSuperUser,
    status,
  };
};
export default useAuth;
