import React, { useRef, useState, useEffect } from "react";

//  Redux
import { useSelector, useDispatch } from "react-redux";

// State
import { setTermNumber } from "../../app/state";

// MUI Components
import {
  Box,
  Button,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  DialogContent,
  Dialog,
  TextField,
  Checkbox,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

// MUI Icons

// Custom components
import FlexBetween from "components/utilities/FlexBetween";

// Custom hooks

// APIs
import { useEnrolStudentMutation } from "../../app/api/programsApiSlice";
import { useGetProgramsQuery } from "../../app/api/programsApiSlice";
import { useGetStudentsSummaryQuery } from "../../app/api/studentsApiSlice";

// Images

// Random Others
import dayjs from "dayjs";
import PulseLoader from "react-spinners/PulseLoader";

const EnrolStudentInProgram = ({
  setOpen,
  enrolProgram,
  enrolTerm,
  enrolDescription,
}) => {
  const dispatch = useDispatch();

  const studentId = useSelector((state) => state.global.studentId);
  const termNumber = useSelector((state) => state.global.termNumber);

  const errRef = useRef();

  const { student } = useGetStudentsSummaryQuery("studentsSummaryList", {
    selectFromResult: ({ data }) => ({
      student: data?.entities[studentId],
    }),
  });

  let v = {};
  v.program = enrolProgram ?? "";
  v.enrolDesc = enrolDescription ?? "";
  v.term = enrolTerm ?? termNumber;
  v.termDisabled = false;
  v.screenOnly = false;
  v.screenDisabled = false;

  const [vars, setVars] = useState(v);
  const [errMsg, setErrMsg] = useState("");
  let content = "";

  const [enrolStudent, { isLoadingEnrol }] = useEnrolStudentMutation();

  const errClass = errMsg ? "errmsg" : "offscreen";

  useEffect(() => {
    setErrMsg("");
  }, [vars]);

  const handleTextInput = (e) => {
    setVars((prev) => ({ ...prev, enrolDesc: e.target.value }));
  };

  const handleChange = (event) => {
    if (event.target.value === "") {
      setVars((prev) => ({
        ...prev,
        program: "",
        enrolDesc: "",
        termDisabled: false,
        screenOnly: false,
        screenDisabled: false,
      }));
      return;
    }
    let v = vars;
    v.program = event.target.value;
    const { ids, entities } = programs;
    let p = entities[event.target.value];
    v.screenDisabled = p.screener_default === null;
    v.screenOnly = p.screener_default !== null && p.screener_default !== false;
    if (!p.require_term) {
      v.term = "";
      v.termDisabled = true;
    } else {
      v.termDisabled = false;
    }
    setVars((prev) => ({
      ...prev,
      program: v.program,
      term: v.term,
      screenOnly: v.screenOnly,
      screenDisabled: v.screenDisabled,
      termDisabled: v.termDisabled,
    }));
  };

  const handleChangeTerm = (e) => {
    e.preventDefault();
    setVars((prev) => ({ ...prev, term: e.target.value }));
    dispatch(setTermNumber(e.target.value));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeIsScreen = (e) => {
    setVars((prev) => ({ ...prev, screenOnly: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    if (
      vars.program === "" ||
      (vars.term === "" && vars.termDisabled === false)
    ) {
      setErrMsg(
        "You must select Program and Term. Enrolment Note is optional.",
      );
      return;
    }
    const enrolDate = dayjs(new Date()).format("YYYY-MM-DD");
    const program = vars.program;
    const enrolDesc = vars.enrolDesc;
    const termNumber = vars.term === "" ? 0 : vars.term;
    const isScreenOnly = vars.screenOnly;

    e.preventDefault();
    enrolStudent({
      program,
      studentId,
      enrolDesc,
      termNumber,
      enrolDate,
      isScreenOnly,
    })
      .unwrap()
      .then((fulfilled) => setOpen(false))
      .catch((rejected) => {
        console.log(rejected);
        if (rejected.status >= 400) {
          setErrMsg(rejected.data?.message);
        }
        errRef.current.focus();
      });
  };

  const {
    data: programs,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProgramsQuery("programsList", {
    //pollingInterval: 150000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  if (isLoading || isLoadingEnrol) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids, entities } = programs;

    const progs = ids.map((program_Id) => (
      <option
        key={entities[program_Id].program_id}
        value={entities[program_Id].program_id}
      >
        {entities[program_Id].program_name}
      </option>
    ));

    content = (
      <Dialog disableEscapeKeyDown open={true} onClose={handleClose}>
        <DialogTitle>
          <FlexBetween>
            Enrol Student in Program
            <Button variant="contained" onClick={handleClose}>
              X
            </Button>
          </FlexBetween>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter required details to enrol {student.first_name}{" "}
            {student.last_name} in a Program.
          </DialogContentText>

          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormGroup>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel htmlFor="select-program">Program</InputLabel>
                <Select
                  native
                  value={vars.program}
                  onChange={handleChange}
                  input={<OutlinedInput label="Program" id="select-program" />}
                >
                  <option aria-label="None" value="" />
                  {progs}
                </Select>
              </FormControl>
              {!vars.termDisabled && (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="select-term-label">Term</InputLabel>
                  <Select
                    labelId="select-term-label"
                    id="select-term"
                    value={vars.term}
                    disabled={vars.termDisabled}
                    onChange={handleChangeTerm}
                  >
                    <MenuItem value={1}>Term 1</MenuItem>
                    <MenuItem value={2}>Term 2</MenuItem>
                    <MenuItem value={3}>Term 3</MenuItem>
                    <MenuItem value={4}>Term 4</MenuItem>
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ m: 1, minWidth: 220 }}>
                <TextField
                  id="enrol_desc"
                  label="Enrolment Note"
                  variant="outlined"
                  value={vars.enrolDesc}
                  onChange={handleTextInput}
                />
              </FormControl>
              <FormControl sx={{ m: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={vars.screenOnly}
                      disabled={vars.screenDisabled}
                      onChange={handleChangeIsScreen}
                    />
                  }
                  label="Screening Tests Only"
                />
              </FormControl>
              <Button variant="contained" onClick={handleSubmit}>
                Enrol Student
              </Button>
            </FormGroup>
          </Box>
          <p ref={errRef} className={errClass} aria-live="assertive">
            {errMsg}
          </p>
        </DialogContent>
      </Dialog>
    );
  }
  return content;
};

export default EnrolStudentInProgram;
