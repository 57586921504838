///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PAGE IS TO BE RE-WRITTEN - NO NEED TO REVIEW
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useTheme } from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetClassBandMatrixQuery } from "../../app/api/reportsApiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100%",
  backgroundColor: "transparent",
  boxShadow: "none",
  borderRadius: "0.5rem",
});

const ClassBandMatrix = ({
  className,
  program,
  year,
  termA,
  termB,
  groupA,
  groupB,
  band,
  setBand,
}) => {
  const {
    data: rptData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetClassBandMatrixQuery({
    className,
    program,
    year,
    termA,
    termB,
    groupA,
    groupB,
  });

  const theme = useTheme();

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }
  if (isSuccess) {
    content = (
      <Grid container sx={{ minHeight: "30vh", paddingTop: "0.5rem" }}>
        <Grid
          item
          xs={3}
          sx={{ backgroundColor: theme.palette.neutral[100] }}
        ></Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: theme.palette.neutral[100],
            alignItems: "center",
          }}
        >
          <Item>
            <Typography variant="h5">Below 25%</Typography>
          </Item>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: theme.palette.neutral[100],
            p: 0,
            m: 0,
          }}
        >
          <Item>
            <Typography variant="h5">25% to 40%</Typography>
          </Item>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: theme.palette.neutral[100] }}>
          <Item>
            <Typography variant="h5">Above 40%</Typography>
          </Item>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: theme.palette.neutral[100] }}>
          <Item>
            <Typography variant="h5">Below 25%</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[0].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.mid,
              border: rptData[0].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[0].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[1].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.high,
              border: rptData[1].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[1].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[2].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.high,
              border: rptData[2].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[2].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: theme.palette.neutral[100] }}>
          <Item>
            <Typography variant="h5">25% to 40%</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[3].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.low,
              border: rptData[3].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[3].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[4].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.mid,
              border: rptData[4].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[4].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[5].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.high,
              border: rptData[5].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[5].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: theme.palette.neutral[100] }}>
          <Item>
            <Typography variant="h5">Above 40%</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[6].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.low,
              border: rptData[6].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[6].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[7].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.low,
              border: rptData[7].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[7].students}</Typography>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            onClick={() => setBand(rptData[8].band)}
            sx={{
              backgroundColor: theme.palette.benchmark.mid,
              border: rptData[8].band === band ? "solid 3px #000" : "",
            }}
          >
            <Typography variant="h2">{rptData[8].students}</Typography>
          </Item>
        </Grid>
      </Grid>
    );
  }

  return content;
};

export default ClassBandMatrix;
