import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid } from "@mui/material";
import { useGetClassListQuery } from "app/api/adminApiSlice";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function createData(class_id, class_name, school_name, school_id) {
  return { class_id, class_name, school_name, school_id };
}

const ClassList = () => {
  const navigate = useNavigate();

  const {
    data: classListData,
    isLoading: classListDataLoading,
    isSuccess: classListDataSuccess,
    isError: classListDataError,
    error,
    refetch,
  } = useGetClassListQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const rows = [];

  if (classListDataSuccess) {
    classListData.forEach((data) => {
      const classData = createData(
        data.class_id,
        data.class_name,
        data.school_name,
        data.school_id,
      );
      rows.push(classData);
    });
  }

  const handleClassEdit = (class_id) => {
    navigate(`/home/admin/update-class/${class_id}`);
  };

  let content;

  if (classListDataLoading) return <PulseLoader color="#000" />;
  if (classListDataError)
    content = content = <p className="errmsg">{error?.data?.message}</p>;

  if (classListDataSuccess) {
    content = (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Class ID</TableCell>
              <TableCell align="left">Class Name</TableCell>
              <TableCell align="left">School Name</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.class_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.class_id}
                </TableCell>
                <TableCell align="left">{row.class_name}</TableCell>
                <TableCell align="left">{row.school_name}</TableCell>
                <TableCell align="left">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => handleClassEdit(row.class_id)}
                        color="primary"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button color="secondary" variant="contained">
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

    return content;
  }
};

export default ClassList;
