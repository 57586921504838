import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const adminAdapter = createEntityAdapter({
  selectId: (e) => e.admin_id,
});

const initialState = adminAdapter.getInitialState();

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClassList: builder.query({
      query: () => ({
        url: `/admin/class`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getClassById: builder.query({
      query: (classId) => ({
        url: `/admin/class/${classId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    saveClass: builder.mutation({
      query: (classDtls) => ({
        url: "/admin/class",
        method: "POST",
        body: { ...classDtls },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Config", id: "LIST" }],
    }),
    updateClass: builder.mutation({
      query: (classDtls) => ({
        url: "/admin/class",
        method: "PATCH",
        body: {},
        ...classDtls,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Config", id: "LIST" }],
    }),
  }),
});

export const {
  useGetClassListQuery,
  useGetClassByIdQuery,
  useSaveClassMutation,
  useUpdateClassMutation,
} = adminApiSlice;

// returns the query result object
// export const selectAdminResult = adminApiSlice.endpoints.getAdmin.select()

// // creates memoized selector
// const selectAdminData = createSelector(
// 	selectAdminResult,
// 	(adminResult) => adminResult.data // normalized state object with ids & entities
// )

// //getSelectors creates these selectors and we rename them with aliases using destructuring
// export const {
// 	selectAll: selectAllAdmin,
// 	selectById: selectAssessmentResultById,
// 	selectIds: selectAssessmentResultIds,
// 	// Pass in a selector that returns the Admin slice of state
// } = adminAdapter.getSelectors((state) => selectAdminData(state) ?? initialState)
