import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const Field = ({ label, value, sxProps }) => {
  const theme = useTheme();

  const sx = {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
    p: "0 0 0 8px",
  };
  const props = { ...sx, ...sxProps };
  return (
    <Box sx={props} borderRadius="6px">
      <Typography variant="h6">{label}</Typography>
      <Typography variant="h4" fontWeight="bold">
        {value}
      </Typography>
    </Box>
  );
};

export default Field;
