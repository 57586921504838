// color design tokens export
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    100: "#ccdde8",
    200: "#99bbd1",
    300: "#6699ba",
    400: "#3377a3",
    500: "#00558c",
    600: "#004470",
    700: "#003354",
    800: "#002238",
    900: "#00111c",
  },
  // primary: { // original colours
  // 	// blue
  // 	50: "#e2f2fc",
  // 	100: "#baddf9",
  // 	200: "#8ec9f6",
  // 	300: "#60b3f1",
  // 	400: "#3ca3f0",
  // 	500: "#0e94ed",
  // 	600: "#0886e0",
  // 	700: "#00558C", // was 0074cd
  // 	800: "#0063bb",
  // 	900: "#00469c",
  // },
  secondary: {
    100: "#fcebe9",
    200: "#f5c4bd",
    300: "#ee9c91",
    400: "#e77565",
    500: "#00558C",
    600: "#b33e2e",
    700: "#862f22",
    800: "#5a1f17",
    900: "#2d100b",
  },
  // secondary: { // original colours
  // 	// orange
  // 	50: "#fbf3e1",
  // 	100: "#f6dfb3",
  // 	200: "#f1cb82",
  // 	300: "#ecb651",
  // 	400: "#e9a62d",
  // 	500: "#e79812",
  // 	600: "#e38d0d",
  // 	700: "#dd7e07",
  // 	800: "#d77003",
  // 	900: "#e04e39", // was cd5900
  // },
};

// function that reverses the color palette
// function reverseTokens(tokensDark) {
// 	const reversedTokens = {}
// 	Object.entries(tokensDark).forEach(([key, val]) => {
// 		const keys = Object.keys(val)
// 		const values = Object.values(val)
// 		const length = keys.length
// 		const reversedObj = {}
// 		for (let i = 0; i < length; i++) {
// 			reversedObj[keys[i]] = values[length - i - 1]
// 		}
// 		reversedTokens[key] = reversedObj
// 	})
// 	return reversedTokens
// }
// export const tokensLight = reverseTokens(tokensDark)

// mui theme settings
export const themeSettings = () => {
  return {
    palette: {
      primary: {
        ...tokensDark.primary,
        main: tokensDark.primary[500],
        light: tokensDark.primary[200],
      },
      secondary: {
        ...tokensDark.secondary,
        main: tokensDark.secondary[500],
        light: tokensDark.secondary[200],
      },
      neutral: {
        ...tokensDark.grey,
        main: tokensDark.grey[500],
        light: tokensDark.grey[100],
        white: tokensDark.grey[0],
      },
      multiLit: {
        blue: "#00558C",
        orange: "#e04e39",
        warn: "#004986",
        warl: "#003087",
        warp: "#002554",
        miniLit: "#70AB37",
        miniLitSageA: "#007A3E",
        miniLitSageB: "#9BCF82",
        macqLit: "#910048",
        spellIt: "#9B26B6",
        languageLift: "#FFA06A",
        spellingYr34: "#966EAA",
        preLit: "#FF6C2F",
        initiaLitF: "#0077CB",
        initiaLit1: "#0077CB",
        initiaLit2CF: "#009F92",
        initiaLit2S: "#717ACF",
        initiaLit2G: "#2460A7",
        initiaLit2AM: "#6E8DB0",
        initiaLitStorybook: "#32B8DF",
        learnToRead: "#0073CD",
        learnToWrite: "#FFAC14",
        learnToLiteracyCentres: "#00558C",
        learnToResearchUnit: "#E04E39",
        learnToAssmtMonitor: "#12376D",
        learnToSchoolPartnership: "#12376D",
        learnToPositiveTeaching: "#00AB8E",
      },
      result: {
        correct: "#92D879",
        skipped: "#7A8377",
        incorrect: "#EB7176",
        switched: "#e04e39",
        skippedRow: "#A0A1A1",
        selfCorrect: "#bea500", //#7BB069
        concat: "#9b51e0",
        insert: "#fc6500",
        incorrectlyFormed: "#8c7d00",
        fluentlyRead: "#00c853",
        soundedOut: "#008c53",
      },
      benchmark: {
        high: "#41af52",
        mid: "#db923d",
        low: "#c53328",
        highFill: "#41af52c4",
        midFill: "#db923dc4",
        lowFill: "#c53328c4",
        noFill: "#000",
      },
      action: {
        active: tokensDark.secondary[900],
        // activeOpacity: 1,
        hover: tokensDark.secondary[500],
        // hoverOpacity: 0.7,
        focus: tokensDark.primary[700],
        // focusOpacity: 1,
        selected: tokensDark.primary[400],
        // selectedOpacity: 1,
      },
    },

    typography: {
      fontFamily: ["Mulish"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Raleway", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Raleway", "sans-serif"].join(","),
        fontSize: 32,
      },
      h2Plain: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 14,
      },
      h7: {
        fontFamily: ["Mulish", "sans-serif"].join(","),
        fontSize: 8,
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&.Mui-selected": {
              backgroundColor: tokensDark.primary[300],
              color: tokensDark.secondary.main,
              "&:hover": {
                backgroundColor: tokensDark.secondary[300],
              },
            },
          },
        },
      },
    },
  };
};
