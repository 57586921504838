import React from "react";
import { useState } from "react";

//  Redux

// State

// MUI Components
import { Button } from "@mui/material";

// MUI Icons

// Custom components

// Custom hooks

// APIs
import { useConvertEnrolmentToFullMutation } from "../../app/api/programsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

const ConvertEnrolmentScreenerToFull = ({
  progEnrolID,
  programName,
  isScreening,
}) => {
  const [errMsg, setErrMsg] = useState("");

  const [convertEnrolmentToFull, { isLoading, isSuccess, isError, error }] =
    useConvertEnrolmentToFullMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await convertEnrolmentToFull({
        progEnrolID,
      }).unwrap();
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Err Msg 400: " + err.data?.message);
      } else if (err.status === 401) {
        setErrMsg("Err Msg 401: " + err.data?.message);
      } else {
        setErrMsg(err.data?.message);
      }
    }
  };

  let msg = "Convert " + programName + " Screener to Full Enrolment";

  if (!isScreening) msg = "Student Already Fully Enrolled in " + programName;

  if (isLoading) msg = <PulseLoader color={"#FFF"} />;

  if (isError) {
    msg = errMsg;
  }

  if (isSuccess) msg = "Student Enrolled Successfully";

  return (
    <Button
      variant="contained"
      disabled={/^(?!Convert).+/.test(msg)}
      onClick={handleSubmit}
    >
      {msg}
    </Button>
  );
};

export default ConvertEnrolmentScreenerToFull;
