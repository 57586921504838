import { Box } from "@mui/material";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function executeFunctionByName(functionName, context /*, args */) {
  var args = Array.prototype.slice.call(arguments, 2);
  var namespaces = functionName.split(".");
  var func = namespaces.pop();
  for (var i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }
  return context[func].apply(context, args);
}

export function convertStringtoFunction(functionName) {
  var nullFunc = function () {}; // Fallback Null-Function
  var ret = window; // Top level namespace

  // If null/undefined string, then return a Null-Function
  if (functionName == null) return nullFunc;

  // Convert string to function name
  functionName.split(".").forEach(function (key) {
    ret = ret[key];
  });

  // If function name is not available, then return a Null-Function else the actual function
  return ret == null ? nullFunc : ret;
}

export function imageFormatter(
  cell,
  folder,
  altText,
  width,
  height,
  align,
  fit,
) {
  var imageRequirer = require.context(
    "../assets",
    true,
    /^\.\/.*\.(jpg|png|gif)$/,
  );
  return (
    <Box
      display="flex"
      alignItems={align}
      component="img"
      fit={fit}
      sx={{
        width: { width },
        height: { height },
      }}
      alt={altText}
      src={require("../assets/" + folder + cell)}
    />
  );
}
