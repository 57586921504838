import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	styled,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material'
import { useGetInitiaLitTabularDataQuery } from 'app/api/reportsApiSlice'
import MultiSelect from 'components/common/MultiSelect'
import FlexBetween from 'components/utilities/FlexBetween'
import { useEffect, useMemo, useState } from 'react'
import { PulseLoader } from 'react-spinners'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}))

const StyledTableCellBody = styled(TableCell)(({ theme, benchmark }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		backgroundColor: theme.palette.benchmark[`${benchmark}Fill`],
	},
}))

const ReportinitialitStudentWise = () => {
	const theme = useTheme()

	const [selectedProgram, setSelectedProgram] = useState('InitiaLit-F')
	const [selectedGroupName, setSelectedGroupName] = useState('Screener')
	const [selectedSchoolName, setSelectedSchoolName] = useState([])
	const [selectedClassName, setSelectedClassName] = useState([])
	const [selectedGrade, setSelectedGrade] = useState([])
	const [selectedAtsi, setSelectedAtsi] = useState([])
	const [selectedEald, setSelectedEald] = useState('')
	const [selectedGender, setSelectedGender] = useState([])
	const [selectedTags, setSelectedTags] = useState([])
	const [incExTags, setIncExTags] = useState('include')

	const [filteredCollection, setFilteredCollection] = useState()

	// Pull tabular data from API
	const { data, isLoading } = useGetInitiaLitTabularDataQuery()

	// Program array
	const programOptions = ['InitiaLit-F', 'InitiaLit-1', 'InitiaLit-2']

	// Eald options
	const ealdOptions = ['Yes', 'No']

	// Gender options
	const genderOptions = [
		{ name: 'Male' },
		{ name: 'Female' },
		{ name: 'Not Stated' },
	]

	// Atsi options
	const atsiOptions = [
		{ name: 'Not stated' },
		{ name: 'Aboriginal' },
		{ name: 'Torres Strait Islander' },
		{ name: 'Both' },
		{ name: 'Neither' },
	]

	const {
		collectionGroupShortNames,
		groupCollections,
		schoolOptions,
		classOptions,
		gradeOptions,
		tagsOptions,
	} = useMemo(() => {
		const groupNames = []
		const collections = {}
		const schoolOptionsSet = new Set()
		const classOptionsSet = new Set()
		const gradeOptionsSet = new Set()
		const tagsOptionsSet = new Set()

		if (data) {
			data.filter((e) => e.program_name === selectedProgram).forEach(
				(element) => {
					// group short name
					const shortName = element.group_short_name
					if (shortName && !groupNames.includes(shortName)) {
						groupNames.push(shortName)
					}

					// make collection of group short name
					const groupName = `collection_${shortName
						?.toLowerCase()
						.replaceAll(' ', '_')}`
					if (!collections[groupName]) {
						collections[groupName] = []
					}
					collections[groupName].push(element)

					// get school options
					schoolOptionsSet.add(element.school_name)

					// get school options
					classOptionsSet.add(element.class_name)

					// get grade options
					gradeOptionsSet.add(element.grade)

					// get tag options
					element.tags.forEach((tag) => {
						tagsOptionsSet.add(tag.tag_name)
					})
				}
			)
		}

		return {
			collectionGroupShortNames: groupNames,
			groupCollections: collections,
			schoolOptions: [...schoolOptionsSet].map((name) => ({ name })),
			classOptions: [...classOptionsSet].map((name) => ({ name })),
			gradeOptions: [...gradeOptionsSet].map((name) => ({ name })),
			tagsOptions: [...tagsOptionsSet].map((name) => ({ name })),
		}
	}, [data, selectedProgram])

	const collectionName = `collection_${selectedGroupName
		?.toLowerCase()
		.replaceAll(' ', '_')}`

	const getSummaryGroupList = () => {
		const summaryGroups = new Set()

		groupCollections[collectionName]?.forEach((group) => {
			if (group.summary_group) {
				summaryGroups.add(group.summary_group)
			}
		})

		return Array.from(summaryGroups)
	}

	useEffect(() => {
		setFilteredCollection(groupCollections[collectionName])
		console.log('hit useEffect')
	}, [collectionName, groupCollections])

	const summaryGroupList = getSummaryGroupList()

	const changeProgram = (e) => {
		setSelectedProgram(e.target.value)
		setSelectedGroupName('Screener')
		resetFilters()
	}

	const resetFilters = () => {
		setSelectedSchoolName([])
		setSelectedClassName([])
		setSelectedGrade([])
		setSelectedAtsi([])
		setSelectedEald('')
		setSelectedGender([])
		setSelectedTags([])
	}

	const handleChange = (event, incEx) => {
		setIncExTags(incEx)
	}

	const getAverageScoreSummaryWise = (summary_group_name, collection) => {
		const currData = !collection
			? groupCollections[collectionName]
			: collection
		const groupData = currData.filter(
			(data) => data.summary_group === summary_group_name
		)

		let totalScore = 0
		groupData?.forEach((item) => {
			totalScore += +item.total_score
		})

		return (totalScore / groupData.length).toFixed(2).replace(/\.?0+$/, '')
	}

	const groupByStudent = (collection) => {
		const currData = !collection
			? groupCollections[collectionName]
			: collection

		return Object.values(
			// groupCollections[collectionName]?.reduce((acc, item) => {
			currData?.reduce((acc, item) => {
				const studentId = item.student_id

				if (!acc[studentId]) {
					acc[studentId] = {
						student_id: studentId,
						student_name: item.student_name,
						grade: item.grade,
						class_name: item.class_name,
						school_name: item.school_name,
						gender: item.gender,
						atsi: item.atsi,
						eald: item.eald,
						assessments: [],
						total_score: 0,
						max_score: 0,
					}
				}

				acc[studentId].assessments.push({
					benchmark: item.benchmark,
					fill: theme.palette.benchmark[item.benchmark + 'Fill'],
					summary_group: item.summary_group,
					seq: item.seq,
					asmt_date: item.asmt_date,
					total_score: parseFloat(item.total_score),
					max_score: parseFloat(item.max_score),
					pct_score: item.pct_score,
					tags: item.tags,
				})

				acc[studentId].total_score += parseFloat(item.total_score)
				acc[studentId].max_score += parseFloat(item.max_score)
				return acc
			}, {})
		)
	}

	const getAverages = (collection) => {
		let avgScore = 0
		let avgPer = 0
		let totalStudents = 0
		let totalScores = 0
		let totalPercentages = 0

		const groupedStudents = groupByStudent(collection)

		Object.values(groupedStudents).forEach((student) => {
			totalStudents++

			totalScores += parseFloat(student.total_score) || 0

			const totalAssessmentPct = student.assessments.reduce(
				(acc, asmt) => {
					const score = parseFloat(asmt.total_score) || 0
					const max = parseFloat(asmt.max_score) || 0

					const percentage = max > 0 ? (score / max) * 100 : 0
					return acc + percentage
				},
				0
			)

			totalPercentages += totalAssessmentPct
		})

		avgScore = totalStudents > 0 ? totalScores / totalStudents : 0

		const totalAssessments = Object.values(groupedStudents).reduce(
			(count, student) => {
				return count + student.assessments.length
			},
			0
		)

		avgPer = totalAssessments > 0 ? totalPercentages / totalAssessments : 0

		return { avgScore, avgPer }
	}

	// filter method
	useEffect(() => {
		let filteredData = groupCollections[collectionName]?.filter(
			(item) =>
				(selectedSchoolName.length === 0 ||
					selectedSchoolName.includes(item.school_name)) &&
				(selectedClassName.length === 0 ||
					selectedClassName.includes(item.class_name)) &&
				(selectedGrade.length === 0 ||
					selectedGrade.includes(item.class_name)) &&
				(selectedAtsi.length === 0 ||
					selectedAtsi.includes(item.atsi)) &&
				(selectedGender.length === 0 ||
					selectedGender.includes(item.gender)) &&
				(selectedTags.length === 0 ||
					(incExTags === 'include' &&
						selectedTags.some((tag) =>
							item.tags.some(
								(itemTag) => itemTag.tag_name === tag
							)
						)) ||
					(incExTags === 'exclude' &&
						!selectedTags.some((tag) =>
							item.tags.some(
								(itemTag) => itemTag.tag_name === tag
							)
						)))
		)

		if (selectedEald) {
			filteredData = filteredData.filter(
				(item) => item.eald === selectedEald
			)
		}
		setFilteredCollection(filteredData)
	}, [
		selectedProgram,
		selectedSchoolName,
		selectedClassName,
		selectedGrade,
		selectedAtsi,
		selectedEald,
		selectedGender,
		selectedTags,
		incExTags,
		groupCollections,
		collectionName,
	])

	if (isLoading) return <PulseLoader />

	return (
		<Grid container spacing={2}>
			<Grid item xs={2} sx={{ marginTop: '10px' }}>
				<FlexBetween width="100%">
					<Typography variant="h4">Filters</Typography>
					<Button variant="contained" onClick={() => resetFilters()}>
						Reset
					</Button>
				</FlexBetween>
				{/* Program Name */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<InputLabel id="programname">Program name</InputLabel>
					<Select
						labelId="programname"
						id="programname-id"
						value={selectedProgram}
						label="Program"
						onChange={(e) => changeProgram(e)}
						sx={{ width: '250px', marginLeft: '5px' }}
					>
						{programOptions.map((program_name, index) => (
							<MenuItem key={index} value={program_name}>
								{program_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* Group Name */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<InputLabel id="groupname">Group name</InputLabel>
					<Select
						labelId="groupname"
						id="groupname-id"
						value={selectedGroupName}
						label="Group"
						onChange={(e) => setSelectedGroupName(e.target.value)}
						sx={{ width: '250px', marginLeft: '5px' }}
					>
						{collectionGroupShortNames.map((group_name, index) => (
							<MenuItem key={index} value={group_name}>
								{group_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* School Name */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="School"
						options={schoolOptions}
						selected={selectedSchoolName}
						setSelected={setSelectedSchoolName}
					/>
				</FormControl>

				{/* Class Name */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="Class"
						options={classOptions}
						selected={selectedClassName}
						setSelected={setSelectedClassName}
					/>
				</FormControl>

				{/* Grade */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="Grade"
						options={gradeOptions}
						selected={selectedGrade}
						setSelected={setSelectedGrade}
					/>
				</FormControl>

				{/* Tag */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="Tag"
						options={tagsOptions}
						selected={selectedTags}
						setSelected={setSelectedTags}
					/>
				</FormControl>
				<ToggleButtonGroup
					color="primary"
					value={incExTags}
					exclusive
					onChange={handleChange}
					aria-label="Platform"
				>
					<ToggleButton value="include">Include tags</ToggleButton>
					<ToggleButton value="exclude">Exclude tags</ToggleButton>
				</ToggleButtonGroup>
				{/* Atsi */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="Atsi"
						options={atsiOptions}
						selected={selectedAtsi}
						setSelected={setSelectedAtsi}
					/>
				</FormControl>

				{/* Eald */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<InputLabel id="eald">Eald</InputLabel>
					<Select
						labelId="eald"
						id="eald-id"
						value={selectedEald}
						label="Eald"
						onChange={(e) => setSelectedEald(e.target.value)}
						sx={{ width: '250px', marginLeft: '5px' }}
					>
						{ealdOptions.map((eald, index) => (
							<MenuItem key={index} value={eald}>
								{eald}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* Gender */}
				<FormControl fullWidth sx={{ marginTop: '10px' }}>
					<MultiSelect
						label="Gender"
						options={genderOptions}
						selected={selectedGender}
						setSelected={setSelectedGender}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={10}>
				<Paper sx={{ overflow: 'auto' }}>
					<TableContainer sx={{ maxHeight: '80vh' }}>
						<Table stickyHeader sx={{ minWidth: 650 }}>
							<TableHead>
								<TableRow>
									<FlexBetween width="100%">
										<Typography variant="h4">
											InitiaLit-1 {selectedGroupName} -
											Class Record
										</Typography>
									</FlexBetween>
								</TableRow>
								<TableRow>
									<StyledTableCell
										align="left"
										sx={{ p: '0 0 0 1rem' }}
									>
										Student Name
									</StyledTableCell>
									<StyledTableCell
										align="left"
										sx={{ p: '0 0 0 1rem' }}
									>
										Class
									</StyledTableCell>
									<StyledTableCell
										align="left"
										sx={{ p: '0 0 0 1rem' }}
									>
										Date
									</StyledTableCell>
									{summaryGroupList.map(
										(groupName, index) => (
											<StyledTableCell
												key={index}
												align="left"
											>
												{groupName}
											</StyledTableCell>
										)
									)}
									<StyledTableCell
										align="left"
										sx={{ p: '0 0 0 1rem' }}
									>
										Total(100)
									</StyledTableCell>
									<StyledTableCell
										align="left"
										sx={{ p: '0 0 0 1rem' }}
									>
										Total(%)
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell align="left" colSpan={3}>
										Averages:{' '}
									</TableCell>
									{summaryGroupList.map(
										(groupName, index) => (
											<TableCell
												key={index}
												align="center"
											>
												(
												{getAverageScoreSummaryWise(
													groupName,
													filteredCollection
												)}
												)
											</TableCell>
										)
									)}
									<TableCell>
										(
										{
											getAverages(filteredCollection)
												.avgScore
										}
										)
									</TableCell>
									<TableCell>
										(
										{getAverages(
											filteredCollection
										).avgPer.toFixed(2)}
										)
									</TableCell>
								</TableRow>
								{Object.values(
									groupByStudent(filteredCollection)
								).map((student_data, index) => {
									console.log(student_data)

									return (
										<TableRow>
											<TableCell align="left">
												{student_data.student_name}
											</TableCell>
											<TableCell align="center">
												{student_data.class_name}
											</TableCell>
											<TableCell align="center">
												{student_data.asmt_date ??
													'20/04/2024'}
											</TableCell>
											{summaryGroupList.map(
												(groupName, groupIndex) => (
													<StyledTableCellBody
														align="center"
														key={groupIndex}
														benchmark={
															student_data.benchmark
														}
													>
														{student_data.assessments.some(
															(asmt) =>
																asmt.summary_group ===
																groupName
														)
															? student_data.assessments
																	.filter(
																		(
																			asmt
																		) =>
																			asmt.summary_group ===
																			groupName
																	)
																	.reduce(
																		(
																			acc,
																			curr
																		) =>
																			acc +
																			parseFloat(
																				curr.total_score
																			),
																		0
																	)
															: 0}
													</StyledTableCellBody>
												)
											)}
											<TableCell>
												{student_data.total_score}
											</TableCell>
											<TableCell>
												{(
													(student_data.total_score /
														student_data.max_score) *
													100
												).toFixed(2)}{' '}
												%
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default ReportinitialitStudentWise
