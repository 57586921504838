import React, { useState, useEffect } from "react";

// State

// MUI Components
import {
  Box,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// MUI Icons

// Custom components
import FlexBetween from "components/utilities/FlexBetween";
import HorizontalCenter from "components/utilities/HorizontalCenter";
import VertCenter from "components/utilities/VertCenter";
import InitiaLitYTDChart from "./charts/InitiaLitYTDChart";
import MultiSelect from "components/common/MultiSelect";

// Custom hooks

// APIs
import { useGetInitiaLitSnapshotDataQuery } from "../../app/api/reportsApiSlice";

// Images

// Random Others
import PulseLoader from "react-spinners/PulseLoader";

const ReportInitiaLitSnapshot = () => {
  // set the filter options
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesFiltered, setClassesFiltered] = useState([]);
  const [grades, setGrades] = useState([]);
  const [tags, setTags] = useState([]);
  const [genders, setGenders] = useState([]);

  // set the selected filters
  const [selectedProgram, setSelectedProgram] = useState(["InitiaLit-F"]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [incExTags, setIncExTags] = useState("include");
  const [selectedATSI, setSelectedATSI] = useState([]);
  const [selectedEALD, setSelectedEALD] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);

  // Program array
  const programs = [
    { name: "InitiaLit-F" },
    { name: "InitiaLit-1" },
    { name: "InitiaLit-2" },
  ];

  // ATSI array
  const atsi = [
    { name: "Aboriginal" },
    { name: "Torres Strait Islander" },
    { name: "Both" },
    { name: "Neither" },
  ];

  // EALD array
  const eald = [{ name: "Yes" }, { name: "No" }];

  // Pull data from API
  const {
    data,
    error: dataError,
    isLoading: dataLoading,
  } = useGetInitiaLitSnapshotDataQuery();

  const changeSchool = (schoolsSelected) => {
    let cF = [];
    classes.forEach((c) => {
      if (schoolsSelected.indexOf(c.school) !== -1) cF.push(c);
    });
    setClassesFiltered([...cF]);
    setSelectedClass([]);
  };

  const resetFilters = () => {
    setSelectedProgram(["InitiaLit-F"]);
    setSelectedSchool([]);
    setSelectedClass([]);
    setSelectedGrade([]);
    setSelectedTag([]);
    setSelectedATSI([]);
    setSelectedEALD([]);
    setSelectedGender([]);
  };

  const handleChange = (event, incEx) => {
    setIncExTags(incEx);
  };

  let content;

  if (dataError) {
    return <p className="errmsg">{dataError?.data?.message}</p>;
  }
  if (dataLoading) {
    return <PulseLoader color={"#000"} />;
  } else {
    // build arrays from data
    if (schools?.length === 0 && data.length) {
      data.forEach((item) => {
        if (schools.map((e) => e.name).indexOf(item.school_name) === -1) {
          schools.push({ name: item.school_name });
        }

        if (classes.map((e) => e.name).indexOf(item.class_name) === -1) {
          classes.push({
            school: item.school_name,
            name: item.class_name,
          });
        }
        if (grades.map((e) => e.name).indexOf(item.grade) === -1) {
          grades.push({ name: item.grade });
        }
        if (genders.map((e) => e.name).indexOf(item.gender) === -1) {
          genders.push({ name: item.gender });
        }
        item.tags.forEach((tag) => {
          if (tags.map((e) => e.name).indexOf(tag.tag_name) === -1) {
            tags.push({ name: tag.tag_name });
          }
        });
      });
      setSelectedProgram([programs[0].name]);
    }

    // Filter controls
    const filterControls = (
      <HorizontalCenter sx={{ width: "250px" }}>
        <FlexBetween width="100%">
          <Typography variant="h4">Filters</Typography>
          <Button variant="contained" onClick={() => resetFilters()}>
            Reset
          </Button>
        </FlexBetween>
        <MultiSelect
          label="Program"
          options={programs}
          selected={selectedProgram}
          setSelected={setSelectedProgram}
          multi={false}
        />
        <MultiSelect
          label="School"
          options={schools}
          selected={selectedSchool}
          setSelected={setSelectedSchool}
          onChange={changeSchool}
        />
        <MultiSelect
          label="Class"
          options={classesFiltered.length === 0 ? classes : classesFiltered}
          selected={selectedClass}
          setSelected={setSelectedClass}
        />
        <MultiSelect
          label="Grade"
          options={grades}
          selected={selectedGrade}
          setSelected={setSelectedGrade}
        />
        <MultiSelect
          label="Tag"
          options={tags}
          selected={selectedTag}
          setSelected={setSelectedTag}
        />
        <ToggleButtonGroup
          color="primary"
          value={incExTags}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="include">Include tags</ToggleButton>
          <ToggleButton value="exclude">Exclude tags</ToggleButton>
        </ToggleButtonGroup>
        <MultiSelect
          label="ATSI"
          options={atsi}
          selected={selectedATSI}
          setSelected={setSelectedATSI}
        />
        <MultiSelect
          label="EALD"
          options={eald}
          selected={selectedEALD}
          setSelected={setSelectedEALD}
        />
        <MultiSelect
          label="Gender"
          options={genders}
          selected={selectedGender}
          setSelected={setSelectedGender}
        />
      </HorizontalCenter>
    );

    // Format data for chart
    const filteredData = data.filter(
      (item) =>
        (selectedProgram.length === 0 ||
          selectedProgram.includes(item.program_name)) &&
        (selectedSchool.length === 0 ||
          selectedSchool.includes(item.school_name)) &&
        (selectedClass.length === 0 ||
          selectedClass.includes(item.class_name)) &&
        (selectedGrade.length === 0 || selectedGrade.includes(item.grade)) &&
        (selectedTag.length === 0 ||
          (incExTags === "include" &&
            selectedTag.some((tag) =>
              item.tags.some((itemTag) => itemTag.tag_name === tag),
            )) ||
          (incExTags === "exclude" &&
            !selectedTag.some((tag) =>
              item.tags.some((itemTag) => itemTag.tag_name === tag),
            ))) &&
        (selectedATSI.length === 0 || selectedATSI.includes(item.atsi)) &&
        (selectedEALD.length === 0 || selectedEALD.includes(item.eald)) &&
        (selectedGender.length === 0 || selectedGender.includes(item.gender)),
    );

    const benchmarkCounts = filteredData.reduce(
      (acc, item) => {
        acc.low += item.benchmark === "low" ? 1 : 0;
        acc.mid += item.benchmark === "mid" ? 1 : 0;
        acc.high += item.benchmark === "high" ? 1 : 0;
        return acc;
      },
      { low: 0, mid: 0, high: 0 },
    );

    const maxBenchmarkCount = Math.round(
      Math.max(...Object.values(benchmarkCounts)) * 1.2,
    );

    const summaryData = filteredData.reduce((acc, item) => {
      const group = acc.find((g) => g.label === item.group_name);
      if (group) {
        group.data[0] += item.benchmark === "low" ? 1 : 0;
        group.data[1] += item.benchmark === "mid" ? 1 : 0;
        group.data[2] += item.benchmark === "high" ? 1 : 0;
      } else {
        acc.push({
          label: item.group_name,
          data: [
            item.benchmark === "low" ? 1 : 0,
            item.benchmark === "mid" ? 1 : 0,
            item.benchmark === "high" ? 1 : 0,
          ],
          program_order: item.program_order,
          group_order: item.group_order,
        });
      }
      return acc;
    }, []);

    // Sort summaryData by program_order then by group_order
    summaryData.sort((a, b) => {
      const programOrderA = data.find(
        (item) => item.group_name === a.label,
      ).program_order;
      const programOrderB = data.find(
        (item) => item.group_name === b.label,
      ).program_order;
      const groupOrderA = data.find(
        (item) => item.group_name === a.label,
      ).group_order;
      const groupOrderB = data.find(
        (item) => item.group_name === b.label,
      ).group_order;

      if (programOrderA !== programOrderB) {
        return programOrderA - programOrderB;
      }
      return groupOrderA - groupOrderB;
    });

    content = (
      <VertCenter id="chart-container" sx={{ width: "100%" }}>
        {filterControls}
        <InitiaLitYTDChart
          width={"800px"}
          height={"600px"}
          chartData={summaryData}
          yAxisMax={maxBenchmarkCount}
        />
      </VertCenter>
    );
  }
  return content;
};

export default ReportInitiaLitSnapshot;
