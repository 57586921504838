///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// PAGE IS TO BE RE-WRITTEN - NO NEED TO REVIEW
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setReportDrilldown_StudentSelected } from "../../app/state";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
} from "@mui/material";
import useTitle from "../../hooks/useTitle";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import StudentListFiltered from "../students/StudentsListFiltered";

const uniqueBy = (x, f) =>
  Object.values(
    x.reduce((a, b) => {
      a[f(b)] = b;
      return a;
    }, {}),
  );

const ResultsByBenchmark = ({ data }) => {
  useTitle("MAP: Benchmark Results");

  const theme = useTheme();
  const dispatch = useDispatch();
  const COLORS = [
    theme.palette.benchmark.high,
    theme.palette.benchmark.mid,
    theme.palette.benchmark.low,
  ];

  // Filters
  const [regionName, setRegionName] = useState(data[0].region_name);
  const [schoolName, setSchoolName] = useState(data[0].school_name);
  const [className, setClassName] = useState(data[0].class_name);
  const [programName, setProgramName] = useState(data[0].program_name);
  const [groupName, setGroupName] = useState(data[0].group_name);
  const [accYear, setAccYear] = useState("No Filter");
  const [termNumber, setTermNumber] = useState("No Filter");
  const [term, setTerm] = useState(0);
  const [benchmarkSelect, setBenchmarkSelect] = useState();

  let content;

  const showFilteredStudents = (benchmark) => {
    dispatch(setReportDrilldown_StudentSelected(false));
    setBenchmarkSelect(benchmark);
  };

  // Filter results
  var conditions = [];

  // Dynamically build the list of conditions
  if (regionName !== "No Filter") {
    conditions.push(function (item) {
      return item.region_name === regionName;
    });
  }

  if (schoolName !== "No Filter") {
    conditions.push(function (item) {
      return item.school_name === schoolName;
    });
  }
  if (className !== "No Filter") {
    conditions.push(function (item) {
      return item.class_name === className;
    });
  }
  if (programName !== "No Filter") {
    conditions.push(function (item) {
      return item.program_name === programName;
    });
  }
  if (groupName !== "No Filter") {
    conditions.push(function (item) {
      return item.group_name === groupName;
    });
  }
  if (accYear !== "No Filter") {
    conditions.push(function (item) {
      return item.accademic_year === accYear;
    });
  }
  if (termNumber !== "No Filter") {
    conditions.push(function (item) {
      return item.term_number === termNumber;
    });
  }

  const chartData = data.filter((d) => conditions.every((c) => c(d)));

  const highResults = chartData.reduce(
    (a, { highstudents }) => a + highstudents,
    0,
  );
  const midResults = chartData.reduce(
    (a, { midstudents }) => a + midstudents,
    0,
  );
  const lowResults = chartData.reduce(
    (a, { lowstudents }) => a + lowstudents,
    0,
  );

  const setTheRegionName = (regionName) => {
    setRegionName(regionName);
    setBenchmarkSelect(null);
  };
  const setTheSchoolName = (schoolName) => {
    setSchoolName(schoolName);
    setBenchmarkSelect(null);
  };
  const setTheClassName = (className) => {
    setClassName(className);
    setBenchmarkSelect(null);
  };
  const setTheProgramName = (programName) => {
    setProgramName(programName);
    setBenchmarkSelect(null);
  };
  const setTheGroupName = (groupName) => {
    setGroupName(groupName);
    setBenchmarkSelect(null);
  };
  const setTheAccYear = (accYear) => {
    setAccYear(accYear);
    setBenchmarkSelect(null);
  };

  const setTheTerm = (termNumber) => {
    setTermNumber(termNumber);
    if (termNumber === "No Filter") {
      setTerm(0);
    } else {
      setTerm(termNumber);
    }
  };

  let results = [];
  results.push({ name: "Above 40%", benchmark: "high", value: highResults });
  results.push({ name: "25-40%", benchmark: "mid", value: midResults });
  results.push({ name: "Below 25%", benchmark: "low", value: lowResults });

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${value} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="0.5rem"
      padding="2rem 0"
    >
      <Box
        id="filter-selectors"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="0.5rem"
        sx={{
          backgroundColor: theme.palette.neutral[50],
          padding: "1rem",
          borderRadius: "0.5rem",
          border: "1px solid",
        }}
      >
        <Stack
          direction="row"
          padding="2rem 0"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-region-label">Region</InputLabel>
            <Select
              labelId="select-region-label"
              id="select-region"
              value={regionName}
              onChange={(e) => setTheRegionName(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.region_name).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.region_name}>
                    {obj.region_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-school-label">School</InputLabel>
            <Select
              labelId="select-school-label"
              id="select-school"
              value={schoolName}
              onChange={(e) => setTheSchoolName(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.school_name).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.school_name}>
                    {obj.school_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-class-label">Class</InputLabel>
            <Select
              labelId="select-class-label"
              id="select-class"
              value={className}
              onChange={(e) => setTheClassName(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.class_name).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.class_name}>
                    {obj.class_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-program-label">Program</InputLabel>
            <Select
              labelId="select-program-label"
              id="select-program"
              value={programName}
              onChange={(e) => setTheProgramName(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.program_name).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.program_name}>
                    {obj.program_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-group-label">Group</InputLabel>
            <Select
              labelId="select-group-label"
              id="select-group"
              value={groupName}
              onChange={(e) => setTheGroupName(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.group_name).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.group_name}>
                    {obj.group_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-accYear-label">Grade</InputLabel>
            <Select
              labelId="select-accYear-label"
              id="select-accYear"
              value={accYear}
              onChange={(e) => setTheAccYear(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.accademic_year).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.accademic_year}>
                    {obj.accademic_year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginLeft: 1, minWidth: 120 }}>
            <InputLabel id="select-term-label">Term</InputLabel>
            <Select
              labelId="select-term-label"
              id="select-term"
              value={termNumber}
              onChange={(e) => setTheTerm(e.target.value)}
            >
              <MenuItem key={-1} value={`No Filter`}>
                No Filter
              </MenuItem>
              {uniqueBy(data, (x) => x.term_number).map((obj, key) => {
                return (
                  <MenuItem key={key} value={obj.term_number}>
                    {obj.term_number}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="0.5rem"
        width="100%"
      >
        <Typography variant="h2">Results By Benchmark</Typography>

        <PieChart width={500} height={500}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={results}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={200}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                onClick={() => showFilteredStudents(results[index].benchmark)}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>

        {benchmarkSelect && (
          <StudentListFiltered
            regionName={regionName}
            schoolName={schoolName}
            className={className}
            programName={programName}
            groupName={groupName}
            accYear={accYear}
            termNumber={term}
            benchmark={benchmarkSelect}
          />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default ResultsByBenchmark;
