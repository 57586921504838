import React from "react";
import { Box, Typography } from "@mui/material";

const BannerHeader = ({ title }) => {
  return (
    <Box
      display="Flex"
      justifyContent="center"
      boxShadow={1}
      sx={{
        borderRadius: "1rem",
        p: "1rem",
      }}
    >
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
};

export default BannerHeader;
