import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// State

import {
  setCaAsmtResult,
  setCaManage,
  setCaAsmtInstComp,
  setCaTimerOn,
  setCaFinalEdit,
} from "../../app/state";

// MUI Components
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// MUI Icons

// Custom components
import AsmtQuestion from "./AsmtQuestion";
import AsmtMatrix from "./AsmtMatrix";
import AsmtProgressRibbon from "./AsmtProgressRibbon";
import BasicSnackbar from "components/common/BasicSnackbar";
import Timer from "components/utilities/Timer";

// Custom hooks

// APIs

// Images

// Random Others

const DisplayAsmtQuestion = ({ questionType, questions, setQuestComp }) => {
  const dispatch = useDispatch();

  const asmtInfo = useSelector((state) => state.global.caAsmtInfo);
  const existingResult = useSelector((state) => state.global.caExistingResult);
  const caManage = useSelector((state) => state.global.caManage);

  var questCnt = questions.length;
  const [i, setI] = useState(0);
  const [snackConfig, setSnackConfig] = useState(0);
  const [openWarningSnack, setOpenWarningSnack] = useState(false);
  const [data, setData] = useState(questions);
  const [warningOpen, setWarningOpen] = useState(false);
  let tempData = [];

  const updateResults = (rslt, word) => {
    tempData = data.map((d) => ({ ...d }));
    const indx = tempData.findIndex((k) => k.word_no === word);
    tempData[indx].result = rslt;
    if (asmtInfo.fill_correct_on_score) {
      fillCorrect(tempData, word);
    } else {
      setData([...tempData]);
    }
    if (existingResult) {
      let tempManage = structuredClone(caManage);
      tempManage.resultChange = true;
      dispatch(setCaManage(tempManage));
    }
    return;
  };

  // Update all the unscored items prior to the one being scored to be correct
  const fillCorrect = (d, word) => {
    d.map((a) =>
      a.result === null && a.word_no < word ? (a.result = "correct") : null,
    );
    setData([...d]);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarningSnack(false);
  };

  const handleCloseWarning = () => {
    setWarningOpen(false);
  };

  const moveToNext = () => {
    if (
      i + 1 >= questCnt ||
      questionType === "Grid Answer" ||
      questionType === "Row Answer" ||
      questionType === "Word List" ||
      questionType === "Passage"
    ) {
      let comp;
      // use tempData is within a render and data has not refreshed yet
      if (tempData?.length !== 0) {
        comp = tempData.filter((x) => !!x.result).length === questCnt;
      } else {
        comp = data.filter((x) => !!x.result).length === questCnt;
      }
      // if comp (questions completed) then save results to state and set questions completed flag
      if (comp) {
        questionsComplete();
      } else {
        if (asmtInfo.must_complete_all) {
          // alert that you must do all questions
          const tempArr = {
            severity: "warning",
            message:
              "Please complete all items, currently " +
              data.filter((x) => !!x.result).length +
              " of " +
              questCnt +
              " items answered.",
          };

          setSnackConfig(tempArr);
          setOpenWarningSnack(true);
        } else {
          setWarningOpen(true);
        }
      }
    } else {
      setI(i + 1);
    }
  };

  const questionsComplete = () => {
    // if questions completed then save results to state and set questions completed flag
    if (tempData?.length !== 0) {
      dispatch(setCaAsmtResult(tempData));
    } else {
      dispatch(setCaAsmtResult(data));
    }
    setQuestComp(true);
    if (asmtInfo.timer) {
      dispatch(setCaTimerOn(false));
      dispatch(setCaFinalEdit(false));
    }
  };

  function handleNavBack() {
    if (i === 0) {
      dispatch(setCaAsmtInstComp(false));
    } else {
      setI(i - 1);
    }
  }

  function handleNavForward() {
    moveToNext();
  }

  function handleRecordScore(result, wordNo, moveNext) {
    updateResults(result, wordNo);
    if (moveNext) {
      moveToNext();
    }
  }

  function handleSkippedRow(row, moveNext) {
    tempData = data.map((d) => {
      return { ...d };
    });
    tempData.map((a) => {
      if (a.row === row) {
        a.result = "skippedRow";
        a.concat = false;
        a.switched = false;
        a.insert = false;
      }
      return a;
    });

    if (asmtInfo.fill_correct_on_score) {
      let x = tempData
        .sort((a, b) => a.row - b.row)
        .findIndex((a) => a.row === row);
      fillCorrect(tempData, tempData[x].word_no);
    } else {
      setData([...tempData]);
    }
    if (existingResult) {
      let tempManage = structuredClone(caManage);
      tempManage.resultChange = true;
      dispatch(setCaManage(tempManage));
    }
    if (moveNext) {
      moveToNext();
    }
  }

  function handleToggleResult(currRes, wordNo, buttons, suppData) {
    const ans = [...buttons];
    let result;
    if (!currRes) {
      result = ans.sort((a, b) => a.button_seq - b.button_seq)[0].button_type;
      updateResults(result, wordNo);
    } else {
      if (ans.filter((a) => a.button_type === currRes).length === 0) {
        result = null;
      } else {
        let i = ans.filter((a) => a.button_type === currRes)[0].button_seq;
        if (i === ans.length) {
          result = null;
        } else {
          result = ans.filter((a) => a.button_seq === i + 1)[0].button_type;
        }
      }
      updateResults(result, wordNo);
    }
  }

  function updateResultSuppField(suppData, word) {
    tempData = data.map((d) => ({ ...d }));
    const indx = tempData.findIndex((k) => k.word_no === word);
    Object.entries(suppData).forEach(
      ([key, value]) => (tempData[indx][key] = value),
    );

    if (asmtInfo.fill_correct_on_score) {
      fillCorrect(tempData, word);
    } else {
      setData([...tempData]);
    }
    if (existingResult) {
      let tempManage = structuredClone(caManage);
      tempManage.resultChange = true;
      dispatch(setCaManage(tempManage));
    }
    return;
  }

  const funcs = {
    moveToNext,
    handleNavBack,
    handleNavForward,
    handleRecordScore,
    handleToggleResult,
    handleSkippedRow,
    updateResultSuppField,
  };

  let questSeq = [...data];

  questSeq.sort((a, b) => parseInt(a.word_no) - parseInt(b.word_no));

  let questionComponents;

  if (questionType === "Single Answer") {
    questionComponents = (
      <>
        <AsmtQuestion testItem={questSeq[i]} funcs={funcs} />
        <AsmtProgressRibbon
          questions={questSeq}
          activeQuestion={i}
          funcs={funcs}
        />
      </>
    );
  } else if (
    questionType === "Grid Answer" ||
    questionType === "Row Answer" ||
    questionType === "Word List" ||
    questionType === "Passage"
  ) {
    questionComponents = (
      <>
        <AsmtMatrix questions={questSeq} funcs={funcs} />
      </>
    );
  } else {
    questionComponents = (
      <p>
        The type of assessment display is not defined, please contact an
        administrator.
      </p>
    );
  }

  let content = (
    <>
      {asmtInfo.timer && <Timer initSecs={asmtInfo.time_limit} />}
      <Box sx={{ overflow: "auto" }}>{questionComponents}</Box>
      <BasicSnackbar
        open={openWarningSnack}
        onClose={handleCloseSnack}
        severity={snackConfig.severity}
        message={snackConfig.message}
      />
      <Dialog
        open={warningOpen}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Save Incomplete Results
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Only " +
              data.filter((x) => !!x.result).length +
              " of " +
              questCnt +
              " items have been answered. Do you want to continue?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseWarning}>
            No
          </Button>
          <Button variant="contained" onClick={() => questionsComplete()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return content;
};

export default DisplayAsmtQuestion;
